<template>
  <!-- description -->
  <v-expansion-panel class="mb-2">
    <v-expansion-panel-header>DESCRIPTION</v-expansion-panel-header>
    <v-expansion-panel-content eager>
      <p v-html="$options.filters.br(description)" class="pt-4"></p>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'ListViewItemDescription',
  props: {
    description: {type: String, required: true}
  }
}
</script>

<style>

</style>