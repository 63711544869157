<template>
  <div>
    <div class="btn-eye btn-list-item-actions" v-if="eyeStatus == 'F'">
      <v-btn text block color="grey darken-4" class="short px-0 no-min-width"
        @click="toggleEyeStatus">
        <v-icon class="grey--text text--darken-4" size="20" 
          aria-label="hide trial" role="img" aria-hidden="false" > 
          mdi-eye-off </v-icon> HIDE
      </v-btn>
    </div>

    <div v-else class="btn-eye btn-list-item-actions selected">
      <v-btn text block class="short px-0 no-min-width white--text"
        @click="toggleEyeStatus">
        <v-icon class="white--text" size="20" 
                aria-label="hide trial" role="img" aria-hidden="false" >
                mdi-eye-off</v-icon >HIDDEN 
      </v-btn>
    </div>

  </div>
</template>

<script>
export default {
  name: 'EyeHiddenBox',
  props: {
    item: { type: Object, required: true },
  },
  methods: {
    toggleEyeStatus() {
      this.$store.dispatch('trialsStore/toggleEyeStatus', this.item.id);
    },
  },
  computed: {
    eyeStatus() {
      return this.$store.getters['trialsStore/getEyeStatus'](this.item.id - 1);
    },
  },
};
</script>

<style></style>
