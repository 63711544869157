<template>
  <div>
    <!-- <p>{{patientInfo.join(' | ')}}</p>
    <p>{{diseases.join(' | ')}}</p>
    <p>{{biomarkers.join(' | ')}}</p>
    <p>{{testResults.join(' | ')}}</p> -->
    {{ savedCriterias }}
  </div>
</template>

<script>
import { criteriaDict } from '@/store/initState';

export default {
  name: 'SelectedCriteria',
  props: {
    criteriaStr: { type: String, required: true }
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      sortBy: '',
      sortDesc: true,
      loadingSearch: false,
    };
  },
  methods: {
  },
  computed: {
    biomarkersMultipleInfo() {
      return this.$store.getters['criteriaStore/getBiomarkersMultipleInfo']
    },
    criteria() {
      return JSON.parse(this.criteriaStr);
    },
    patientInfo() {
      return convertPatientObjToArr(this.criteria.patientInfo);
    },
    diseases() {
      return convertDiseaseObjToArr(this.criteria.disease);
    },
    biomarkers() {
      return convertBiomarkersObjToArr(this.criteria.biomarkers, this.biomarkersMultipleInfo);
    },
    testResults() {
      return convertTestResultsObjToArr(this.criteria.testResults);
    },
    savedCriterias() {
      return [
        ...this.diseases,
        ...this.patientInfo,
        ...this.biomarkers,
        ...this.testResults,
      ].join(' | ');
    },
  },
};

function convertPatientObjToArr(obj) {
  let patientArr = [];
  Object.keys(obj).forEach((key) => {
    if (key == 'sex_multiple' && obj[key] !== 2) {
      patientArr.push(obj[key] == 0 ? 'Male' : 'Female');
    } else if (key == 'geoArr') {
      let geoStr = obj[key]
        .filter((geo) => geo.zipcode !== '')
        .map((geo) => geo.zipcode);
        if (geoStr.length > 0) {
          patientArr.push(geoStr.join(', '));
        }
    } else if (key == 'age' && obj[key] !== '') {
      patientArr.push(`${obj.age} ${obj.ageUnit}`);
    } else if (key.includes('multiple') && obj[key] !== 2) {
      patientArr.push(`${criteriaDict[key]}: ${obj[key] == 0 ? 'Yes' : 'No'}`);
    } else if ((key == 'height' || key == 'weight' || key == 'BMI') && obj[key] !== '') {
      patientArr.push(`${criteriaDict[key]}: ${obj[key]}`);
    } else if (key == 'therapy' && obj[key].length > 0) {
      patientArr.push(
        `${criteriaDict[key]}: ${obj[key]
          .map((item) => item.pref_name)
          .join(', ')}`
      );
    }
  });
  return patientArr;
}
function convertDiseaseObjToArr(obj) {
  let diseasesArr = [
    ...obj.diseaseSelected,
    ...obj.gyn,
    ...obj.lung,
    ...obj.solid,
    ...obj.allDisease,
  ];
  if (obj.diseaseModifiers) {
    diseasesArr.push(...obj.diseaseModifiers)
  }
  return diseasesArr;
}
function convertBiomarkersObjToArr(obj, biomarkersMultipleInfo) {
  let biomarkersArr = [];

  let biomarkersMultipleArr = [];
  let multipleArr = [
    'egfr_multiple',
    'alk_multiple',
    'ros1_multiple',
    'nras_multiple',
    'kras_multiple',
    'hras_multiple',
    'her2Neu_multiple',
  ];

  multipleArr.forEach((bio) => {
    if (obj[bio] == 0) {
      biomarkersMultipleInfo[bio].positive.forEach((item) => {
        biomarkersMultipleArr.push(item.name);
      });
    } else if (obj[bio] == 1) {
      biomarkersMultipleInfo[bio].negative.forEach((item) => {
        biomarkersMultipleArr.push(item.name);
      });
    }
  });

  biomarkersArr = [
    ...biomarkersMultipleArr,
    ...obj.biomarkersSelected.map((item) => item.biomarker),
  ];
  return biomarkersArr;
}
function convertTestResultsObjToArr(obj) {
  let testResults = [];
  Object.keys(obj).forEach((key) => {
    //if (key == 'hiv_multiple') {
    if (key.includes('multiple')) {
      if (obj[key] !== 2) {
        testResults.push(`${criteriaDict[key]}: ${obj[key] == 0 ? 'Yes' : 'No'}`);
      }
    } else {
      if (key == 'performance') {
        testResults.push(`Performance: ${obj[key].name}`);
      } else if (obj[key] !== '') {
        testResults.push(`${criteriaDict[key]}: ${obj[key]}`);
      }
    }
  });
  return testResults;
}
</script>

<style></style>
