<template>
  <v-tooltip v-bind="options" :content-class="`t-${location}`" :z-index="50">
    <template v-slot:activator="{ on: onTooltip }">
      <v-btn :data-test="'btn-' + tooltip | snakeCase" :id="'btn-' + tooltip | snakeCase" depressed :color="color"
        v-on="{ ...onTooltip }"
        aria-label="tooltip"
        @click="$emit('iconClicked')"
      >
        <v-icon :aria-label="tooltip" role="img" aria-hidden="false">{{icon}}</v-icon>
      </v-btn>
    </template>
    <span>{{tooltip}}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipIcon',
  props: {
    tooltip: { type: String, required: true },
    icon: { type: String, required: true },
    location: { type: String, default: 'bottom' },
    color: { type: String, default: 'primary' }
  },
  data() {
    return {
      options: {
        [this.location]: true
      }
    } 
  }
}
</script>

<style>

</style>