<template>
  <div id="loading-wrapper" class="d-flex flex-column">
    <loading-mag :w="300" :h="300" />
    <div class="loading-msg text-center pt-16">Optimizing FACTrial search criteria</div>
  </div>
</template>

<script>
import LoadingMag from '../components/LoadingMag.vue';

export default {
  name: 'InitLoading',
  components: { LoadingMag }
}
</script>

<style>

</style>