export default {
  install(Vue) {
    Vue.directive('tfFocus', {
      bind(el, binding) {
        let input = el.getElementsByTagName('INPUT')[0];
        input.setAttribute('name', binding.expression)
      },
    });
  },
};
