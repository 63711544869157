<template>
  <span class="btn-eye">
    <v-btn @click="toggleEyeStatus" class="short px-0 no-min-width" color="primary" text>
      <v-icon class="grey--text text--lighten-1" size="24" v-if="eyeStatus == 'F'"
        aria-label="hide trial" role="img" aria-hidden="false">
        mdi-eye-off-outline
      </v-icon>
      <v-icon v-else size="24" class="grey--text text--darken-2"
        aria-label="hide trial" role="img" aria-hidden="false">mdi-eye-off</v-icon>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'EyeHidden',
  props: {
    item: { type: Object, required: true, },
  },
  methods: {
    toggleEyeStatus() {
      this.$store.dispatch('trialsStore/toggleEyeStatus', this.item.id);
    },
  },
  computed: {
    eyeStatus() {
      return this.$store.getters['trialsStore/getEyeStatus'](this.item.id - 1);
    },
  },
};
</script>

<style></style>
