<template>
  <div class="notification">
    <v-snackbar bottom 
      :class="notification.type"
      v-model="notification.show"
      :timeout="-1">
      
      <!-- <v-alert :class="notification.type" border="left" dense>
        <p class="content">{{ notification.msg }}</p>
      </v-alert>-->

      <v-alert :class="notification.type" border="left" icon="mdi-information">
        <p class="title mb-2"><strong>Finding Clinical Trials</strong> </p>
        <p class="content mb-0">{{ notification.msg}}</p>
        <template v-slot:[`prepend`]>
          <v-progress-circular class="ml-2 mr-5"
            indeterminate
            color="#1b1b1b"
          ></v-progress-circular>
        </template>
      </v-alert>

      <template v-slot:action="{ }">
        <v-icon @click="$store.dispatch('notificationStore/hide')"
          aria-label="Close Notification" role="button"  aria-hidden="false">mdi-close-thick</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  created() {
    this.$store.dispatch('notificationStore/hide');
  },
  computed: {
    ...mapGetters('notificationStore', ['getNotification']),
    notification() {
      return this.getNotification;
    }
  }
}
</script>

<style>

</style>