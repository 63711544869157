import { cloneDeep } from 'lodash';
import { defaultPatientInfo, defaultTestResults } from '@/store/initState'
import axios from 'axios';
import fetchService from '@/services/fetchService';

let defaultGeoArr = [
  { lat: '', lon: '', city: '', state: '', zipcode: '' },
  { lat: '', lon: '', city: '', state: '', zipcode: '' },
  { lat: '', lon: '', city: '', state: '', zipcode: '' }
];

export default {
  state: {
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    primaryCancerData: [],
    priorTherapyData: [],
    patientInfo: cloneDeep(defaultPatientInfo),
    biomarkersData: [],
    biomarkers: {},
    allDiseaseData: [],
    testResults: cloneDeep(defaultTestResults),
    trialsHeader: [
      {text: '', filterLabel: 'ref', value: 'id', sortable: false, width: 50, show: true},
      {text: 'Hide', filterLabel: 'ref', value: 'hide', width: 80, sortable: false, show: true},

      {text: 'Star', filterLabel: 'ref', value: 'star', width: 80, sortable: false, show: true},
      {text: 'NCT ID', filterLabel: 'ref', value: 'nct_id', fixed: true, width: 100, show: true},
      {text: 'Title', filterLabel: 'ref', value: 'brief_title', width: 400, show: true},
      
      {text: 'Phase', value: 'phase', align: 'center', width: 100, show: true},
      {text: 'Disease(s) of Focus', value: 'disease_names_lead', width: 400, show: true},
      {text: 'Focused Match', value: 'lead_disease_matches', align: 'center', width: 130, show: true},
      {text: 'All Diseases', value: 'disease_names', width: 400, show: true},
      {text: 'General Match', value: 'disease_matches', width: 100, show: true},
      {text: 'Biomarker Exclusion', value: 'biomarker_exc_matches', align: 'center', width: 150, show: true},
      {text: 'Biomarker Inclusion', value: 'biomarker_inc_matches', align: 'center', width: 150, show: true},
      {text: 'HIV Exclusion', value: 'hiv_exc_matches', width: 120, show: true},
      {text: 'Brain Mets', value: 'bmets_matches', width: 120, show: true},
      {text: 'PT Inclusion', value: 'pt_inc_matches', width: 150, show: true},
      {text: 'PT Exclusion', value: 'pt_exc_matches', width: 160, show: true},
      {text: 'PLT', value: 'plt_matches', width: 100, show: true},
      {text: 'WBC', value: 'wbc_matches', width: 100, show: true},
      {text: 'Performance Status', value: 'perf_matches', show: true},
      {text: 'Sex', value: 'gender_matches', width: 100, show: true},
      {text: 'Age', value: 'age_matches', width: 100, show: true},
      {text: 'VA Sites', value: 'va_matches', width: 130, show: true},
      {text: 'NIH CC', value: 'nih_cc_matches', width: 130, show: true},
      {text: 'Study Source', value: 'study_source', width: 130, show: true}
    ],
    trials: [],
    dbStarted: false,
    finalPatientInfo: {},
    finalBiomarkers: {},
    finalBiomarkersMultipleSelected: [],
    tempGeoArr: cloneDeep(defaultGeoArr),
    geoArr: cloneDeep(defaultGeoArr),
    _defaultBiomarkers: {},
    _biomarkersMultipleInfo: null
  },
  getters: {
    getTZ: state => state.tz,
    getDBStatus: state => state.dbStarted,
    getPatientInfo: state => state.patientInfo,
    getGeo: state => state.patientInfo.geo,
    getGeoArr: state => state.geoArr,
    getTestResults: state => state.testResults,
    getPrimaryCancerData: state => state.primaryCancerData,
    getPriorTherapyData: state => state.priorTherapyData,
    getBiomarkersData: state => state.biomarkersData,
    getAllDiseaseData: state => state.allDiseaseData,
    getBiomarkers: state => state.biomarkers,
    getDefaultBiomarkers: state => state._defaultBiomarkers,
    getBiomarkersMultipleInfo: state => state._biomarkersMultipleInfo,
    getBiomarkersMultipleSelected: (state) => { //return ccode for biomarkers selections
      let biomarkersMultipleArr = [];
      let multipleArr = [];
      Object.keys(state._defaultBiomarkers).forEach(key => {
        if (key.includes('multiple')) {
          multipleArr.push(key)
        }
      })

      //let multipleArr = [ 'egfr_multiple', 'alk_multiple', 'ros1_multiple', 'nras_multiple', 'kras_multiple', 'hras_multiple', 'her2Neu_multiple', ];

      multipleArr.forEach((bio) => {
        if (state.biomarkers[bio] == 0) {
          state._biomarkersMultipleInfo[bio].positive.forEach((item) => {
            biomarkersMultipleArr.push(item.name);
          });
        } else if (state.biomarkers[bio] == 1) {
          state._biomarkersMultipleInfo[bio].negative.forEach((item) => {
            biomarkersMultipleArr.push(item.name);
          });
        }
      });
      return biomarkersMultipleArr;
    },
    getTempGeo: state => state.tempGeoArr
  },
  mutations: {
    UPDATE_DB_STATUS(state) {
      state.dbStarted = true;
    },
    UPDATE_BIOMARKERS_DATA(state, data) {
      state.biomarkersData = data;
    },
    UPDATE_ALL_DISEASE_DATA(state, data) {
      state.allDiseaseData = data
    },
    UPDATE_PRIMARY_CANCER_DATA(state, data) {
      state.primaryCancerData = data;
    },
    UPDATE_PRIOR_THERAPY_DATA(state, data) {
      state.priorTherapyData = data;
    },
    UPDATE_PATIENT_INFO(state, pInfo) {
      state.patientInfo = pInfo
    },
    UPDATE_BIOMARKERS(state, biomarkers) {
      state.biomarkers = biomarkers
    },
    REMOVE_BIOMARKER_AT_INDEX(state, index) {
      state.biomarkers.biomarkersSelected.splice(index, 1);
    },
    UPDATE_TEST_RESULTS(state, tResults) {
      state.testResults = tResults
    },
    UPDATE_BRAINCNS(state, val) {
      state.patientInfo.brainCNS_multiple = val
    },
    REMOVE_BIOMARKERS_MULTIPLE(state, item) {
      state.biomarkers[item] = 2;
    },
    UPDATE_LAT_LON(state, geoData) {
      state.patientInfo.geo = geoData
    },
    UPDATE_GEO_ARR(state, geoArr) {
      state.patientInfo.geoArr = cloneDeep(geoArr)
      state.geoArr = cloneDeep(geoArr)
    },
    UPDATE_TEMP_GEO(state, geoArr) {
      state.tempGeoArr = cloneDeep(geoArr)
    },
    SET_BIOMARKERS_BASIC_INFO(state, multipleInfo) {
      //set biomarkersMultipleInfo
      state._biomarkersMultipleInfo = JSON.parse(multipleInfo);

      //set defaultBiomarkers, biomarkers
      let tempBiomarkers = {};
      Object.keys(state._biomarkersMultipleInfo).forEach(key => {
        if (key.includes('multiple')) {
          tempBiomarkers[key] = 2
        }
      })
      //add one more property for biomarker dropdown 
      tempBiomarkers.biomarkersSelected = [];
      state._defaultBiomarkers = cloneDeep(tempBiomarkers);
      state.biomarkers = cloneDeep(tempBiomarkers);
    }
  }, 
  actions: {
    updatePatientInfo({commit}, pInfo) {
      commit('UPDATE_PATIENT_INFO', pInfo)
    },
    updateBiomarkers({commit}, biomarkers) {
      commit('UPDATE_BIOMARKERS', biomarkers)
    },
    updateTestResults({commit}, tResults) {
      commit('UPDATE_TEST_RESULTS', tResults)
    },
    removeBiomarkerAtIndex({commit}, index) {
      commit('REMOVE_BIOMARKER_AT_INDEX', index)
    },
    updateBrainCNS({commit}, val) {
      commit('UPDATE_BRAINCNS', val)
    },
    removeBiomarkerMultiple({commit}, item) {
      commit('REMOVE_BIOMARKERS_MULTIPLE', item)
    },
    fetchLatLon({commit}, url) {
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(res => {
            let result = res.data.resourceSets[0].resources[0]
            
            let geoData = {
              lat: result.point.coordinates[0],
              lon: result.point.coordinates[1],
              city: result.address.locality,
              state: result.address.adminDistrict
            }
            resolve(result.address.countryRegion)
            commit('UPDATE_LAT_LON', geoData);
          })
      })
      
    },
    fetchBiomarkersData({commit, state}) {
      /* fetch biomarkers data and build `biomarkers` and `_defaultBiomarkers` */
      return new Promise((resolve, reject) => {
        fetchService.getDataFiles('biomarkers')
          .then(response => {
            commit('UPDATE_BIOMARKERS_DATA', response)
            resolve(response)
          })
          .catch(error => {
            console.log('error', error)
            reject(error)
          })
      })    
    },
    fetchAllDiseaseData({commit}) {
      return new Promise((resolve, reject) => {
        fetchService.getDataFiles('possible_disease_trees')
          .then(response => {
            commit('UPDATE_ALL_DISEASE_DATA', response)
            resolve(response)
          })
          .catch(error => {
            console.log('error', error)
            reject(error)
          })
      })    
    },
    fetchPrimaryCancerData({commit}) {
      return new Promise((resolve, reject) => {
        fetchService.getDataFiles('primary_cancer')
          .then(response => {
            commit('UPDATE_PRIMARY_CANCER_DATA', response)
            resolve(response)
          })
          .catch(error => {
            console.log('error', error)
            reject(error)
          })
      })
    },
    fetchPriorTherapyData({commit}) {
      return new Promise((resolve, reject) => {
        fetchService.getDataFiles('prior_therapy')
          .then(response => {
            commit('UPDATE_PRIOR_THERAPY_DATA', response)
            resolve(response)
          })
          .catch(error => {
            console.log('error', error)
            reject(error)
          })
      })
    }
  }
}