<template>
  <div id="summary-header" class="font-weight-bold elevation-4">
    <v-expansion-panels v-model="summaryPanelOpen">
      <v-expansion-panel active-class="summary-open">
        <v-expansion-panel-header>
          <template v-slot:actions>
            <tooltip-icon
              :tooltip="summaryOpen ? 'Collapse Summary' : 'Open Summary'"
              icon="mdi-chevron-double-down"
              color="unstyled"
              @iconClicked="summaryOpen = !summaryOpen"
            />
          </template>
          <template>
            <div id="summary-expansion-title">SELECTED SEARCH CRITERIA</div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="summary-info pt-5 pl-md-4 pr-md-3 pb-md-4">
            <v-row>
              <v-col cols="12" sm="10" class="pa-0 px-sm-0">
                <!-- <div class="text-h5 font-weight-bold mb-3">SELECTED SEARCH CRITERIA</div> -->

                <!-- patient info -->
                <div v-if="!shareSummary.isPatientInfoDefault">
                  <span class="summary-icon mr-2"><img alt="patient info" height="16" src="@/assets/patient_info.svg" /></span>
                  <span>
                    <span v-if="geoArrFiltered.length > 0" class="summary-criteria summary-patient-info">
                      <strong>Geolocation:</strong> {{ geoRender }}
                    </span>

                    <patient-info-criteria :criteria="shareSummary.patientInfo.sex_multiple" :label="'Sex'" :labelArr="['Male', 'Female']" />

                    <span v-if="shareSummary.patientInfo.age.length > 0" class="summary-criteria">
                      <strong>Age:</strong> {{ shareSummary.patientInfo.age }}
                      {{ shareSummary.patientInfo.ageUnit }}
                    </span>
                    <span v-if="shareSummary.patientInfo.weight && shareSummary.patientInfo.weight.length > 0" class="summary-criteria">
                      <strong>Weight:</strong> {{ shareSummary.patientInfo.weight }}
                    </span>
                    <span v-if="shareSummary.patientInfo.height && shareSummary.patientInfo.height.length > 0" class="summary-criteria">
                      <strong>Height:</strong> {{ shareSummary.patientInfo.height }}
                    </span>
                    <span v-if="shareSummary.patientInfo.BMI && shareSummary.patientInfo.BMI.length > 0" class="summary-criteria">
                      <strong>BMI:</strong> {{ shareSummary.patientInfo.BMI }}
                    </span>

                    <patient-info-criteria :criteria="shareSummary.patientInfo.ableToSwallow_multiple" :label="'Able to Swallow'" :labelArr="['Yes', 'No']" />
                    <patient-info-criteria :criteria="shareSummary.patientInfo.chemo_multiple" :label="'Prior Chemo or Immuno'" :labelArr="['Yes', 'No']" />
                    <patient-info-criteria :criteria="shareSummary.patientInfo.radiotherapy_multiple" :label="'Prior Radiotherapy'" :labelArr="['Yes', 'No']" />
                    <patient-info-criteria :criteria="shareSummary.patientInfo.surgery_multiple" :label="'Prior Surgery'" :labelArr="['Yes', 'No']" />

                    <span v-if="shareSummary.patientInfo.therapy.length > 0" class="summary-criteria" >
                      <strong>Other Prior Therapy:</strong> {{ therapyRender }}
                    </span >
                  </span>
                </div>
                <!-- disease details -->
                <!-- <span v-if="isDiseaseDetailsNotDefault" class="summary-icon mr-2" style="margin-left: 2px"> -->
                <span class="summary-icon mr-2" style="margin-left: 2px">
                  <img  alt="disease detail"  height="16" src="@/assets/disease_detail.svg" />
                </span>
                <span>
                  <span  v-if="combinedDisease.length > 0" class="summary-criteria">
                    <strong>Diseases: </strong>{{ combinedDisease.join(', ') }}
                  </span>

                  <!-- biomarkers -->
                  <span v-if="shareSummary.biomarkers.biomarkersSelected.length > 0 || shareSummary.biomarkersMultipleSelected.length > 0" class="summary-criteria">
                    <strong>Biomarkers:</strong> {{ biomarkersSelectedRender }}
                  </span>

                  <patient-info-criteria :criteria="shareSummary.patientInfo.brainCNS_multiple" :label="fieldToLabel('brainCNS_multiple')" :labelArr="['Yes', 'No']" />
                </span>
                <br />

                <!-- test results -->
                <span class="summary-icon mr-2">
                  <img alt="test result"  height="20" src="@/assets/test_results.svg" />
                </span>
                <span>
                  <patient-info-criteria
                    v-for="(item, index) in testResultOnlyMultiple"
                    :key="index" :criteria="item.value" :label="item.label" :labelArr="['Yes', 'No']" />
                  <span v-if="shareSummary.testResults.wbc" class="summary-criteria">
                    <strong>WBC (/uL):</strong> {{ shareSummary.testResults.wbc }}
                  </span>
                  <span v-if="shareSummary.testResults.platelets" class="summary-criteria">
                    <strong>Platelets (/uL):</strong>
                    {{ shareSummary.testResults.platelets }}
                  </span>
                  <span class="summary-criteria">
                    <strong>Performance Status:</strong>
                    {{ shareSummary.testResults.performance.name }}
                  </span>
                </span>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import PatientInfoCriteria from './PatientInfoCriteria.vue';
import TooltipIcon from './TooltipIcon.vue';
import { criteriaDict } from '@/store/initState';

export default {
  components: { PatientInfoCriteria, TooltipIcon },
  name: 'ShareSummaryHeader',
  props: {
    shareSummary: {type: Object, required: true}
  },
  data() {
    return {
      summaryOpen: true,
      summaryPanelOpen: 0,
      criteriaDict,
    };
  },
  methods: {
    onNewSearch() {
      this.$emit('backToCriteriaTab');
    },
    fieldToLabel(key) {
      return criteriaDict[key];
    },
  },
  computed: {
    geoArrFiltered() {
      return this.shareSummary.patientInfo.geoArr.filter((geo) => geo.lat !== '');
    },
    geoRender() {
      let str = '';
      this.geoArrFiltered.forEach((geo, index) => {
        if (index == 0) {
          str += `${geo.city}, ${geo.state}`;
        } else {
          str += ` • ${geo.city}, ${geo.state}`;
        }
      });
      return str;
    },
    therapyRender() {
      return this.shareSummary.patientInfo.therapy.map((item) => item.pref_name).join(', ');
    },
    biomarkersSelectedRender() {
      let combinedBiomarkers = [
        ...this.shareSummary.biomarkersMultipleSelected,
        ...this.shareSummary.biomarkers.biomarkersSelected.map((item) => item.biomarker),
      ];
      return combinedBiomarkers.join(', ');
    },
    /* isDiseaseDetailsNotDefault() {
      return !(this.shareSummary.isDiseaseDefault && this.shareSummary.isBiomarkersDefault);
    }, */
    combinedDisease() {
      return [
        ...this.shareSummary.disease.diseaseSelected,
        ...this.shareSummary.disease.allDisease,
        ...this.shareSummary.disease.diseaseModifiers,
      ];
    },
    testResultOnlyMultiple() {
      let tempArr = [];
      Object.keys(this.shareSummary.testResults).forEach((key) => {
        if (key.includes('multiple')) {
          tempArr.push({
            label: criteriaDict[key],
            value: this.shareSummary.testResults[key],
          });
        }
      });
      return tempArr;
    },
  },
};
</script>

<style></style>
