<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-16-9"></video>
  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null
    }
  },
  methods: {
    pauseVideo() {
      this.player.pause()
    }
  }, 
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      //this.player.log('onPlayerReady', this);
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>