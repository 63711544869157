import fetchService from '@/services/fetchService';

export default {
  state: {
    trialDetails: [],
  },
  getters: {
    getTrialDetails: state => id => {
      return state.trialDetails.find(trial => {
        return trial.nct_id === id
      })
    }
  },
  mutations: {
    UPDATE_TRIAL_DETAILS(state, newTrial) {
      let index = state.trialDetails.findIndex(trial => trial.nct_id == newTrial.nct_id)
      if (index !== -1) {
        state.trialDetails.splice(index, 1, newTrial)
      } else {
        state.trialDetails = [...state.trialDetails, newTrial];
      }
    }
  },
  actions: {
    /* getTrialDetailsById({commit}, payload) {
      let params = {}
      if (payload.lat !== '' && payload.lon !== '' && payload.distance > 0) {
        params = {...payload}
      } else {
        params.nct_ids = payload.nct_ids
      }
      return new Promise((resolve, reject) => {
        fetchService.postDataFiles('report_data', params)
          .then(response => {
            let newTrial = {
              ...response.trials[0],
              lat: payload.latitude,
              lon: payload.longitude,
              distance: payload.distance
            }
            //if (payload.lat !== '') {
              
            //}
            commit('UPDATE_TRIAL_DETAILS', newTrial)
            resolve(newTrial)
          })
          .catch(error => {
            console.log('error', error)
            reject(error)
          })
      })
    }, */
    getTrialDetailsByIdArr({commit}, payload) {
      return new Promise((resolve, reject) => {
        fetchService.postDataFiles('report_data', payload)
          .then(response => {
            let newTrial = {
              ...response.trials[0]
            }
            commit('UPDATE_TRIAL_DETAILS', newTrial)
            resolve(newTrial)
          })
          .catch(error => {
            console.log('error', error)
            reject(error)
          })
      })
    }
  }
}