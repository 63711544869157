import { Auth } from 'aws-amplify';

let defaultUser = {
  username: 'test_provider',
  attributes: {
    "custom:login_count": "1"
  },
  signInUserSession: {
    idToken: {
      payload: {
        "email": "johnsmith@outlook.com",
        "cognito:groups": [
          //"admin", "essex_demo"
          //"admin", "CCDI", "essex_demo"
          //"CCDI"
        ],
      }
    }
  }
}

export default {
  state: {
    login: false,
    user: process.env.VUE_APP_SKIP_AUTH === 'true' ? defaultUser : null,
    idleDialogOpened: false,
    jwtToken: '',
    selectedGroup: '', //admin, essex_demo, CCDI
    programs: [],
    searchType: ''
  },
  getters: {
    getLoginStatus: state => state.login,
    getUser: state => state.user,
    getJWT: state => state.jwtToken,
    getIdleDialogStatus: state => state.idleDialogOpened,
    getEmail: state => {
      if (state.user) {
        return state.user.signInUserSession.idToken.payload.email
      } else {
        return ''
      }
    },
    getUsername: state => {
      if (state.user) {
        return state.user.attributes.name || state.user.username
        //return state.user.username
      } else {
        return ''
      }
    },
    getGroup: state => {
      if (state.user) {
        return state.user.signInUserSession.idToken.payload['cognito:groups'] || []
      } else {
        return []
      }
    },
    getSelectedGroup: state => {
      return state.selectedGroup;
    },
    getPrograms: state => state.programs,
    getSearchType: state => state.searchType,
    shouldShowToggle: state => {
      return state.programs.filter(program => {
        return program.cognito_group_name == state.selectedGroup
      })[0].filter_trials
    }
  },
  mutations: {
    UPDATE_USER(state, user) {
      state.user = user
      state.jwtToken = user ? user.signInUserSession.idToken.jwtToken : ''
    },
    UPDATE_IDLE_DIALOG(state, status) {
      state.idleDialogOpened = status
    },
    UPDATE_TOKEN(state, token) {
      //console.log('update token')
      state.jwtToken = token
    },
    UPDATE_SELECTED_GROUP(state, group) {
      state.selectedGroup = group;
    },
    SET_PROGRAMS(state, programs) {
      state.programs = programs
    },
    SET_SEARCH_TYPE(state, type) {
      state.searchType = type
    }
  },
  actions: {
    setUser({commit}, user) {
      //console.log(user)
      commit('UPDATE_USER', user)
    },
    setSelectedGroup({commit}, group) {
      commit('UPDATE_SELECTED_GROUP', group)
    }
  }
}