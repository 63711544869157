<template>
  <span v-if="criteria != 2" class="summary-criteria">
    <strong>{{label}}:</strong> {{labelArr[criteria]}}
  </span>        
</template>

<script>
export default {
  name: 'PatientInfoCriteria',
  props: {
    criteria: {type: Number, required: true},
    label: {type: String, required: true},
    labelArr: {type: Array, required: true},
  }
}
</script>

<style>

</style>