<template>
  <div>
    <div style="width: 40px" class="mx-auto">
      <v-dialog content-class="edit-dialog" persistent v-model="dialog" width="1000" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="dialog = true" color="#9E7D22" class="short px-0 no-min-width" text 
            v-bind="attrs" v-on="on">
            <v-icon aria-label="Edit Criteria Types" role="img" aria-hidden="false">
              mdi-pencil-box-outline
            </v-icon>
          </v-btn>
        </template>

        <v-card class="edit-card">
          <v-icon role="img" aria-hidden="true" class="white--text title-icon">
            mdi-pencil
          </v-icon>

          <div class="btn-close" v-show="!updating">
            <v-icon aria-label="close dialog" role="img" aria-hidden="false" @click="dialog = false" >
              mdi-close-thick
            </v-icon >
          </div>

          <v-card-text class="ml-n2 mr-0 pr-2">
            <div class="form-progress-wrapper">
              <v-progress-linear v-if="updating" indeterminate color="primary" class="mb-8"></v-progress-linear>
            </div>

            <p class="title text-h4 mb-3">Edit Criteria</p>

            <!-- <v-row>
              <v-col cols="6">
                <v-text-field label="ID" :value="trialCriteria.nct_id" prefix="NCT" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-combobox v-model="selectedType" :items="criteriaTypes" label="Criteria Type"
                  item-text="criteria_type_title" item-value="criteria_type_title" disabled
                ></v-combobox>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col>
                <span>NCT ID: </span><span class="font-weight-bold">{{trialCriteria.nct_id}}</span>, <span>Criteria Type: </span><span class="font-weight-bold">{{title}}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea filled name="Original Text" label="Original Text" hide-details
                  v-model="trialCriteria.trial_criteria_orig_text" rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea filled name="Refined Text" label="Refined Text" hide-details
                  v-model="trialCriteria.trial_criteria_refined_text" rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea filled name="Expression" label="Expression" hide-details
                  v-model="trialCriteria.trial_criteria_expression" rows="8"
                ></v-textarea>
              </v-col>
            </v-row>
            
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" outlined>TEST EXPRESSION</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveCriteria">SAVE AND CLOSE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar :timeout="timeout" v-model="testExpressionSnackbar" :color="snackbarColor">
      {{testMsg}}

      <template v-slot:action="{ attrs }">
        <v-btn @click="testExpressionSnackbar = false" color="white" text v-bind="attrs" class="pr-0 mr-n4">
          <v-icon aria-label="close" role="img" aria-hidden="false">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import fetchService from '@/services/fetchService';

export default {
  name: 'TrialCriteriaEditDialog',
  props: {
    item: {type: Object, required: true},
    type: {type: String, required: true}
  },
  data() {
    return {
      dialog: false,
      updating: false,
      selectedType: null,
      trialCriteria: null,
      testMsg: '',
      timeout: 10000,
      testExpressionSnackbar: false,
      snackbarColor: ''
    }
  },
  created() {
    this.trialCriteria = {...this.item};
    /* this.selectedType = this.$store.getters['typesStore/getCriteriaTypes'].find(criteria => {
      return criteria.criteria_type_id == this.item.criteria_type_id;
    }).criteria_type_title */
  },
  methods: {
    saveCriteria() {
      /* payloadTemplate: {
        "record": {
          "nct_id": "NCT01629498",
          "criteria_type_id": 32, 
          "trial_criteria_orig_text": "orig text", 
          "trial_criteria_refined_text": "refined text" , 
          "trial_criteria_expression": "expression"
        }
      } */
      /* this.trialCriteria {
        "nct_id": "NCT00075387",
        "criteria_type_id": 6,
        "trial_criteria_orig_text": null,
        "trial_criteria_refined_text": "Platelet count >= 100,000/uL",
        "trial_criteria_expression": "C51951 >= 100000",

        "update_date": "2020-12-24 00:00:00.000",
        "update_by": "hickmanhb"
      } */
      let {nct_id, criteria_type_id, trial_criteria_orig_text, trial_criteria_refined_text, trial_criteria_expression} = this.trialCriteria;
      let query = {
        payload: {
          record: {nct_id, criteria_type_id, trial_criteria_orig_text, trial_criteria_refined_text, trial_criteria_expression}
        },
        type: this.type
      }
      this.updating = true;
      this.$store.dispatch('typesStore/updateTrialCriteriaType', query)
        .then(res => {
          this.updating = false;
          this.dialog = false;
        })
    },
    testExpression() {
      this.updating = true;
      fetchService.postDataFiles('post_eval_expression', {
        expression: this.trialCriteria.trial_criteria_expression
      })
        .then((response) => {
          this.updating = false;
          if (response.status == 'OK') {
            this.testExpressionSnackbar = true;
            this.snackbarColor = 'success';
            this.testMsg = 'Criteria Test Passed';
          } else {
            this.testExpressionSnackbar = true;
            this.snackbarColor = 'error';
            this.testMsg = `Criteria Test fail: ${response.message}`;
          }
        })
    }
  },
  computed: {
    criteriaTypes() {
      return this.$store.getters['typesStore/getCriteriaTypes']
    },
    title() {
      return this.criteriaTypes?.find(criteria => {
        return criteria.criteria_type_id == this.item.criteria_type_id;
      }).criteria_type_title;
    }
  },
  watch: {
    item() {
      this.trialCriteria = {...this.item};
    }
  }
}
</script>

<style>

</style>