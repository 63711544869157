<template>
  <div>
    <v-dialog content-class="edit-dialog" persistent v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text class="short px-0 no-min-width" v-bind="attrs" v-on="on" 
          @click="getTypesCount">
          <v-icon aria-label="Delete Criteria Types" role="img" aria-hidden="false"
            class="grey--text">
            mdi-close-circle-outline
          </v-icon>
        </v-btn>
      </template>

      <v-card class="edit-card">
        <v-icon role="img" aria-hidden="true" class="white--text title-icon">
          mdi-pencil
        </v-icon>

        <div class="btn-close">
          <v-icon aria-label="close dialog" role="img" aria-hidden="false" 
            @click="dialog = false">mdi-close-thick</v-icon>
        </div> 
        
        <v-card-text class="ml-n2 mr-0 pr-2">
          <div class="form-progress-wrapper">
            <v-progress-linear v-if="updating || deleting" indeterminate color="primary" class="mb-8"></v-progress-linear>
          </div>
          
          <div v-if="!updating">
            <p class="text-h6 text-center">There are {{count}} criteria for this criteria type.</p>
            <p class="text-h6 text-center">Are you sure you want to delete it?</p>
          </div>
          <div v-else>
            <p class="text-h6 text-center">Getting a count of the criteria of this type.</p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="purple darken-1" outlined @click="dialog = false" >
            CANCEL
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteCriteriaType">
            DELETE CRITERIA TYPES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'TypesDeleteDialog',
  props: {
    item: { type: Object, required: true},
  },
  data() {
    return {
      dialog: false,
      updating: false,
      deleting: false,
      count: 0
    }
  },
  methods: {
    getTypesCount() {
      this.dialog = true;
      this.updating = true;
      this.deleting = false;
      this.$store.dispatch('typesStore/getTypesCount', {criteria_type_id: this.item.criteria_type_id})
        .then(res => {
          this.count = res.count;
          this.updating = false;
        })
    },
    deleteCriteriaType() {
      this.deleting = true;
      this.updating = false;
      this.$store.dispatch('typesStore/deleteTypes', {criteria_type_id: this.item.criteria_type_id})
        .then(res => {
          this.$store.dispatch('typesStore/fetchCriteriaTypes')
            .then(res => {
              this.dialog = false;
              this.deleting = false;
            });
        })
    },
  },
};
</script>

<style></style>
