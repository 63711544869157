<template>
	<div class="upload-field">
		<input
			:id="inputId"
			type="file"
			ref="fileInput"
			class="upload-input"
			@change="handleFileSelect"
			:multiple="multiple"
			accept=".csv, .xls, .xlsx"
			@focus="handleFocus"
			@blur="handleBlur"
		/>

		<div
			class="drag-drop-area"
			@dragover.prevent="handleDragOver"
			@dragleave="handleDragLeave"
			@drop.prevent="handleDrop"
			:class="{ dragging: isDragging }"
			tabindex="0"
			role="button"
			@keydown.space.prevent="handleButtonClick"
			@keydown.enter.prevent="handleButtonClick"
			@click="handleButtonClick"
		>
			Drag & Drop Excel/CSV files, or click
			<a
				href="#"
				class="ml-1"
				@click.prevent.stop="handleButtonClick"
				>here</a
			>
		</div>

		<!-- Display selected files and a clear button -->
		<div
			v-if="hasFiles"
			class="selected-files"
		>
			<div class="d-flex align-center">
				<span class="font-weight-bold">Selected File:</span>
			</div>
			<ul>
				<li
					v-for="(file, index) in selectedFiles"
					:key="index"
					class="d-flex align-center"
				>
					<v-icon
						color="green"
						class="mr-2"
						>mdi-file-excel</v-icon
					>
					<span class="font-weight-bold">
						{{ file.name }}
					</span>
					<v-spacer />
					<v-btn
						@click="clearFiles"
						icon
						color="red"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'UploadField',
		props: {
			multiple: {
				type: Boolean,
				default: false,
			},
			value: {
				// Bound to v-model for two-way data binding
				type: [File, Array],
				default: null,
			},
		},
		data() {
			return {
				isDragging: false,
				inputId: `file-input-${Math.random().toString(36).substring(2, 10)}`,
			};
		},
		computed: {
			selectedFiles() {
				if (this.multiple && Array.isArray(this.value)) {
					return this.value;
				} else if (this.value) {
					return [this.value];
				} else {
					return [];
				}
			},
			hasFiles() {
				return this.selectedFiles.length > 0;
			},
		},
		methods: {
			handleButtonClick() {
				this.$refs.fileInput.click();
			},
			handleFileSelect(event) {
				const files = event.target.files;
				this.updateFiles(files);
			},
			handleDragOver() {
				this.isDragging = true;
			},
			handleDragLeave() {
				this.isDragging = false;
			},
			handleDrop(event) {
				const files = event.dataTransfer.files;
				this.isDragging = false;

				const validFiles = Array.from(files).filter((file) => {
					return [
						'text/csv',
						'application/vnd.ms-excel',
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					].includes(file.type);
				});

				this.updateFiles(validFiles);
			},
			updateFiles(files) {
				if (this.multiple) {
					this.$emit('input', Array.from(files));
				} else {
					this.$emit('input', files[0]);
				}
			},
			clearFiles() {
				this.$emit('input', this.multiple ? [] : null);
				this.$refs.fileInput.value = ''; // Reset the file input
			},
			handleFocus() {
				this.$emit('focus');
			},
			handleBlur() {
				this.$emit('blur');
			},
		},
	};
</script>

<style scoped>
	.upload-field {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.upload-input {
		display: none;
	}

	.drag-drop-area {
		border: 2px dashed #ccc; /* Default border color */
		border-radius: 4px;
		width: 100%;
		height: 200px;
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #4e4e4e; /* Default text color */
		cursor: pointer;
		text-align: center;
		transition: border-color 0.3s, color 0.3s; /* Smooth transition */
	}

	.drag-drop-area:hover {
		border-color: #0280a5; /* Hover border color */
		color: #0280a5; /* Hover text color */
	}

	.drag-drop-area.dragging {
		background-color: #e9f5ff;
	}

	.drag-drop-area a {
		color: #0280a5;
		text-decoration: underline;
		cursor: pointer;
	}

	.selected-files {
		margin-top: 20px;
		width: 100%;
		text-align: left;
	}

	.selected-files ul {
		list-style-type: none;
		padding: 0;
		margin: 0 0 10px 0;
	}

	.selected-files li {
		background-color: #f9f9f9;
		padding: 8px 12px;
		border-radius: 4px;
		margin-bottom: 5px;
		border: 1px solid #ddd;
	}

	.clear-button {
		background-color: #dc3545;
		color: #fff;
		border: none;
		padding: 8px 16px;
		border-radius: 4px;
		cursor: pointer;
	}

	.clear-button:hover {
		background-color: #c82333;
	}
</style>
