<template>
  <!-- summary -->
  <v-expansion-panel class="mb-2">
    <v-expansion-panel-header>SUMMARY</v-expansion-panel-header>
    <v-expansion-panel-content>
      <p v-html="$options.filters.br(summary)" class="pt-4"></p>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'ListViewItemSummary',
  props: {
    summary: { type: String, required: true },
  },
};
</script>

<style></style>
