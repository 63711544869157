<template>
  <div class="types-create d-flex">
    <v-dialog content-class="edit-dialog" persistent v-model="dialog" width="800" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="dialog = true" color="primary" v-bind="attrs" v-on="on"
          class="mt-2 mb-4">
            CREATE CRITERIA TYPE
        </v-btn>
      </template>

      <v-card class="types-create-card">
        <v-icon role="img" aria-hidden="true" class="white--text title-icon">
          mdi-pencil
        </v-icon>

        <div class="btn-close" v-show="!updating">
          <v-icon aria-label="close dialog" role="img" aria-hidden="false" @click="dialog = false" >
            mdi-close-thick
          </v-icon >
        </div>

        <v-card-text class="ml-n2 mr-0 pr-2">
          <div class="form-progress-wrapper">
            <v-progress-linear v-if="updating" indeterminate color="primary" class="mb-8"></v-progress-linear>
          </div>
          <v-form v-model="formValid" ref="createTypeForm">
            <div>
              <p class="title text-h4 mb-3">Criteria Type</p>
            </div>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field data-test="c-code" type="text" v-model="cCode" label="Code*" 
                  required clearable :rules="[rules.required('Code'), rules.codeRule]"></v-text-field>
                </v-col>
              <v-col cols="12" md="6">
                <v-text-field data-test="c-title" type="text" v-model="cTitle" label="Title*" 
                  required clearable :rules="[rules.required('Title')]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field data-test="c-title" type="text" v-model="cDescription" label="Description*" 
                  required clearable :rules="[rules.required('Description')]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <div class="form-group">
                  <div class="group-label">Active</div>
                  <v-btn-toggle v-model="activeValue" mandatory rounded class="elevation-4 ml-1">
                    <v-btn depressed color="outlined">Yes</v-btn>
                    <v-btn depressed color="outlined">No</v-btn>
                  </v-btn-toggle>
                </div>
              </v-col>
              <v-col cols="12" md="5">
                <div class="form-group">
                  <div class="group-label">Sense</div>
                  <v-btn-toggle v-model="senseValue" mandatory rounded class="elevation-4 ml-1">
                    <v-btn depressed color="outlined">Exclusion</v-btn>
                    <v-btn depressed color="outlined">Inclusion</v-btn>
                  </v-btn-toggle>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field data-test="c-index" label="Index" type="number" min="0"
                  v-model="cIndex" :rules="[rules.indexRule]"  
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createTypes" :disabled="!formValid || updating" >
            CREATE TYPES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'TypesEdit',
  props: {
    types: { type: Object},
  },
  data() {
    return {
      formValid: false,
      dialog: false,
      updating: false,
      cCode: '',
      cTitle: '',
      cDescription: '',
      activeValue: 1,
      senseValue: 1,
      cIndex: '',
      rules: {
        required(propertyType) {
          return v => v && v.length > 0 || `${propertyType} is required`
        },
        codeRule(v) {
          const pattern = /^[A-Za-z_][A-Za-z0-9_]+$/
          return pattern.test(v) || 'Invalid Code'
        },
        indexRule(v) {
          const pattern = /^[1-9][0-9]*$/
          return pattern.test(v) || 'Invalid Index'
        }
      },
    };
  },
  created() {
    //this.searchName = this.search.search_name;
  },
  methods: {
    createTypes() {
      this.updating = true;
      let record = {
        criteria_type_code: this.cCode,
        criteria_type_title: this.cTitle,
        criteria_type_desc: this.cDescription
      }
      record.criteria_type_active = this.activeValue == 0 ? 'Y' : 'N'

      if (this.senseValue !== undefined ) {
        record.criteria_type_sense = this.senseValue == 0 ? 'Exclusion' : 'Inclusion';
      }

      if (this.cIndex !== '') {
        record.criteria_column_index = parseInt(this.cIndex);
      }

      //console.log('payload', {record})
      this.$store.dispatch('typesStore/createCriteriaTypes', {record})
        .then((res) => {
          this.$store.dispatch('typesStore/fetchCriteriaTypes')
            .then(res => {
              this.dialog = false;
              this.updating = false;
              this.resetCriteriaTypeForm()
            });
        })
    },
    resetCriteriaTypeForm() {
      this.$refs.createTypeForm.reset();
      this.activeValue = undefined;
      this.senseValue = undefined;
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>

<style lang="scss">
</style>
