<template>
  <div v-if="starredTrials.length > 0">
    <!-- <v-menu :close-on-content-click="closeOnContentClick" -->
    <v-menu :close-on-content-click="closeOnContentClick" :value="menuOpened"
      transition="slide-y-transition" offset-y max-width="500" min-width="320">
      <template v-slot:activator="{ on, attrs }">
        <v-btn id="starred-trials" text color="primary" dark v-bind="attrs" v-on="on" 
          @click="menuOpened = true" class="no-ripple" >
          <v-icon size="44" class="white--text"
            aria-label="star" role="img" aria-hidden="false">mdi-bookmark</v-icon>
            <div class="chip-notification white--text">{{starredTrials.length}}</div>
        </v-btn>      
      </template>

      <div id="trial-widget-container" class="mt-1">
        <div class="widget-header">
          <p class="trial-widget-title">My Saved Trials <span>({{starredTrials.length}})</span></p>
          <p class="mb-0">Saved trials are saved to your account. To remove a trial from this list, use "
            <v-icon size="20" class="yellow--text text--darken-1"
              aria-label="star" role="img" aria-hidden="false">mdi-bookmark</v-icon>
            " to remove the trial from your saved trials.</p>
        </div>
        <div class="widget-body">
          <div v-for="trial in starredTrials" :key="trial.nct_id" class="trial-widget-card">
            <div class="trial-widget-card-title d-flex align-center">
              <v-btn text class="nctid primary--text pa-0" @click="loadDetails(trial)">{{trial.nct_id}}</v-btn>
              <v-spacer></v-spacer>
              <!-- <span>Starred on Mar 22, 2022</span> -->
              <v-btn text color="primary" dark class="pa-0 ml-2" @click="toggleStarStatus(trial)">
                <v-icon size="28" class="yellow--text text--darken-1"
                  aria-label="star" role="img" aria-hidden="false">mdi-bookmark</v-icon>
              </v-btn>
            </div>
            <div class="trial-widget-card-body">
              <p>{{trial.brief_title}}</p>
              <p class="prop">Phase {{trial.phase}}, {{trial.disease_names_lead}}</p>
            </div>
          </div>
          <p v-if="starredTrials.length == 0" class="text-center mb-0">No starred trials</p>
        </div>
        
      </div>
    </v-menu>

    <v-dialog v-model="dialog" scrollable max-width="1500px" content-class="modal-details" >
      <v-card>
        <v-card-title>
          <p class="mb-0 pl-2 pr-8" style="word-break: normal;">
            <a :href="'https://www.cancer.gov/about-cancer/treatment/clinical-trials/search/v?id=' + selectedTrial.nct_id + '&amp;r=1'" target="_blank" 
              class="font-weight-bold pr-2">{{selectedTrial.nct_id}}</a> 
            {{selectedTrial.brief_title}} 
          </p>

          <div class="btn-close">
            <v-icon aria-label="close dialog" role="img" aria-hidden="false" 
              @click="dialog = false">mdi-close-thick</v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <list-view-item-side :trial="selectedTrial" />
            </v-col>
            <v-col cols="12" md="9">
              <div v-if="trialDetails">
                <v-expansion-panels :value="detailsPanel" flat multiple class="child details-expansion">
                  <!-- description -->
                  <list-view-item-description v-if="trialDetails.detail_description" :description="trialDetails.detail_description" />
                  <!-- summary -->
                  <list-view-item-summary v-if="trialDetails.brief_summary" :summary="trialDetails.brief_summary" />
                  <!-- inclusion -->
                  <list-view-item-inc-exc label="Inclusion" :inclusion_exclusion="trialDetails.inclusion_exclusion" />
                  <!-- exclusion -->
                  <list-view-item-inc-exc label="Exclusion" :inclusion_exclusion="trialDetails.inclusion_exclusion" />
                  <!-- sites -->
                  <list-view-item-sites v-if="trialDetails.sites.length > 0" :sites="trialDetails.sites" />
                </v-expansion-panels>
              </div>
              <div v-else class="mt-8 mb-4">
                <v-progress-circular class="mx-auto d-block" indeterminate color="#0080A5"></v-progress-circular>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ListViewItemSide from './ListViewItemSide.vue';
import ListViewItemDescription from './ListViewItemDescription.vue';
import ListViewItemSites from './ListViewItemSites.vue';
import ListViewItemSummary from './ListViewItemSummary.vue';
import ListViewItemIncExc from './ListViewItemIncExc.vue';

export default {
  name: 'StarredTrialWidget',
  components: { ListViewItemSide, ListViewItemDescription, ListViewItemSites, ListViewItemSummary, ListViewItemIncExc },
  data() {
    ListViewItemSide
    return {
      closeOnContentClick: false,
      menuOpened: false,
      dialog: false,
      detailsPanel: [0, 1],
      selectedTrial: {},
      trialDetails: null
    }
  },
  created() {
    //this.$store.dispatch('trialsStore/fetchStarredTrials')
  },
  methods: {
    loadDetails(trial) {
      this.selectedTrial = trial;
      this.trialDetails = null;
      let storeTrialDetails = this.$store.getters['detailsStore/getTrialDetails'](trial.nct_id);
      if (storeTrialDetails !== undefined) {
        this.trialDetails = storeTrialDetails
      } else {
        this.$store.dispatch('detailsStore/getTrialDetailsByIdArr', { nct_ids: [trial.nct_id] })
          .then(res => {
            this.trialDetails = res;
          })
      }
      this.dialog = true;
      this.menuOpened = false;
    },
    toggleStarStatus(trial) {
      this.$store.dispatch('trialsStore/toggleStarStatus', trial)
    }
  },
  computed: {
    starredTrials() {
      return this.$store.getters['trialsStore/getStarredTrials']
    }
  }
}
</script>

<style lang="scss">
.details-expansion {
  .v-expansion-panel-header {
      background-color: #D5EBF1;
      padding: 16px 24px;
      min-height: auto;
  }
  .v-expansion-panel {
      border: 1px solid #BFBFBF;
  }
}

</style>