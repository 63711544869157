<template>
  <span :class="className">
    <v-icon v-if="value == true || value == 'true'" size="24" class="teal--text darken-3">mdi-check-bold</v-icon>
    <v-icon v-else size="20" class="brown--text lighten-1">mdi-minus-circle-outline</v-icon>
  </span>
</template>

<script>
export default {
  name: 'MatchInc',
  props: {
    value: {
      type: [Boolean, String],
      required: true
    }
  },
  computed: {
    className() {
      return this.value === true ? 'check' : 'stop';
    }
  }
}
</script>
<style>

</style>