<template>
	<v-dialog
		:value="videoDialog"
		width="auto"
		persistent
	>
		<v-card>
			<v-card-title class="text-h5 px-2">
				<!-- Play video intro msg here. Please turn on your speakerphone. -->

				<div class="btn-close">
					<v-icon
						aria-label="close dialog"
						role="img"
						aria-hidden="false"
						@click="closeIntroVideo"
						>mdi-close-thick</v-icon
					>
				</div>
			</v-card-title>

			<v-card-text class="px-2">
				<iframe
					v-if="videoDialog"
					src="https://drive.google.com/file/d/1uCB6Xcoc7f_pLknOsztbBc09iFby3CtV/preview"
					width="1366"
					height="768"
					allow="autoplay"
				></iframe>
				<!-- <video-player
					:options="videoOptions"
					ref="vPlayer"
				/> -->
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import VideoPlayer from '@/components/VideoPlayer.vue';

	export default {
		name: 'VideoDialog',
		components: { VideoPlayer },
		data() {
			return {
				videoOptions: {
					autoplay: false,
					controls: true,
					muted: false,
					poster: '/img/emFact_poster.png',
					sources: [
						{
							// src:'/media/emFact_demo_2023-01-30.mp4',
							src: '',
							type: 'video/mp4',
						},
					],
				},
			};
		},
		methods: {
			closeIntroVideo() {
				this.$store.commit('videoStore/UPDATE_VIDEO_DIALOG', false);
			},
		},
		computed: {
			videoDialog() {
				return this.$store.getters['videoStore/getVideoDialog'];
			},
		},
	};
</script>

<style></style>
