<template>
  <span class="btn-star">
    <v-btn text color="primary" class="short px-0 no-min-width" @click="toggleStarStatus">
      <v-icon v-if="starStatus == false" size="24" class="grey--text text--lighten-1"
        aria-label="star" role="img" aria-hidden="false">mdi-bookmark</v-icon>
      <v-icon v-else size="24" class="yellow--text text--darken-1"
        aria-label="star" role="img" aria-hidden="false">mdi-bookmark</v-icon>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'Star',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleStarStatus() {
      this.$store.dispatch('trialsStore/toggleStarStatus', this.item)
    }
  },
  computed: {
    starStatus() {
      //return this.$store.getters['trialsStore/getStarStatus'](this.item.id - 1)
      return this.item.starred
    }
  }
}
</script>

<style>

</style>