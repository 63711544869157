<template>
  <div class="search-edit d-flex align-center justify-center">
    <div class="pr-20">
      <v-dialog content-class="edit-dialog" persistent v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="#9E7D22" class="short px-0 no-min-width" v-bind="attrs" v-on="on" 
            @click="dialog = true">
            <v-icon aria-label="Edit Search Name" role="img" aria-hidden="false">
              mdi-pencil-box-outline
            </v-icon><span class="d-md-none">EDIT NAME</span>
          </v-btn>
        </template>

        <v-card class="edit-card">
          <v-icon role="img" aria-hidden="true" class="white--text title-icon">
            mdi-bookmark
          </v-icon>

          <div class="btn-close">
            <v-icon aria-label="close dialog" role="img" aria-hidden="false" 
              @click="dialog = false">mdi-close-thick</v-icon>
          </div> 
          
          <v-card-text class="ml-n2 mr-0 pr-2">
            <div>
              <p class="title text-center text-h4 mb-3">Please Provide A Name</p>
              <p class="text-h6 text-center">This will replace the default search name<br /> that was assigned to your saved search</p>
            </div>
            <div>
              <v-text-field data-test="username" type="text" v-model="searchName" 
                label="" required clearable :loading="updating"></v-text-field>
            </div>
          </v-card-text>

          <v-card-actions>
            <!-- <v-btn color="purple darken-1" outlined @click="dialog = false" >
              CANCEL
            </v-btn> -->
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="updateSearchName" :disabled="!validSearchName || updating">
              UPDATE NAME
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchEdit',
  props: {
    search: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
      searchName: '',
      updating: false,
    }
  },
  created() {
    this.searchName = this.search.search_name
  },
  methods: {
    updateSearchName() {
      this.updating = true;

      this.$store.dispatch('searchStore/updateSearchName', {
        "session_uuid": this.search.session_uuid, "search_name": this.searchName
      }).then(response => {
        this.updating = false;
        this.dialog = false;
      })
    }
  },
  computed: {
    validSearchName() {
      return this.searchName !== '' && this.searchName !== null
    },
  },
  watch: {
    search() {
      this.searchName = this.search.search_name
    }
  }
};
</script>

<style lang="scss">
#app {
  &.v-application {
    .v-dialog{
      .search-edit {
        .name {
          width: 200px;
        }
      }
      .search-edit-card {
        position: relative;
        margin-top: 40px;
        padding-top: 60px !important;
        .v-icon.title-icon {
          font-size: 60px;
          background-color: $primary-blue;
          padding: 10px;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: -40px;
          transform: translateX(-50%)
        }
        .btn-close {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }
    
    .search-edit-dialog {
      box-shadow: none;
      //transform: translateY(-40px);
    }
  }
}
</style>
