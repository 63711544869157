<template>
  <v-expansion-panel class="mb-3 elevation-2" :class="{starred: trial.starred}">
    <v-row class="no-negative-margin">
      <v-col cols="12" class="expansion-title-wrapper">
        <!-- <div class="matching-criteria d-flex align-center flex-wrap" v-if="trial.score !== 0"
          :style="`background-color: ${color}`">
          <div v-for="(value, name) in trial.score_contributions" :key="name">
            {{mapNameToLabel(name) | titleCase}}<span class="percentage-chip">{{value}}%</span>
          </div>
        </div> -->

        <!-- score with number /> -->
        <!-- <score-circle :score="trial.score" :trial="trial" /> -->
        <!-- <score-bar :score="trial.score" /> -->
        <div class="mb-0 matching-trial-title d-flex" :class="{starred: trial.starred}">
          <div class="pr-4 pb-2">
            <a class="font-weight-bold e-link" :href="'https://www.cancer.gov/about-cancer/treatment/clinical-trials/search/v?id=' + trial.nct_id + '&amp;r=1'" target="_blank" :key="trial.nct_id">
              {{trial.nct_id}}
              <v-icon small color="primary">mdi-open-in-new</v-icon>
            </a>
            <!-- <a class="font-weight-bold e-link" :href="'https://clinicaltrials.gov/ct2/show/' + trial.nct_id" target="_blank" :key="trial.nct_id">
              {{trial.nct_id}}
              <v-icon small color="primary">mdi-open-in-new</v-icon>
            </a> -->
          </div> 
          <div class="matching-trial-title-desc">
            {{trial.brief_title}}
          </div>
        </div>

        <eye-hidden-box :item="trial" />
        <star-box :item="trial" />
        <v-expansion-panel-header class="pt-2 parent" @click="fetchTrialDetailsArr">
          <template v-slot:actions> 
            <div class="btn-view-details">
                <v-icon size="18" color="primary"
                  aria-label="show trial" role="img" aria-hidden="true" > 
                  mdi-clipboard-text</v-icon> {{contentOpened ? 'COLLAPSE' : 'VIEW DETAILS'}}
            </div>
          </template>
        </v-expansion-panel-header>
      </v-col>
    </v-row>

    <v-expansion-panel-content class="mx-md-6 pt-4 parent">
      <v-row>
        <!-- left detail -->
        <v-col cols="12" md="3">
          <list-view-item-side :trial="trial" />
        </v-col>
        <!-- right col: more details -->
        <v-col cols="12" md="9">
          <div v-if="trialDetails && fetchingDetails == false">
            <v-expansion-panels :value="detailsPanel" flat multiple class="child">
              <!-- description -->
              <list-view-item-description v-if="trialDetails.detail_description" :description="trialDetails.detail_description" />
              <!-- summary -->
              <list-view-item-summary v-if="trialDetails.brief_summary" :summary="trialDetails.brief_summary" />
              <!-- inclusion -->
              <list-view-item-inc-exc label="Inclusion" :inclusion_exclusion="trialDetails.inclusion_exclusion" />
              <!-- exclusion -->
              <list-view-item-inc-exc label="Exclusion" :inclusion_exclusion="trialDetails.inclusion_exclusion" />
              <!-- sites -->
              <list-view-item-sites v-if="trialDetails.sites.length > 0" :sites="trialDetails.sites" />
            </v-expansion-panels>
          </div>
          <div v-else class="mt-8 mb-4">
            <v-progress-circular class="mx-auto d-block" indeterminate color="#0080A5"></v-progress-circular>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import EyeHidden from './EyeHidden.vue';
import EyeHiddenBox from './EyeHiddenBox.vue';
import ListViewItemDescription from './ListViewItemDescription.vue';
import ListViewItemIncExc from './ListViewItemIncExc.vue';
import ListViewItemSide from './ListViewItemSide.vue';
import ListViewItemSites from './ListViewItemSites.vue';
import ListViewItemSummary from './ListViewItemSummary.vue';
import Match from './Match.vue';
import MatchExc from './MatchExc.vue';
import ScoreBar from './ScoreBar.vue';
import ScoreCircle from './ScoreCircle.vue';
import Star from './Star.vue';
import StarBox from './StarBox.vue';
import WhNoNumber from './svg/WhNoNumber.vue';

export default {
  name: 'ListViewItem',
  components: { EyeHidden, Match, MatchExc, Star, WhNoNumber, ScoreCircle, ListViewItemSide, ListViewItemDescription, ListViewItemSummary, ListViewItemIncExc, ListViewItemSites, ScoreBar, EyeHiddenBox, StarBox},
  props: {
    trial: {required: true, type: Object}
  },
  data() {
    return {
      contentOpened: false,
      fetchingDetails: false,
      detailsPanel: [0, 1],
      //localTrialDetails: null,
      hasBeenOpened: false
    }
  },
  methods: {
    fetchTrialDetailsArr(refreshContentOpened = true) {
      if (refreshContentOpened) {this.contentOpened = !this.contentOpened;} 
      if (this.contentOpened == true) {
        let latLonDistance = this.geoArr.filter(geo => geo.lat !== '').map(geo => {
          return {
            latitude: geo.lat,
            longitude: geo.lon,
            distance: this.distance
          }
        })
        let payload = this.distance > 0 ? {
          nct_ids: [this.trial.nct_id],
          coordinates: latLonDistance
        } : { nct_ids: [this.trial.nct_id] }
        if (!this.hasBeenOpened) {
          this.fetchingDetails = true;
          this.hasBeenOpened = true;
          this.$store.dispatch('detailsStore/getTrialDetailsByIdArr', payload)
            .then(res => {
              //this.localTrialDetails = res;
              this.fetchingDetails = false;
            })
        } 
      } 
    },
    mapNameToLabel(name) {
      return this.trialsHeader.find(header => header.value == name).text
    },
    formatAddress(site) {
      return `https://www.google.com/maps/place/${site.org_address_line_1},+${site.org_city},+${site.org_state_or_province}+${site.org_postal_code}`
    }
  },
  computed: {
    geo() {
      return this.$store.getters['criteriaStore/getGeo']
    },
    geoArr() {
      return this.$store.getters['criteriaStore/getGeoArr']
    },
    trialDetails() {
      return this.$store.getters['detailsStore/getTrialDetails'](this.trial.nct_id)
    },
    trialsHeader() {
      return this.$store.getters['trialsStore/getTrialsHeader']
    },
    distance() {
      return this.$store.state.trialsStore.distance
    },
    color() {
      if (this.trial.score >= 67) {
        return '#EAF6F9'
      } else if (this.score >= 34) {
        return '#FCF0FD'
      } else {
        return '#f6c2aa'
      }
    }
  },
  /* watch: {
    distance() {
      this.hasBeenOpened = false;
      this.fetchTrialDetailsArr(false);
    },
    geoArr() {
      this.hasBeenOpened = false;
      this.fetchTrialDetailsArr(false);
    },
    trialId(newValue, oldValue) {
      console.log('newValue', newValue, oldValue)
      this.hasBeenOpened = false;
      this.fetchTrialDetailsArr(false);
    }
  }, */
  updated() {
  }
}
</script>

<style>

</style>