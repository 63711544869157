export default {
  state: {
    notification: {
      type: 'alert-success',
      msg: 'Updating Request....',
      show: false
    }
  },
  getters: {
    getNotification(state) {
      return state.notification;
    }
  },
  mutations: {
    SHOW(state, payload) {
      let timeout = 0;
      if (state.notification.show) {
        timeout = 300;
        state.notification.show = false;
      }
      setTimeout(() => {
        state.notification = payload
      }, timeout)
    },
    HIDE(state) {
      state.notification.show = false
    }
  },
  actions: {
    show({commit}, payload) {
      commit('SHOW', payload)
    },
    hide({commit}) {
      commit('HIDE')
    }
  }
}