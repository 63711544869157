<template>
  <v-row>
    <zip-code-widget v-for="index in allowZip" :key="index" :index="index-1" 
      @geoUpdated="updateGeo" @zipCodeValid="updateZipCodeValid"
      :ref="'widget' + index" />
    <!-- {{geoInfoArr}}
    <p>allValid: {{checkAllZipcodeValid}}</p> -->
  </v-row>
</template>

<script>
import ZipCodeWidget from './ZipCodeWidget.vue'

export default {
  components: { ZipCodeWidget },
  name: 'ZipCodes',
  data() {
    return {
      allowZip: 3,
      geoInfoArr: [],
      zipCodeValid: [true, true, true]
    }
  },
  created() {
    this.setDefaultGeoInfoArr()
  },
  methods: {
    updateGeo(e) {
      this.$set(this.geoInfoArr, e.index, e.geoData);
    },
    updateZipCodeValid(e) {
      this.zipCodeValid.splice(e.index, 1, e.status);
      if (e.refresh || !e.status) { //when zipcode = '', refresh is true
        this.$set(this.geoInfoArr, e.index, { lat: '', lon: '', city: '', state: '', zipcode: '' });
      }
      this.$emit('updateZipcodesValid', this.zipCodeValid.every(status => status === true));
      this.$store.commit('criteriaStore/UPDATE_TEMP_GEO', this.geoInfoArr)
    },
    reset() {
      this.setDefaultGeoInfoArr();
      for (let i=0; i < this.allowZip; i++) {
        this.$refs[`widget${i+1}`][0].reset();
      }
    },
    setGeoArr(geoArr) {
      if (geoArr.length > 0) {
        this.$store.commit('criteriaStore/UPDATE_TEMP_GEO', geoArr) 
        for (let i=0; i < this.allowZip; i++) {
          //if (geoArr[i].zipcode !== '') {
            this.$refs[`widget${i+1}`][0].setGeo(geoArr[i].zipcode);
          //}
        }
      } else {
        this.$store.commit('criteriaStore/UPDATE_TEMP_GEO', [
          { lat: '', lon: '', city: '', state: '', zipcode: '' },
          { lat: '', lon: '', city: '', state: '', zipcode: '' },
          { lat: '', lon: '', city: '', state: '', zipcode: '' }
        ]) 
        for (let i=0; i < this.allowZip; i++) {
          this.$refs[`widget${i+1}`][0].setGeo('');
        }
      }
    },
    setDefaultGeoInfoArr() {
      for (let i=0; i < this.allowZip; i++) {
        this.geoInfoArr.splice(i, 1, { lat: '', lon: '', city: '', state: '', zipcode: '' })
      }
    },
  },
  computed: {
    /* checkAllZipcodeValid() {
      let status = this.zipcodeValid.every(status => status === true);
      this.$emit('updateZipcodesValid', status);
      this.$store.commit('criteriaStore/UPDATE_TEMP_GEO', this.geoInfoArr)
      return status
    } */
  }
}
</script>

<style>

</style>