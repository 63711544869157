<template>
  <section id="loading-wrapper" class="root-section">
    <v-container class="py-10">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="5">
          <div class="text-center d-flex align-center justify-center">
            <span><img src="/img/FACTrialLogo.png" alt="FACTrial Logo" /></span>
          </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="5">
          <v-card elevation="2" class="pa-10">
            <div>
              <p class="form-title">
                <strong>FORGOT PASSWORD</strong>
              </p>
              <div v-if="!codeSent">
                <p>Please enter your email address. We will send you a reset code to your registered email.</p>

                <v-form data-test="send-code-form" v-model="sendCodeValid" @submit.prevent="sendCodeToUser" class="pt-4">
                  <v-text-field data-test="email" type="text" v-model="email" :rules="emailRules"
                    label="Email Address*" required ></v-text-field>
                  <p v-if="sendCodeErrorMsg" class="error--text v-messages mt-0">
                    {{ sendCodeErrorMsg }}
                  </p>

                  <v-btn data-test="send-code" block :disabled="!sendCodeValid" @click="sendCodeToUser" class="mt-16 btn-search" >
                    SUBMIT
                  </v-btn>
                </v-form>
              </div>

              <div v-show="codeSent">
                <p>Please enter a new password and the reset code emailed to you.</p>

                <v-form data-test="reset-form" v-model="valid" class="pt-4" ref="resetPasswordForm">
                  <v-text-field data-test="new-password" type="password"
                    v-model="newPassword" :rules="passwordRules" label="New Password*" required
                    @keyup.enter="resetPassword"></v-text-field>
                    <p class="hint">Must contain at least 12 characters and  at least one character from each of the following four categories: Lowercase letters (a-z), Uppercase letters (A-Z), Numbers (0-9) and symbol (! @ # $ % ^ &amp; *)</p>
                  <v-text-field data-test="confirm-password" type="password"
                    v-model="confirmPassword" :rules="passwordRules" label="Confirm Password*" required
                    @keyup.enter="resetPassword"></v-text-field>
                    <br />
                  <v-text-field data-test="code" type="text" v-model="code" :rules="codeRules"
                    label="Reset Code*" required
                    @keyup.enter="resetPassword"></v-text-field>

                  <p v-if="resetPassErrorMsg" class="error--text v-messages mt-0">
                    {{ resetPassErrorMsg }}
                  </p>

                  <v-btn data-test="reset-password" block :disabled="!valid" @click="resetPassword" class="mt-16 btn-search" >
                    RESET PASSWORD
                  </v-btn>
                </v-form>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { Auth } from 'aws-amplify';

export default {
  name: 'Forgot',
  components: {},
  data() {
    return {
      valid: false,
      sendCodeValid: false,
      email: '',
      code: '',
      newPassword: '',
      confirmPassword: '',
      user: null,
      codeSent: false,
      emailRules: [
        (v) => !!v || 'Email address is required',
      ],
      codeRules: [
        (v) => !!v || 'Reset code is required',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => {
          const pattern = /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/;
          return pattern.test(v) || 'Password format is invalid';
        },
      ],
      confirmPasswordRules: [
        (v) => !!v || 'Password is required',
        (v) => {
          return this.newPassword == v || 'ID and Password do not match!';
        },
      ],
      sendCodeErrorMsg: '',
      resetPassErrorMsg: ''
    };
  },
  mounted() {
    //this.valid = false;
  },
  methods: {
    ...mapActions('authStore', {
      a_setUser: 'setUser',
    }),
    validate() {
      this.$refs.form2.validate();
    },
    reset() {
      this.$refs.form2.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    sendCodeToUser() {
      if (this.email !== '') {
        Auth.forgotPassword(this.email)
          .then(data => {
            this.codeSent = true;
          })
          .catch(err => {
            this.sendCodeErrorMsg = err.message
          });
      }
    },
    resetPassword() {
      if (this.valid) {
        Auth.forgotPasswordSubmit(this.email, this.code, this.newPassword)
          .then(data => {
            this.$router.push({name: 'Login'})
          })
          .catch(err => {
            this.resetPassErrorMsg = err.message
          });
      } else {
        this.$refs['resetPasswordForm'].validate()
      }
    }
  },
};
</script>

<style lang="scss">
form[data-test='register-form'] {
  //.v-messages {display: none;}
  .error--text {
    .v-messages {
      display: flex;
    }
  }
  .v-messages__wrapper {
    margin-bottom: 10px;
  }
}
</style>
