<template>
  <div class="d-flex align-center mb-2 score-bar-wrapper">
    <div class="score-bar ml-4">
      <div class="bg"></div>
      <div class="score" :style="`width: ${score}%; background-color: ${color}`"></div>
    </div>
    <div class="score-label ml-2">{{score}}%</div>
  </div>
  
</template>

<script>
export default {
  name: 'ScoreBar',
  props: {
    score: {type: Number, required: true}
  },
  computed: {
    color() {
      if (this.score >= 67) {
        return '#1CB4C7'
      } else if (this.score >= 34) {
        return '#AA6AB4'
      } else {
        return '#F08350'
      }
    }
  }
}
</script>

<style lang="scss">
.score-bar {
  position: relative;
  width: 160px; 
  .bg {
    height: 10px;
    background-color: #f0f0f0;
  }
  .score {
    position: absolute;
    height: 10px;
    top: 0; left: 0;
  }
  
}
.score-label {
  font-size: 0.75rem;
}
</style>