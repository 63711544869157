import cloneDeep from 'lodash/cloneDeep';

export function truthy(data) {

  // number/boolean
  if (typeof (data) === 'number' || typeof (data) === 'boolean') {
    return true
  }

  // date
  if (data instanceof Date) {
    return !isNaN(data.valueOf())
  }

  // undefined
  if (typeof (data) === 'undefined' || data === null) {
    return false
  }

  // array
  if (typeof (data.length) !== 'undefined') {
    return data.length !== 0
  }

  // object
  let count = 0
  for (let i in data) {
    //if ({}.hasOwnProperty.call(data, i)) {
      count++
    //}
  }

  return count !== 0
}

export function deepOmit(o, deleteKeys) {
  const object = cloneDeep(o)

  for (const key in object) {
    if (deleteKeys.includes(key)) {
      delete object[key]
    } else if (typeof object[key] === 'object') {
      object[key] = deepOmit(object[key], deleteKeys)
    }
  }

  return object
}
