<template>
  <div id="loading-mag" :style="{width: w + 'px', height: h + 'px'}" :class="{'mag-fade-out': doneAnimate}">
    <svg id="mag-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-50 -50 600 600" xml:space="preserve">
      <path d="M503.847,432.949l-89.897-89.897c-0.002-0.002-0.003-0.003-0.006-0.006s-0.003-0.003-0.006-0.006l-32.135-32.135
        c-10.855-10.856-28.522-10.86-39.381,0l-3.939,3.939l-13.571-13.571c26.587-31.878,42.607-72.861,42.607-117.519
        C367.517,82.432,285.085,0,183.762,0S0.009,82.432,0.009,183.754s82.43,183.754,183.753,183.754
        c44.658,0,85.641-16.02,117.519-42.606l13.571,13.57l-3.94,3.94c-10.854,10.857-10.854,28.523,0.002,39.381l122.043,122.043
        c10.881,10.883,28.499,10.886,39.383,0.001l31.506-31.506C514.705,461.474,514.705,443.808,503.847,432.949z M183.762,334.091
        c-82.896,0-150.337-67.441-150.337-150.337S100.866,33.417,183.762,33.417S334.1,100.858,334.1,183.754
        S266.658,334.091,183.762,334.091z M338.482,362.103l23.629-23.629l16.388,16.388l-23.629,23.629L338.482,362.103z
          M452.649,476.269l-74.15-74.15l23.629-23.629l74.15,74.15L452.649,476.269z"/>

      <!-- <path class="GreyLine" d="M183.76,66.835c-9.228,0-16.709,7.481-16.709,16.709c0,9.228,7.481,16.709,16.709,16.709
        c46.045,0,83.505,37.457,83.505,83.5c0,9.228,7.481,16.709,16.709,16.709c9.228,0,16.709-7.481,16.709-16.709
        C300.683,119.284,248.231,66.835,183.76,66.835z"/> -->

      <g transform="translate(185, 25), rotate(45), scale(0.45)">
        <g>
          <path class="grey-line" d="M260.655,361.891c-37.236-17.455-65.164-37.236-81.455-59.345h123.345c6.982,0,11.636-4.655,11.636-11.636
            s-4.655-11.636-11.636-11.636H167.564c-2.327-8.145-4.655-15.127-4.655-23.273c0-36.073,30.255-64,54.691-81.455
            c5.818-3.491,6.982-10.473,3.491-16.291s-10.473-6.982-16.291-3.491c-43.055,30.255-65.164,65.164-65.164,101.236
            c0,48.873,39.564,94.255,111.709,126.836c43.055,19.782,73.309,44.218,88.436,70.982H209.455c-6.982,0-11.636,4.655-11.636,11.636
            c0,6.982,4.655,11.636,11.636,11.636h138.473c1.164,3.491,1.164,8.145,1.164,11.636v11.636c0,6.982,4.655,11.636,11.636,11.636
            s11.636-4.655,11.636-11.636v-11.636C372.364,439.855,332.8,394.473,260.655,361.891z"/>
        </g>

        <g>
          <path class="grey-line" d="M221.091,390.982c-3.491-5.818-10.473-6.982-16.291-3.491c-43.055,30.255-65.164,65.164-65.164,101.236v11.636
            c0,6.982,4.655,11.636,11.636,11.636c6.982,0,11.636-4.655,11.636-11.636v-11.636c0-36.073,30.255-64,54.691-81.455
            C223.418,403.782,224.582,396.8,221.091,390.982z"/>
        </g>

        <g>
          <path class="grey-line" d="M260.655,129.164c-43.055-19.782-73.309-44.218-88.436-70.982h130.327c6.982,0,11.636-4.655,11.636-11.636
            s-4.655-11.636-11.636-11.636H164.073c-1.164-3.491-1.164-8.146-1.164-11.636V11.636C162.909,4.655,158.255,0,151.273,0
            c-6.982,0-11.636,4.655-11.636,11.636v11.636c0,48.873,39.564,94.255,111.709,126.836c37.236,17.455,65.164,37.236,81.455,59.345
            H209.455c-6.982,0-11.636,4.655-11.636,11.636c0,6.982,4.655,11.636,11.636,11.636h134.982c2.327,8.145,4.655,15.127,4.655,23.273
            c0,36.073-30.255,65.164-55.855,82.618c-5.818,3.491-6.982,10.473-3.491,16.291c2.327,3.491,5.818,4.655,9.309,4.655
            c2.327,0,4.655-1.164,6.982-2.327c43.055-29.091,66.327-64,66.327-101.236C372.364,207.127,332.8,161.745,260.655,129.164z"/>
        </g>

        <g>
          <path class="grey-line" d="M360.727,0c-6.982,0-11.636,4.655-11.636,11.636v11.636c0,36.073-30.255,65.164-55.855,82.618
            c-5.818,3.491-6.982,10.473-3.491,16.291c2.327,3.491,5.818,4.655,9.309,4.655c2.327,0,4.655-1.164,6.982-2.327
            c43.055-29.091,66.327-64,66.327-101.236V11.636C372.364,4.655,367.709,0,360.727,0z"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import Vivus from 'vivus';

export default {
  name: 'LoadingMag',
  props: ['w', 'h'],
  data() {
    return {
      loadingMag: null,
      doneAnimate: false
    }
  },
  mounted() {
    let svgAnimOpt = {start: 'manual', type: 'oneByOne', duration: 240, animTimingFunction: Vivus.EASE_IN};
    this.loadingMag = new Vivus('mag-svg', svgAnimOpt, () => {
      this.doneAnimate = true;
      setTimeout(() => {
        this.doneAnimate = false
        this.loadingMag.reset().play()
      }, 2000)
    })

    this.loadingMag.play()
  },
  destroyed() {
    this.loadingMag.destroy()
  }
}
</script>

<style lang="scss">
#loading-mag {
  /* width: 300px; height: 300px; */
  margin: auto;
  svg * {
    fill: none; stroke: #0080A5; stroke-width: 2;
    .grey-line {stroke: #E0E0E0;}
  }
  #mag-svg {
    position: relative;
    margin-top: -30px;
  }
}
.mag-fade-out {
  animation: fadeOutIn 4s;
}
@keyframes fadeOutIn {
  from {opacity: 1;}
  30% {opacity: 1;}
  60% {opacity: 0;}
  to {opacity: 1;}
}

</style>