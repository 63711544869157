<template>
  <div>
    <div class="loading-wrapper d-flex align-center justify-center">
      <v-card class="pa-4 mx-4">
        <v-card-title class="text-h4" style="word-break: normal !important">
          Please select one of the following programs:
        </v-card-title>

        <v-card-text class="program-card"> 
          <div>
            <v-radio-group v-model="gp">
              <v-radio v-for="(item, index) in programs" :key="index"
                :value="item.cognito_group_name">
                <template v-slot:label>
                  <p class="p-name">{{item.emfact_program_name}}</p>
                  <p class="p-desc">{{item.emfact_program_desc}}</p>
                </template>
              </v-radio>
            </v-radio-group>
          </div>

          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="chooseGroup">Choose program</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectGroupPanel',
  props: {
    programs: {
      type: Array, 
      required: true
    }
  },
  data() {
    return {
      gp: ''
    }
  },
  methods: {
    chooseGroup() {
      this.$emit('chooseGroup', this.gp)
    },
  }
}
</script>

<style>

</style>