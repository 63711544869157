import Vue from 'vue'

const requireModule = require.context(
  // Search for files in the current directory.
  '.',
  // Search for files in subdirectories.
  true,
  // Include any .js files that are not this file.
  /^((?!globalDirectives).)*\.js$/
)

requireModule.keys().forEach(fileName => {
  const moduleDefinition = requireModule(fileName).default || requireModule(fileName)
  Vue.use(moduleDefinition)
}) 