<template>
  <v-dialog v-model="dialog" width="90%" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="short mt-4" color="primary" v-bind="attrs" v-on="on">
        Admin
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Criteria Types
        <v-spacer></v-spacer>
        <div class="btn-close">
          <v-icon @click="dialog = false" aria-hidden="false" aria-label="close dialog" role="img">
              mdi-close-thick
          </v-icon>
        </div>
      </v-card-title>

      <v-card-text class="ml-n2 mr-0 pr-2">
        <criteria-types />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Auth } from 'aws-amplify';
import CriteriaTypes from '../CriteriaTypes.vue';

export default {
  name: 'AdminDialog',
  components: { CriteriaTypes },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    hideDialog() {
      this.dialog = false;
    },
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.$emit('typesDialogOpened')
      }
    }
  }
};
</script>

<style></style>
