<template>
  <v-expansion-panel class="mb-3 elevation-2">
    <v-row class="no-negative-margin">
      <v-col cols="12" class="expansion-title-wrapper share">
        <!-- <div
          class="matching-criteria d-flex align-center flex-wrap"
          v-if="trial.score !== 0"
          :style="`background-color: ${color}`"
        >
          <div v-for="(value, name) in trial.score_contributions" :key="name">
            {{ mapNameToLabel(name) | titleCase
            }}<span class="percentage-chip">{{ value }}%</span>
          </div>
        </div> -->

        <!-- score with number /> -->
        <!-- <score-circle :score="trial.score" :trial="trial" /> -->
        <!-- <score-bar :score="trial.score" /> -->
        <div class="mb-0 matching-trial-title d-flex">
          <div class="pr-4 pb-2">
            <a class="font-weight-bold e-link" target="_blank"
              :href="'https://clinicaltrials.gov/ct2/show/' + trial.nct_id"
              :key="trial.nct_id">
              {{ trial.nct_id }}
              <v-icon small color="primary">mdi-open-in-new</v-icon>
            </a>
          </div>
          <div class="matching-trial-title-desc">
            {{ trial.brief_title }}
          </div>
        </div>

        <v-expansion-panel-header class="pt-2 parent share" @click="fetchTrialDetailsArr">
          <template v-slot:actions>
            <div class="btn-view-details">
              <v-icon size="18" color="primary" aria-label="show trial" role="img" aria-hidden="true">
                mdi-clipboard-text
              </v-icon>
              {{ contentOpened ? 'COLLAPSE' : 'VIEW DETAILS' }}
            </div>
          </template>
        </v-expansion-panel-header>
      </v-col>
    </v-row>

    <v-expansion-panel-content class="mx-md-6 pt-4 parent">
      <v-row>
        <!-- left detail -->
        <v-col cols="12" md="3">
          <share-list-view-item-side :trial="trial" :trialsHeader="trialsHeader" />
        </v-col>
        <!-- right col: more details -->
        <v-col cols="12" md="9">
          <div v-if="hasDetails">
            <v-expansion-panels :value="detailsPanel" flat multiple class="child">
              <!-- description -->
              <list-view-item-description
                v-if="trial.details.detail_description"
                :description="trial.details.detail_description"
              />
              <!-- summary -->
              <list-view-item-summary
                v-if="trial.details.brief_summary"
                :summary="trial.details.brief_summary"
              />
              <!-- inclusion -->
              <list-view-item-inc-exc
                label="Inclusion"
                :inclusion_exclusion="trial.details.inclusion_exclusion"
              />
              <!-- exclusion -->
              <list-view-item-inc-exc
                label="Exclusion"
                :inclusion_exclusion="trial.details.inclusion_exclusion"
              />
              <!-- sites -->
              <list-view-item-sites
                v-if="trial.details.sites.length > 0"
                :sites="trial.details.sites"
              />
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import EyeHidden from './EyeHidden.vue';
import EyeHiddenBox from './EyeHiddenBox.vue';
import ListViewItemDescription from './ListViewItemDescription.vue';
import ListViewItemIncExc from './ListViewItemIncExc.vue';
import ShareListViewItemSide from './ShareListViewItemSide.vue';
import ListViewItemSites from './ListViewItemSites.vue';
import ListViewItemSummary from './ListViewItemSummary.vue';
import Match from './Match.vue';
import MatchExc from './MatchExc.vue';
import ScoreBar from './ScoreBar.vue';
import ScoreCircle from './ScoreCircle.vue';
import Star from './Star.vue';
import StarBox from './StarBox.vue';
import WhNoNumber from './svg/WhNoNumber.vue';

export default {
  name: 'ShareListViewItem',
  components: {
    EyeHidden,
    Match,
    MatchExc,
    Star,
    WhNoNumber,
    ScoreCircle,
    ShareListViewItemSide,
    ListViewItemDescription,
    ListViewItemSummary,
    ListViewItemIncExc,
    ListViewItemSites,
    ScoreBar,
    EyeHiddenBox,
    StarBox,
  },
  props: {
    trial: { required: true, type: Object,},
    trialsHeader: { type: Array, required: true}
  },
  data() {
    return {
      contentOpened: false,
      fetchingDetails: false,
      detailsPanel: [0, 1],
      //localTrialDetails: null,
      hasBeenOpened: false,
    };
  },
  methods: {
    fetchTrialDetailsArr(refreshContentOpened = true) {
      if (refreshContentOpened) {
        this.contentOpened = !this.contentOpened;
      }
    },
    mapNameToLabel(name) {
      return this.trialsHeader.find((header) => header.value == name).text;
    },
    formatAddress(site) {
      return `https://www.google.com/maps/place/${site.org_address_line_1},+${site.org_city},+${site.org_state_or_province}+${site.org_postal_code}`;
    },
  },
  computed: {
    geo() {
      return this.$store.getters['criteriaStore/getGeo'];
    },
    geoArr() {
      return this.$store.getters['criteriaStore/getGeoArr'];
    },
    trialDetails() {
      return this.$store.getters['detailsStore/getTrialDetails'](
        this.trial.nct_id
      );
    },
    distance() {
      return this.$store.state.trialsStore.distance;
    },
    color() {
      if (this.trial.score >= 67) {
        return '#EAF6F9';
      } else if (this.score >= 34) {
        return '#FCF0FD';
      } else {
        return '#f6c2aa';
      }
    },
    hasDetails() {
      return this.trial.details !== null && Object.keys(this.trial.details).length !== 0
    }
  },
  updated() {
    //console.log('item updated')
  },
};
</script>

<style></style>
