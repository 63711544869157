import { uniq, sortBy } from 'lodash'

function findKeyValueRecursively(input, keyOfTheValue, valueToFind, childName, item) {
  if (keyOfTheValue in input && input[keyOfTheValue] === valueToFind) {
    input.children.push({
      "name": childName,
      "level": item.levels,
      "general_count": item.general_count,
      "children": []
    })
    return true;
  }
  return false;
}

function appendChildren(item, node) {
  node.forEach(child => {
    findKeyValueRecursively(child, 'name', item.parent, item.child, item)
  })
}

function goDeep(obj, item, targetLevel) {
  obj.children.forEach(child => {
    if (targetLevel > 3) {
      goDeep(child, item, targetLevel - 1)
    } else {
      appendChildren(item, child.children)
    }
  })
}

function removeEmptyChildren(obj) {
  if (obj.children.length == 0) {
    delete obj.children
  } else {
    obj.children.forEach(child => {
      removeEmptyChildren(child)
    })
  }
}

export function buildJSONObj(data) {
  //root level
  let dataObj = {
    "name": data[0].child,
    "children": [],
    "general_count": data[0].general_count
  }
  
  data.forEach(item => {
    if (item.levels == 1) { 
      dataObj.children.push({
        "name": item.child, "level": item.levels, "general_count": item.general_count, "children": []
      })
    } else if (item.levels == 2) { 
      appendChildren(item, dataObj.children)
    } else {
      goDeep(dataObj, item, item.levels)
    }
  })

  removeEmptyChildren(dataObj)

  return dataObj
}

export function buildFlatTree(data) {
  let flatTreeList = []
  
  data.forEach(item => {
    flatTreeList.push(item.child);
  })

  return sortBy(uniq(flatTreeList, false))
}

export function getMatchedNodeArr(dataTree, targetNodeName) {
  let targetNode = [];
  let traceArr = [1];

  function getMatchNode(obj, targetName) {
    obj.children.forEach((child, index) => {
      traceArr[child.level] = index
      if (child.name == targetName) {
        targetNode.push(traceArr.slice(0, child.level + 1))
      } else if (child.children && child.children.length > 0) {
        getMatchNode(child, targetName)
      } 
    })
  }

  getMatchNode(dataTree, targetNodeName)
  return targetNode
}

