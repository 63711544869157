<template>
  <svg
    class="hw-no-number matching-level"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 45.71 45.71"
  >
    <defs>
      <clipPath id="clip-path" transform="translate(-656.55 -360.46)">
        <rect class="cls-1" x="657.7" y="361.22" width="44" height="44" />
      </clipPath>
    </defs>
    <g id="Group_2495" data-name="Group 2495">
      <g class="cls-2">
        <g id="Group_2491" data-name="Group 2491">
          <path
            id="Path_1057"
            data-name="Path 1057"
            class="cls-3"
            d="M679.3,383.21l.11-21.25h0a21.36,21.36,0,0,0-18.51,32l18.41-10.75Z"
            transform="translate(-656.55 -360.46)"
          />
          <path
            id="Path_1058"
            data-name="Path 1058"
            class="cls-4"
            d="M679.3,383.21l.11-21.25h0a21.36,21.36,0,0,0-18.51,32l18.41-10.75Z"
            transform="translate(-656.55 -360.46)"
          />
          <path
            id="Path_1059"
            data-name="Path 1059"
            class="cls-3"
            d="M679.41,362l-.11,21.25L697.92,394a21.36,21.36,0,0,0-18.51-32"
            transform="translate(-656.55 -360.46)"
          />
          <path
            id="Path_1060"
            data-name="Path 1060"
            class="cls-4"
            d="M679.41,362l-.11,21.25L697.92,394a21.36,21.36,0,0,0-18.51-32Z"
            transform="translate(-656.55 -360.46)"
          />
          <path
            id="Path_1061"
            data-name="Path 1061"
            class="cls-3"
            d="M679.3,383.45v-.23h0L660.88,394a21.35,21.35,0,0,0,37,0L679.3,383.21Z"
            transform="translate(-656.55 -360.46)"
          />
          <path
            id="Path_1062"
            data-name="Path 1062"
            class="cls-4"
            d="M679.3,383.45v-.23h0L660.88,394a21.35,21.35,0,0,0,37,0L679.3,383.21Z"
            transform="translate(-656.55 -360.46)"
          />
          <path
            id="Path_1063"
            data-name="Path 1063"
            class="cls-5"
            d="M687.6,383.32a8.2,8.2,0,1,1-8.2-8.2h0a8.2,8.2,0,0,1,8.2,8.2"
            transform="translate(-656.55 -360.46)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'WhNoNumber',
};
</script>

<style lang="scss">
.hw-no-number {
  .cls-1,
  .cls-4 {
    fill: none;
  }
  .cls-2 {
    clip-path: url(#clip-path);
  }
  .cls-3 {
    fill: #02b4c7;
  }
  .cls-4 {
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 3px;
  }
  .cls-5 {
    fill: #fff;
  }
}

</style>
