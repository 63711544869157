<template>
  <div class="text-center">
    <v-dialog content-class="biomarkers-dialog-wrapper" :value="biomarkersDialog" persistent width="95vw">
      <v-card>
        <v-card-title>
          Select biomarkers

          <div class="btn-close">
            <v-icon @click="$emit('biomarkersDialogClose')">mdi-close-thick</v-icon>
          </div>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="5">
              <div class="form-group-wrapper">
                <!-- EGFR -->
                <div class="form-group">
                  <btn-group-round :items="['Positive', 'Negative', 'Unsure']" label="EGFR" v-model="biomarkers.egfr_multiple" />
                </div>
                <!-- ALK -->
                <div class="form-group">
                  <btn-group-round :items="['Positive', 'Negative', 'Unsure']" label="ALK" v-model="biomarkers.alk_multiple" />
                </div>
                <!-- ROS1 -->
                <div class="form-group">
                  <btn-group-round :items="['Positive', 'Negative', 'Unsure']" label="ROS1" v-model="biomarkers.ros1_multiple" />
                </div>
                <!-- NRAS -->
                <div class="form-group">
                  <btn-group-round :items="['Positive', 'Negative', 'Unsure']" label="NRAS" v-model="biomarkers.nras_multiple" />
                </div>
                <!-- KRAS -->
                <div class="form-group">
                  <btn-group-round :items="['Positive', 'Negative', 'Unsure']" label="KRAS" v-model="biomarkers.kras_multiple" />
                </div>
                <!-- HRAS -->
                <div class="form-group">
                  <btn-group-round :items="['Positive', 'Negative', 'Unsure']" label="HRAS" v-model="biomarkers.hras_multiple" />
                </div>
                <!-- HER2/Neu -->
                <div class="form-group">
                  <btn-group-round :items="['Positive', 'Negative', 'Unsure']" label="HER2/Neu" v-model="biomarkers.her2Neu_multiple" />
                </div>
              </div>
            </v-col>
            <v-col col="12" md="7">
              <v-autocomplete id="bio-search" :items="biomarkerData" label="Biomarker Search" 
                multiple clearable chips small-chips return-object :loading="loadingBiomarkers"
                v-model="biomarkers.biomarkersSelected" item-text="biomarker" item-value="code">
                 <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close small
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.biomarker }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="outlined" @click="$emit('biomarkersDialogClose')">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
//import biomarkerData from '@/mockData/biomarker.json'
import BtnGroupRound from '@/components/BtnGroupRound.vue';
import { defaultBiomarkers } from '@/store/initState'

export default {
  name: 'BiomarkersDialog',
  components: {
    BtnGroupRound
  },
  props: {
    biomarkersDialog: {
      default: false
    },
    value: Array
  },
  data() {
    return {
      //biomarkerData: [],
      biomarkers: {},
      loadingBiomarkers: false
    };
  },
  created() {
    this.biomarkers = {...this.$store.getters['criteriaStore/getBiomarkers']};
    //this.fetchBiomarkersData();
  },
  methods: {
    remove (item) {
      const index = this.biomarkers.biomarkersSelected.indexOf(item)
      if (index >= 0) {
        this.biomarkers.biomarkersSelected.splice(index, 1)
      } else {
        this.biomarkers.biomarkersSelected.splice(index, 0)
      }
    },
    reset() {
      this.biomarkers = cloneDeep(defaultBiomarkers)
    },
    setDemo(data) {
      this.biomarkers = {
        ...this.biomarkers,
        ...data
      }
    }
  },
  computed: {
    biomarkerData() {
      return this.$store.getters['criteriaStore/getBiomarkersData'];
    }
  },
  watch: {
    biomarkers: {
      deep: true,
      handler() {
        this.$store.dispatch('criteriaStore/updateBiomarkers', this.biomarkers)
      }
    }
  }
};
</script>

<style lang="scss">

</style>
