<template>
  <div class="text-center">
    <v-dialog content-class="disease-dialog-wrapper" :value="diseaseDialog" persistent width="95vw">
      <v-card>
        <v-card-title>
          Select Disease

          <div class="btn-close">
            <v-icon @click="$emit('diseaseDialogClose')">mdi-close-thick</v-icon>
          </div>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete id="primary-cancer-search" :items="primaryCancerTypeData" label="Primary Cancer Type/Condition" 
                clearable hide-details :loading="loadingPrimary"
                v-model="primaryCancerSearch" item-text="display_name" item-value="display_name"
                @change="onMaintypeChange">
              </v-autocomplete>
            </v-col>
            <!-- subtypes -->
            <v-col cols="12">
              <v-autocomplete id="subtype-search" :items="subtypeData" label="Subtype" 
                multiple clearable hide-details chips small-chips return-object :loading="loadingSubtype"
                v-model="subtypeSearch" item-text="display_name" item-value="display_name"
                @change="onSubtypeChange">
                  <template v-slot:selection="data">
                    <v-chip v-bind="data.attrs" :input-value="data.selected" close small
                      @click="data.select"
                      @click:close="updateSubtype(data.item)">
                      {{ data.item.display_name }}
                    </v-chip>
                  </template>
              </v-autocomplete>
            </v-col>
            <!-- stage -->
            <v-col cols="12">
              <v-autocomplete id="stage-search" :items="stageData" label="Stage" 
                multiple clearable hide-details chips small-chips return-object :loading="loadingStage"
                v-model="stageSearch" item-text="display_name" item-value="code">
                  <template v-slot:selection="data">
                    <v-chip v-bind="data.attrs" :input-value="data.selected" close small
                      @click="data.select"
                      @click:close="updateStage(data.item)">
                      {{ data.item.display_name }}
                    </v-chip>
                  </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p>Disease Selected: 
                <transition-group name="fade">
                  <v-chip class="rounded my-1" v-for="(item, index) in diseaseSearch" :key="item" 
                    close rounded small @click:close="removeDisease(index)" >
                    {{item}}
                  </v-chip>
                </transition-group>
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn data-test="add-disease" depressed color="primary" @click="updateDiseaseSearch" class="mr-2">Add Disease</v-btn>
          <v-btn data-test="disease-dialog-close" depressed color="outlined" @click="$emit('diseaseDialogClose')">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import primaryCancerTypeData from '@/mockData/primaryCancerType.json'
//import subtypeData from '@/mockData/subtype.json'
//import stageData from '@/mockData/stage.json'
import fetchService from '@/services/fetchService'

export default {
  name: 'DiseaseDialog',
  props: {
    diseaseDialog: {
      default: false
    },
  },
  data() {
    return {
      //primaryCancerTypeData : [],
      subtypeData: [],
      stageData: [],
      primaryCancerSearch: '',
      subtypeSearch: [],
      stageSearch: [],
      loadingPrimary: false,
      loadingSubtype: false,
      loadingStage: false
    };
  },
  created() {
    //this.fetchPrimaryCancer();
    //this.fetchSubtypeForMaintype();
    //this.fetchStageForMaintype();
  },
  methods: {
    fetchSubtypeForMaintype() {
      this.loadingSubtype = true;
      this.subtypeData = [];
      fetchService.getDataFiles('subtype_for_maintype', {
          display_name: this.primaryCancerSearch
        })
        .then(res => {
          this.loadingSubtype = false;
          this.subtypeData = res
        })
        .catch((e) => {
          console.log('onMainTypeChange', e.message)
        })
    },
    fetchStageForMaintype() {
      this.loadingStage = true;
      this.stageData = [];
      fetchService.postDataFiles('stage_for_types', {
          display_name: [this.primaryCancerSearch]
        })
        .then(res => {
          this.loadingStage = false;
          this.stageData = res
        })
    },
    fetchStageForSubtype() {
      this.loadingStage = true;
      this.stageData = [];
      let param = this.subtypeSearch.map(subtype => subtype.display_name)
      fetchService.postDataFiles('stage_for_types', {
          display_name: param
        })
        .then(res => {
          this.loadingStage = false;
          this.stageData = res
        })
    },
    onMaintypeChange() {
      this.subtypeSearch = []; this.subtypeData = [];
      this.stageSearch = []; this.stageData = []
      if (this.primaryCancerSearch !== undefined && this.primaryCancerSearch !== null) {
        this.fetchSubtypeForMaintype();
        this.fetchStageForMaintype();
      }
      
    },
    onSubtypeChange() {
      this.stageSearch = [];
      if (this.subtypeSearch.length !== 0) {
        this.fetchStageForSubtype();
      }
    },
    updateSubtype (item) {
      const index = this.subtypeSearch.indexOf(item)
      this.stageSearch = [];
      if (index >= 0) this.subtypeSearch.splice(index, 1)
      if (this.subtypeSearch.length == 0) {
        this.fetchStageForMaintype();
      } else {
        this.fetchStageForSubtype();
      }
    },
    updateStage (item) {
      const index = this.stageSearch.indexOf(item)
      if (index >= 0) this.stageSearch.splice(index, 1)
    },
    updateDiseaseSearch() {
      if (this.stageSearch.length > 0) {
        this.$emit('updateDiseaseSelected', this.stageSearch.map(search => search.display_name)) 
      } else if (this.subtypeSearch.length > 0) {
        this.$emit('updateDiseaseSelected', this.subtypeSearch.map(search => search.display_name))
      } else if (this.primaryCancerSearch?.length > 0) {
        this.$emit('updateDiseaseSelected', [this.primaryCancerSearch])
      }
    },
    removeDisease(index) {
      this.$store.dispatch('diseaseStore/removeDisease', {
        type: 'diseaseSelected',
        diseaseIndex: index
      })
    }
  },
  computed: {
    diseaseSearch() {
      return this.$store.getters['diseaseStore/getDisease'].diseaseSelected
    },
    primaryCancerTypeData() {
      return this.$store.getters['criteriaStore/getPrimaryCancerData']
    }
  }
};
</script>

<style lang="scss">

</style>
