<template>
  <div class="form-group">
    <div class="group-label">{{ label }}
    <!-- <div class="criteria-colum-title">{{ label }} -->
      <v-tooltip max-width="300" right v-if="hasTooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey darken-1" size="18" dark v-bind="attrs" v-on="on" class="btn-group-tooltip-icon">
            mdi-help-circle
          </v-icon>
        </template>
        <span>{{tooltip}}</span>
      </v-tooltip>
    </div>
    <v-btn-toggle :value="value" mandatory @change="updateChange" rounded class="elevation-4 ml-1">
      <v-btn v-for="item in items" :key="item" depressed color="outlined">{{
        item
      }}</v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: 'BtnGroupRound',
  props: {
    label: { type: String, required: true, },
    items: { type: Array, required: true, },
    value: { type: Number, default: 2, },
    hasTooltip: {type: Boolean},
    tooltip: {type: String}
  },
  methods: {
    updateChange(e) {
      this.$emit('input', e);
    },
  },
};
</script>

<style>
.btn-group-tooltip-icon {
  transform: translateY(-3px);
}
</style>
