<template>
  <v-dialog v-model="dialog" width="99%" persistent content-class="tight">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="isSearching" text color="primary" class="short" v-bind="attrs" v-on="on"> 
        <v-icon left style="font-size: 24px">mdi-clock-time-ten-outline </v-icon> VIEW RECENT SEARCHES 
      </v-btn>
    </template>

    <v-card class="recent-search-card">
      <v-card-title>
        <div>
          <v-icon left style="font-size: 32px; transform: translateY(-3px)">mdi-clock-time-ten-outline </v-icon> RECENT SEARCHES 
        </div>
        <v-spacer></v-spacer>
        <div class="btn-close">
          <v-icon aria-label="close dialog" role="img" aria-hidden="false" 
            @click="dialog = false">mdi-close-thick</v-icon>
        </div>
      </v-card-title>

      <v-card-text class="ml-md-n2 mr-0 pr-2">
        <recent-search />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Auth } from 'aws-amplify';
import RecentSearch from '../RecentSearch.vue';

export default {
  components: { RecentSearch },
  name: 'LoadSearchDialog',
  props: {
    isSearching: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    hideDialog() {
      this.dialog = false;
    }
  }
};
</script>

<style></style>
