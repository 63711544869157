import { isEqual, cloneDeep } from 'lodash';
import { defaultBiomarkers, defaultDisease, defaultPatientInfo, defaultTestResults } from '@/store/initState'

export default {
  state: {
    patientInfo: null,
    disease: null,
    biomarkers: null,
    testResults: null,
  },
  getters: {
    getPatientInfo: (state) => state.patientInfo,
    getDisease: state => state.disease,
    getBiomarkers: (state) => state.biomarkers,    
    getBiomarkersMultipleSelected: (state, _, rootState) => {
      let biomarkersMultipleArr = [];
      let multipleArr = [];
      Object.keys(rootState.criteriaStore._defaultBiomarkers).forEach(key => {
        if (key.includes('multiple')) {
          multipleArr.push(key)
        }
      })

      //let multipleArr = [ 'egfr_multiple', 'alk_multiple', 'ros1_multiple', 'nras_multiple', 'kras_multiple', 'hras_multiple', 'her2Neu_multiple' ];

      multipleArr.forEach((bio) => {
        if (state.biomarkers[bio] == 0) {
          rootState.criteriaStore._biomarkersMultipleInfo[bio].positive.forEach((item) => {
            biomarkersMultipleArr.push(item.name);
          });
        } else if (state.biomarkers[bio] == 1) {
          rootState.criteriaStore._biomarkersMultipleInfo[bio].negative.forEach((item) => {
            biomarkersMultipleArr.push(item.name);
          });
        }
      });
      return biomarkersMultipleArr;
    },
    getTestResults: (state) => state.testResults,

    isTestResultDefault: (state) => {
      return isEqual(state.testResults, defaultTestResults);
    },
    isPatientInfoDefault: (state) => {
      /* let {geoArr: stateGeoArr, ...statePI} = state.patientInfo;
      let {geoArr : defaultGeoArr, ...defaultPI} = defaultPatientInfo;
      return isEqual(statePI, defaultPI) && stateGeoArr.length == 0; */
      let clonePatientInfo = cloneDeep(state.patientInfo);
      let cloneDefaultPatientInfo = cloneDeep(defaultPatientInfo);
      delete clonePatientInfo.brainCNS_multiple;
      delete cloneDefaultPatientInfo.brainCNS_multiple;
      return isEqual(clonePatientInfo, cloneDefaultPatientInfo);
    },
    isBiomarkersDefault: (state) => {
      return isEqual(state.biomarkers, defaultBiomarkers);
    },
    isDiseaseDefault: state => {
      return isEqual(state.disease, defaultDisease);
    },
    getShareSummary: (state, getters) => {
      return {
        patientInfo: getters.getPatientInfo,
        disease: getters.getDisease,
        biomarkers: getters.getBiomarkers,
        biomarkersMultipleSelected: getters.getBiomarkersMultipleSelected,
        testResults: getters.getTestResults,
        isPatientInfoDefault: getters.isPatientInfoDefault,
        isTestResultDefault: getters.isTestResultDefault,
        isDiseaseDefault: getters.isDiseaseDefault,
        isBiomarkersDefault: getters.isBiomarkersDefault
      }
    }
  },
  mutations: {
    UPDATE_SUMMARY(state, payload) {
      state.patientInfo = cloneDeep(payload.patientInfo);
      state.disease = cloneDeep(payload.disease);
      state.biomarkers = cloneDeep(payload.biomarkers);
      state.testResults = cloneDeep(payload.testResults);
    }
  }
};
