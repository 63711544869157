<template>
  <div class="form-group">
    <!-- <div class="group-label">Disease</div> -->
    <div class="criteria-colum-title">Select Disease(s) <span class="disease-required">*</span></div>
    
    <div>
      <!-- <v-btn data-test="cancer" depressed color="outlined" class="mr-4 mb-3"
       @click="diseaseDialog = true">Cancer</v-btn>
      <v-btn data-test="gyn" depressed color="outlined" class="mr-4 mb-3"
        @click="gynDialog = true">
          Gyn
      </v-btn>
      <v-btn data-test="lung" depressed color="outlined" class="mr-4 mb-3"
        @click="lungDialog = true">
        Lung
      </v-btn>
      <v-btn data-test="solid" depressed color="outlined" class="mb-3" 
        @click="solidDialog = true">
          Solid
      </v-btn> -->
      <!-- <div v-show="searchType == 'advanced'">
        <div class="d-none d-lg-block elevation-2 pa-4 ">
          <div class="criteria-colum-title small">Select disease from a dynamic decision tree</div>
          <div class="d-flex align-center mt-4">
            <v-autocomplete id="disease-tree" :items="allDisease" label="Disease"
              item-text="display_name" item-value="nci_thesaurus_concept_id" return-object
              :loading="loadingAllDisease"
              v-model="allDiseaseSelected"
            ></v-autocomplete>
            <v-btn color="primary" class="ml-3 mt-n2" @click="getSelectedDiseaseTree">Go</v-btn>
          </div>
        </div>

        <div class="text-center my-4 font-weight-bold d-none d-lg-block">OR</div>
      </div> -->

      <!-- 3 disease dropdown -->
      <disease-dialog-home ref="diseaseDialog" @updateDiseaseSelected="updateDiseaseSelected" />

      <!-- disease_modifiers -->
      <!-- <v-row class="mb-8" v-show="searchType == 'advanced'">
        <v-col cols="12" md="4" lg="3">
          <v-checkbox v-model="diseaseModifiers" label="Recurrent" value="Recurrent" hide-details
            @change="updateDiseaseModifier"></v-checkbox>
        </v-col>
        <v-col cols="12" md="4" lg="3">
          <v-checkbox v-model="diseaseModifiers" label="Refractory" value="Refractory" hide-details
            @change="updateDiseaseModifier"></v-checkbox>
        </v-col>
      </v-row> -->
      <!-- disease modifier -->
      <v-row v-show="searchType == 'advanced'">
        <v-col class="mt-8">
          <btn-group-round data-test="recurrent_modifier"
            label="Recurrent" :items="['Yes', 'No', 'Unsure']" v-model="disease.recurrent_multiple"
            :hasTooltip="true" :tooltip="recurrentTooltip" />
          <btn-group-round data-test="refractory_modifier"
            label="Refractory" :items="['Yes', 'No', 'Unsure']" v-model="disease.refractory_multiple"
            :hasTooltip="true" :tooltip="refractoryTooltip" />
        </v-col>
      </v-row>

      <div v-if="allDiseaseLength == 0" class="no-data mt-4">No disease selected. Please select one or more diseases.</div>
      <v-simple-table v-else dense class="mt-6 mb-8 selected-table">
        <template v-slot:default>
          <tbody>
            <tr v-for="(item, index) in diseaseSearch" :key="item">
              <td class="pre-btn-remove">{{ item }}</td>
              <td width="20" class="pr-2 btn-remove" @click="removeDiseaseSelected(index)">
                <v-icon aria-label="remove disease" role="button" aria-hidden="false">mdi-close-circle</v-icon>
              </td>
            </tr>
            <tr v-for="(item, index) in gynSearch" :key="item">
              <td class="pre-btn-remove">{{ item }}</td>
              <td width="20" class="pr-2 btn-remove" @click="removeGyn(index)">
                <v-icon small aria-label="remove disease" role="button" aria-hidden="false">mdi-close-circle</v-icon>
                </td>
            </tr>
            <tr v-for="(item, index) in lungSearch" :key="item">
              <td class="pre-btn-remove">{{ item }}</td>
              <td width="20" class="pr-2 btn-remove" @click="removeLung(index)">
                <v-icon small aria-label="remove disease" role="button" aria-hidden="false">mdi-close-circle</v-icon>
              </td>
            </tr>
            <tr v-for="(item, index) in solidSearch" :key="item">
              <td class="pre-btn-remove">{{ item }}</td>
              <td width="20" class="pr-2 btn-remove" @click="removeSolid(index)">
                <v-icon small aria-label="remove disease" role="button" aria-hidden="false">mdi-close-circle</v-icon>
              </td>
            </tr>
            <tr v-for="(item, index) in allDiseaseSearch" :key="item">
              <td class="pre-btn-remove">{{ item }}</td>
              <td width="20" class="pr-2 btn-remove" @click="removeAllDisease(index)">
                <v-icon aria-label="remove disease" role="button" aria-hidden="false">mdi-close-circle</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <!-- <disease-dialog :diseaseDialog="diseaseDialog" @diseaseDialogClose="closeDiseaseDialog"
      @updateDiseaseSelected="updateDiseaseSelected" />
    <tree-dialog dialogTitle="Select GYN Disease" :diseaseType="'gyn'" :diseaseDialog="gynDialog" @diseaseDialogClose="closeGynDialog" />
    <tree-dialog dialogTitle="Select Lung Disease" :diseaseType="'lung'" :diseaseDialog="lungDialog" @diseaseDialogClose="closeLungDialog" />
    <tree-dialog dialogTitle="Select Solid Neoplasm Disease" :diseaseType="'solid'" :diseaseDialog="solidDialog" @diseaseDialogClose="closeSolidDialog" /> -->
    <tree-general-dialog :dialogTitle="allDiseaseDialogTitle" :diseaseType="'allDisease'" 
      v-if="allDiseaseDialog"
      :treeData="allDiseaseData" :treeFlatData="allDiseaseFlatData"
      :diseaseDialog="allDiseaseDialog" @diseaseDialogClose="closeAllDiseaseDialog" />
  </div>
</template>

<script>
import DiseaseDialog from '@/components/dialog/DiseaseDialog.vue';
/* import GynDialog from '../../ref/dialog/GynDialog.vue';
import LungDialog from '../../ref/dialog/LungDialog.vue';
import SolidDialog from '../../ref/dialog/SolidDialog.vue';
import TreeDialog from '../../ref/dialog/TreeDialog.vue';
import TreeGeneralDialog from '../../ref/dialog/TreeGeneralDialog.vue'; */
import DiseaseDialogHome from './dialog/DiseaseDialogHome.vue';
import BtnGroupRound from '../components/BtnGroupRound.vue';

export default {
  name: 'Disease',
  //components: { DiseaseDialog, GynDialog, LungDialog, SolidDialog, TreeDialog, TreeGeneralDialog, DiseaseDialogHome, },
  components: { DiseaseDialog, DiseaseDialogHome, BtnGroupRound},
  data() {
    return {
      //dialog
      diseaseDialog: false,
      gynDialog: false,
      lungDialog: false,
      solidDialog: false,
      allDiseaseDialog: false,
      diseaseTreeType: 'lung',
      allDiseaseSelected: null,
      allDiseaseData: {},
      allDiseaseFlatData: [],
      allDiseaseDialogTitle: '',
      loadingAllDisease: false,
      diseaseModifiers: [],
      recurrentTooltip: `A cancer has come back.`,
      refractoryTooltip: `A cancer has not responded, or stopped responding to treatment.`,
    }
  },
  created() {
    this.diseaseModifiers = [...this.$store.getters['diseaseStore/getDisease'].diseaseModifiers];
  },
  methods: {
    closeDiseaseDialog() {
      this.diseaseDialog = false;
    },
    closeGynDialog() {
      this.gynDialog = false;
    },
    closeLungDialog() {
      this.lungDialog = false;
    },
    closeSolidDialog() {
      this.solidDialog = false;
    },
    closeAllDiseaseDialog() {
      this.allDiseaseDialog = false;
    },
    updateDiseaseSelected(e) {
      this.$store.dispatch('diseaseStore/addDisease', {
        type: 'diseaseSelected',
        diseaseName: e
      })
    },
    removeDiseaseSelected(index) {
      this.$store.dispatch('diseaseStore/removeDisease', {
        type: 'diseaseSelected',
        diseaseIndex: index
      })
    },
    removeGyn(index) {
      this.$store.dispatch('diseaseStore/removeDisease', {
        type: 'gyn',
        diseaseIndex: index
      })
    },
    removeLung(index) {
      this.$store.dispatch('diseaseStore/removeDisease', {
        type: 'lung',
        diseaseIndex: index
      })
    },
    removeSolid(index) {
      this.$store.dispatch('diseaseStore/removeDisease', {
        type: 'solid',
        diseaseIndex: index
      })
    },
    removeAllDisease(index) {
      this.$store.dispatch('diseaseStore/removeDisease', {
        type: 'allDisease',
        diseaseIndex: index
      })
    },
    getSelectedDiseaseTree() {
      if (this.allDiseaseSelected && !this.loadingAllDisease) {
        //window.scroll({top: 0});

        this.loadingAllDisease = true;
        this.diseaseTreeType = 'all';
        this.allDiseaseDialogTitle = this.allDiseaseSelected.display_name
        const diseaseCode = this.allDiseaseSelected.cid;

        this.$store.dispatch('diseaseStore/fetchDiseaseTree', {disease: 'allDisease', ccode: diseaseCode})
          .then(data => {
            this.allDiseaseData = this.$store.getters['diseaseStore/getAllDiseaseData'];
            this.allDiseaseFlatData = this.$store.getters['diseaseStore/getAllDiseaseFlatData']
            setTimeout(() => {
              this.allDiseaseDialog = true;
              this.loadingAllDisease = false;
            }, 1000)
          })
      }
    },
    /* updateDiseaseModifier(e) {
      this.$store.commit('diseaseStore/UPDATE_DISEASE_MODIFIERS', this.diseaseModifiers);
    }, */
    setDemo(demoData) {
      this.diseaseModifiers = demoData
    },
    reset() {
      this.allDiseaseSelected = null;
      this.diseaseModifiers = [];
      this.$refs.diseaseDialog.reset();
    }
  },
  computed: {
    disease() {
      return this.$store.getters['diseaseStore/getDisease']
    },
    diseaseSearch() {
      return this.disease.diseaseSelected
    },
    gynSearch() {
      return this.disease.gyn
    },
    lungSearch() {
      return this.disease.lung
    },
    solidSearch() {
      return this.disease.solid
    },
    allDiseaseSearch() {
      return this.disease.allDisease
    },
    allDisease() {
      return this.$store.getters['criteriaStore/getAllDiseaseData']
    },
    allDiseaseLength() {
      return this.diseaseSearch.length + this.gynSearch.length + this.lungSearch.length + this.solidSearch.length + this.allDiseaseSearch.length
    },
    searchType() {
      return this.$store.getters['authStore/getSearchType']
    }
  },
}
</script>

<style>

</style>