<template>
  <div>
    <div style="width: 40px">
      <v-dialog content-class="edit-dialog" persistent v-model="dialog" width="1000" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="dialog = true" color="primary" class="mb-4" v-bind="attrs" v-on="on">
            Add
          </v-btn>
        </template>

        <v-card class="edit-card">
          <v-icon role="img" aria-hidden="true" class="white--text title-icon">
            mdi-pencil
          </v-icon>

          <div class="btn-close" v-show="!adding">
            <v-icon aria-label="close dialog" role="img" aria-hidden="false" @click="dialog = false" >
              mdi-close-thick
            </v-icon >
          </div>

          <v-card-text class="ml-n2 mr-0 pr-2">
            <div class="form-progress-wrapper">
              <v-progress-linear v-if="adding" indeterminate color="primary" class="mb-8"></v-progress-linear>
            </div>

            <p class="title text-h4 mb-3">Add Criteria</p>
            <v-form v-model="formValid" ref="addTrialCriteriaTypeForm">
              <v-row>
                <v-col cols="6">
                  <v-text-field label="ID*" v-model="trialCriteria.nct_id"
                    :rules="[rules.required('NCT ID')]"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select v-model="trialCriteria.criteria_type_id" :items="criteriaTypes" label="Criteria Type*"
                    item-text="criteria_type_title" item-value="criteria_type_id"
                    :rules="[rules.required('Criteria type')]"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea filled name="Original Text" label="Original Text" hide-details
                    v-model="trialCriteria.trial_criteria_orig_text" rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea filled name="Refined Text" label="Refined Text" hide-details
                    v-model="trialCriteria.trial_criteria_refined_text" rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea filled name="Expression" label="Expression" hide-details
                    v-model="trialCriteria.trial_criteria_expression" rows="8"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" outlined @click="testExpression">TEST EXPRESSION</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveCriteria" :disabled="!formValid">SAVE AND CLOSE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-snackbar :timeout="timeout" v-model="testExpressionSnackbar" :color="snackbarColor">
      {{testMsg}}

      <template v-slot:action="{ attrs }">
        <v-btn @click="testExpressionSnackbar = false" color="white" text v-bind="attrs" class="pr-0 mr-n4">
          <v-icon aria-label="close" role="img" aria-hidden="false">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import fetchService from '@/services/fetchService';

export default {
  name: 'TrialCriteriaAddDialog',
  props: {
    currentNctId: {type: String},
    currentSelectedTypeId: {type: Number},
  },
  data() {
    return {
      formValid: false,
      dialog: false,
      adding: false,
      selectedType: null,
      trialCriteria: {
        nct_id: '',
        criteria_type_id: '', 
        trial_criteria_orig_text: '', 
        trial_criteria_refined_text: '' , 
        trial_criteria_expression: ''
      },
      rules: {
        required(propertyType) {
          return v => {
            return v && v.toString().length > 0 || `${propertyType} is required`
          }
        },
      },
      testMsg: '',
      timeout: 10000,
      testExpressionSnackbar: false,
      snackbarColor: ''
    }
  },
  created() {
    if (this.currentNctId !== '') {
      this.trialCriteria.nct_id = this.currentNctId;
    }
    if (this.currentSelectedTypeId !== undefined) {
      this.trialCriteria.criteria_type_id = this.currentSelectedTypeId;
    }
  },
  methods: {
    saveCriteria() {
      /* payloadTemplate: {
        "record": {
          "nct_id": "NCT01629498",
          "criteria_type_id": 32, 
          "trial_criteria_orig_text": "orig text", 
          "trial_criteria_refined_text": "refined text" , 
          "trial_criteria_expression": "expression"
        }
      } */
      this.adding = true;
      this.$store.dispatch('typesStore/addTrialCriteriaType', {record: this.trialCriteria})
        .then(res => {
          this.adding = false;
          this.dialog = false;
          this.$refs.addTrialCriteriaTypeForm.reset();
        })
    },
    testExpression() {
      this.adding = true;
      fetchService.postDataFiles('post_eval_expression', {
        expression: this.trialCriteria.trial_criteria_expression
      })
        .then((response) => {
          this.adding = false;
          if (response.status == 'OK') {
            this.testExpressionSnackbar = true;
            this.snackbarColor = 'success';
            this.testMsg = 'Criteria Test Passed';
          } else {
            this.testExpressionSnackbar = true;
            this.snackbarColor = 'error';
            this.testMsg = `Criteria Test fail: ${response.message}`;
          }
        })
    }
  },
  computed: {
    criteriaTypes() {
      return this.$store.getters['typesStore/getCriteriaTypes']
    },
  },
  watch: {
    currentNctId() {
      this.trialCriteria.nct_id = this.currentNctId;
    },
    currentSelectedTypeId() {
      this.trialCriteria.criteria_type_id = this.currentSelectedTypeId;
    }
  }
}
</script>

<style>

</style>