<template>
  <div>
    <div class="col-title b-teal">
      <img alt="patient info" height="20" src="@/assets/patient_info.svg" />
      <span>Patient Information</span>
    </div>
    <!-- sex -->
    <btn-group-round data-test="sex" :items="['Male', 'Female', 'Unsure']" label="Sex"
      v-model="patientInfo.sex_multiple" />

    <!-- age -->
    <div class="form-group">
      <v-form v-model="formValid" ref="AgeZipcodeForm">
        <v-row>
          <v-col cols="6" class="pt-1 pb-2 pb-md-0">
            <v-text-field data-test="age" id="age" label="Age" type="text" class="d-inline-block"
              min="1" max="120" step="1" pattern="[0-9]+" :rules="[validAge]"
              v-model="patientInfo.age">
            </v-text-field>
          </v-col>
          <v-col cols="6" class="pl-0 pt-1 pb-2 pb-md-0">
            <v-select data-test="age-unit" :items="['Months', 'Years']" label="" class="d-inline-block"
              v-model="patientInfo.ageUnit"></v-select>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col class="py-0">
            <div class="group-label">Zip Code of Interest <span style="font-weight: normal;">(Enter up to 3 US Zip Codes)</span></div>
          </v-col>
        </v-row>
        <zip-codes ref="zipcodes" @updateZipcodesValid="updateZipcodesValid"/> -->
      </v-form>
    </div>

    <!-- BMI -->
    <div class="form-group">
      <v-row class="form-group">
        <v-col class="pt-0 pb-3">
          <div class="group-label">Enter Height and Weight to Calculate BMI</div>
        </v-col>
      </v-row>
      <v-row class="form-group">
        <v-col cols="4" class="pt-1 pb-2 pb-md-0">
          <v-text-field v-model="patientInfo.height" label="Height" value="65" suffix="in" class="pt-0 mt-0"
            @input="calculateBMI"></v-text-field>
        </v-col>
        <v-col cols="5" class="pt-1 pb-2 pb-md-0">
          <v-text-field v-model="patientInfo.weight" label="Weight" value="150" suffix="lbs" class="pt-0 mt-0"
            @input="calculateBMI"></v-text-field>
        </v-col>
        <v-col cols="3" class="pt-1 pb-2 pb-md-0">
          <v-text-field label="BMI" v-model="patientInfo.BMI" :disabled="disableBMI" class="pt-0 mt-0"></v-text-field>
        </v-col>
      </v-row>
    </div>

    <!-- Performance Status -->
    <div class="form-group pt-1">
      <v-autocomplete id="performance-status" :items="performanceArr" label="Performance Status"
        item-text="name" item-value="ccode" return-object
        v-model="patientInfo.performance"
      >
      </v-autocomplete>
    </div>

    <div>
      <!-- able to swallow -->
      <btn-group-round  v-show="searchType == 'advanced'" data-test="sex" :items="['Yes', 'No', 'Unsure']" label="Able to Swallow Pills" v-model="patientInfo.ableToSwallow_multiple" />
      <!-- <btn-group-round  v-show="searchType == 'advanced'" data-test="sex" :items="['Yes', 'No', 'Unsure']" label="Able to Swallow Pills" v-model="patientInfo.ableToSwallow_multiple"
        :hasTooltip="true" tooltip='If "No" please answer question on GI system below.' />
      <btn-group-round  v-show="searchType == 'advanced'" data-test="sex" :items="['Yes', 'No', 'Unsure']" label="Able to Swallow Pills" v-model="patientInfo.ableToSwallow_multiple"
        :hasTooltip="true" tooltip='If "No" please answer question on GI system below.' />
      <btn-group-round  v-show="searchType == 'advanced'" data-test="sex" :items="['Yes', 'No', 'Unsure']" label="Able to Swallow Pills" v-model="patientInfo.ableToSwallow_multiple"
        :hasTooltip="true" tooltip='Please select "No" if you have any issues with absorption in your GI system (e.g., malabsorption syndrome, complications after stomach surgery).' /> -->

      <!-- advanced search -->
      <!-- <div class="elevation-2 pa-4 mb-6"> -->
        <!-- Prior Chemo or Immuno -->
        <btn-group-round data-test="chemo" :items="['Yes', 'No', 'Unsure']" label="Have you had chemotherapy or immunotherapy?" v-model="patientInfo.chemo_multiple" />

        <!-- Prior Therapy -->
        <div v-show="searchType == 'advanced'" class="form-group pt-1 mb-0 chip-multilines no-label-autocomplete">
          <div class="group-label">Search and add specific treatment medications here
            <v-tooltip right max-width="300" close-delay="2000" content-class="tooltip-link">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey darken-1" size="18" dark v-bind="attrs" v-on="on" class="btn-group-tooltip-icon">
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Example: chemotherapy drug 'cytarabine' or an inhibitor like 'midostaurin' can be typed &amp; selected here. This field uses the NCI's thesaurus
                (<strong><a href="https://evsexplore.nci.nih.gov/evsexplore/welcome" target="_blank"
                  style="color: #FFFFFF; text-decoration: underline !important; text-decoration-color: #FFFFFF;">NCIt</a></strong>).</span>
            </v-tooltip>
          </div>
          <v-autocomplete data-test="therapy-search" id="therapy-search" :items="priorTherapyData" label=""
            single-line multiple clearable chips small-chips return-object
            v-model="patientInfo.therapy" item-text="pref_name" item-value="code">
            <template v-slot:selection="data">
              <v-chip :input-value="data.selected" v-bind="data.attrs" close small
                @click:close="updateTherapy(data.item)" @click="data.select">
                {{ data.item.pref_name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </div>

        <!-- Prior Radiotherapy -->
        <btn-group-round data-test="radiotherapy" :items="['Yes', 'No', 'Unsure']" label="Have you had radiotherapy for this cancer?" v-model="patientInfo.radiotherapy_multiple"
          :hasTooltip="true" :tooltip="radiotherapyTooltip" />

        <!-- Prior Surgery -->
        <btn-group-round data-test="surgery" :items="['Yes', 'No', 'Unsure']" label="Have you had surgery for this cancer?" v-model="patientInfo.surgery_multiple"
        :hasTooltip="true" :tooltip="surgeryTooltip" />
      </div>
    <!-- </div> -->

    <!-- Solid Organ Transplant Recipient -->
    <btn-group-round data-test="solidOrgan" label="Have you been a recipient for a solid organ transplant? (excluding stem cell and bone marrow transplants)" :items="['Yes', 'No', 'Unsure']"
      v-model="patientInfo.solidOrgan_multiple" />

    <!-- zip code -->
    <!-- <div>
      <v-row>
        <v-col class="pt-2 pb-0">
          <div class="criteria-colum-title mb-0">Zip Code(s) of Interest
            <v-tooltip right max-width="300" content-class="tooltip-link">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey darken-1" size="18" dark v-bind="attrs" v-on="on" class="btn-group-tooltip-icon">
                  mdi-help-circle
                </v-icon>
              </template>
              <span>{{zipTooltip}}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <zip-codes ref="zipcodes" @updateZipcodesValid="updateZipcodesValid"/>
    </div> -->
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { defaultPatientInfo } from '@/store/initState'
import BtnGroupRound from '../components/BtnGroupRound.vue';
import ZipCodes from './ZipCodes.vue';
import { defaultTestResults } from '@/store/initState'

export default {
  name: 'PatientInfo',
  components: {
    BtnGroupRound,
    ZipCodes,
  },
  props: {
    priorTherapyData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      country: '',
      doneSearch: true,
      formValid: true,
      patientInfo: null,
      radiotherapyTooltip: `Only select "Yes" if you have had radiotherapy for the disease being used in this search for clinical trials. For example, if you have had radiation to your foot, but are searching for lung cancer trials, select "No" since your radiotherapy was not to the chest. Select "Unsure" if you don't know, and all potential trials will be returned.`,
      surgeryTooltip: `Only select "Yes" if you have had surgery for the disease being used in this search for clinical trials. For example, if you had surgery for a broken arm from falling, but are searching for trials for a brain tumor, select "No" since your surgery was not related. Select "Unsure" if you don't know, and all potential trials will be returned.`,
      zipTooltip: `Leave blank if you are willing to travel anywhere for your treatment (filtering by Cancer Center is available later in the search process). If you have specific areas you would like to stay, enter the zip code(s) below.`,
      testResults: null,
      performanceArr: [
        {"name": "Unsure", "code": "C159685"},
        {"name": "0: Asymptomatic", "code": "C105722"},
        {"name": "1: Symptomatic, but fully ambulatory", "code": "C105723"},
        {"name": "2: Symptomatic, in bed less than 50% of day", "code": "C105725"},
        {"name": "3: Symptomatic, in bed more than 50% of day, but not bed-ridden", "code": "C105726"},
        {"name": "4: Bed-ridden", "code": "C105727"},
      ]
    }
  },
  created() {
    this.patientInfo = {...this.$store.getters['criteriaStore/getPatientInfo']}
    this.testResults = {...this.$store.getters['criteriaStore/getTestResults']}
  },
  methods: {
    updateTherapy (item) {
      const index = this.patientInfo.therapy.indexOf(item)
      if (index >= 0) this.patientInfo.therapy.splice(index, 1)
    },
    reset() {
      this.patientInfo = cloneDeep(defaultPatientInfo);
      //this.$refs.zipcodes.reset();
      this.$store.dispatch('trialsStore/resetDistance')
      this.testResults = cloneDeep(defaultTestResults);
    },
    setDemo(data) {
      this.patientInfo = {
        ...this.patientInfo,
        ...data
      };
    },
    setGeoArr(geoArr) {
      this.$refs.zipcodes.setGeoArr(geoArr);
      this.$refs.AgeZipcodeForm.validate();
    },
    updateZipcodesValid(e) {
      this.$emit('zipcodeValid', e)
    },
    calculateBMI() {
      let calculatedBMI = Math.round(this.patientInfo.weight / Math.pow(this.patientInfo.height, 2) * 703 * 10) / 10;
      if (Number.isFinite(calculatedBMI) && this.patientInfo.weight !== '') {
        this.patientInfo.BMI = calculatedBMI;
      } else {
        this.patientInfo.BMI = '';
      }
    }
  },
  computed: {
    validCountry() {
      if (this.patientInfo.zipcode == '') {
        return true
      } else {
        return (this.country == 'United States' || this.doneSearch == false) || 'This is not a valid zipcode';
      }
    },
    validAge() {
      let ageValid;
      if (this.patientInfo.age.length > 0) {
        if (this.patientInfo.ageUnit == 'Years') {
          ageValid = this.patientInfo.age >=1 && this.patientInfo.age <=120
        } else {
          ageValid = this.patientInfo.age >=1 && this.patientInfo.age <=120 * 12
        }
        this.$emit('ageValid', ageValid)
        return ageValid || 'Invalid age'
      }
      this.$emit('ageValid', true)
      return true
    },
    /* bmi() {
      let calculatedBMI = Math.round(this.patientInfo.weight / Math.pow(this.patientInfo.height, 2) * 703 * 100) / 100;
      if (Number.isFinite(calculatedBMI)) {
        return calculatedBMI;
      } else {
        return 'NA';
      }
    }, */
    disableBMI() {
      return this.patientInfo.weight !== '' && this.patientInfo.height !== ''
    },
    searchType() {
      return this.$store.getters['authStore/getSearchType']
    }
  },
  watch: {
    patientInfo: {
      deep: true,
      handler() {
        this.$store.dispatch('criteriaStore/updatePatientInfo', this.patientInfo)
      }
    }
  }
}
</script>

<style>

</style>