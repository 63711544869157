import Vue from 'vue'
import camelCase from 'lodash/camelCase'

import Filters from './labelCase';

for (const filter in Filters) {
  Vue.filter(filter, Filters[filter]);
}

const requireModule = require.context(
  // Search for files in the current directory.
  '.',
  // Search for files in subdirectories.
  true,
  // Include any .js files that are not this file.
  /^((?!globalFilters).)*\.js$/
)

requireModule.keys().forEach(fileName => {
  if (fileName !== './mapFilters.js' || fileName !== './labelCase.js') {
    const moduleName = camelCase(
      fileName.replace(/(\.\/|\.js)/g, '')
    )
    const moduleDefinition = requireModule(fileName).default || requireModule(fileName)

    Vue.filter(moduleName, moduleDefinition);
  }
})