<template>
	<v-row>
		<v-col
			cols="12"
			class="pb-0"
			:class="{ 'sh-details': disableDistance }"
		>
			<v-form ref="form">
				<v-text-field
					data-test="zipcode"
					id="zipcode"
					:label="`Zip code`"
					type="number"
					ref="tf"
					max="99999"
					:loading="loading"
					@keydown.enter.prevent.stop
					v-model="zipcode"
					:rules="[zipFormat, validCountry]"
					@input="d_fetchLatLon"
				></v-text-field>
			</v-form>
		</v-col>
		<v-col
			id="distance-wrapper"
			cols="12"
			class="pb-0"
			:class="{ 'sh-details': disableDistance }"
		>
			<v-text-field
				id="distance"
				label="Distance"
				type="number"
				min="0"
				suffix="mi"
				:disabled="disableDistance"
				:hint="
					disableDistance ? 'Please enter valid zip code to enable input' : ''
				"
				persistent-hint
				:rules="[rules.distanceFormat]"
				:loading="distLoading"
				v-model="distance"
				@input="d_getTrialsForDistanceArr"
				@keydown.enter.prevent.stop
			>
			</v-text-field>
		</v-col>
	</v-row>
</template>

<script>
	import { debounce, cloneDeep } from 'lodash';
	import axios from 'axios';
	import fetchService from '@/services/fetchService';
	import { mapGetters, mapActions } from 'vuex';

	export default {
		components: {},
		name: 'ZipDistance',
		data() {
			return {
				country: '',
				doneSearch: true,
				zipcode: '',
				validZipCode: true,
				needFetch: true,
				loading: false,
				distLoading: false,
				distance: '100',
				rules: {
					distanceFormat: (value) => {
						if (value != '') {
							return value > 0 || 'Distance must be greater than 0';
						} else {
							if (value == '') {
								this.distance = '100';
							}
							return true;
						}
					},
				},
			};
		},
		created() {
			this.d_getTrialsForDistanceArr = debounce(
				this.getTrialsForDistanceArr,
				1000
			);
			this.d_fetchLatLon = debounce(this.fetchLatLon, 1000);
		},
		methods: {
			...mapActions('trialsStore', {
				t_fetchTrialsForDistanceArr: 'fetchTrialsForDistanceArr',
				t_clearTrialsForDistanceArr: 'clearTrialsForDistanceArr',
			}),
			fetchLatLon(zipcode) {
				this.validZipCode = true;
				if (this.needFetch) {
					if (zipcode.length == 5 && this.doneSearch) {
						this.loading = true;
						//let url = `/get_lat_lon_from_address?address=${zipcode}`;
						let url = `/get_sites_from_zip_distance?zips=${zipcode}&distance=${this.distance}`;
						//axios.get(url)
						fetchService.getDataFiles(url).then((response) => {
							let res = response.addresses[0];
							let geoData = {
								lat: res.lat,
								lon: res.lon,
								city: res.city,
								state: res.state,
								zipcode: res.postal_code,
							};
							this.loading = false;
							this.doneSearch = true;
							this.country = res.country;

							if (this.country == 'United States' && zipcode.length == 5) {
								// valid zip code
								this.$store.commit('criteriaStore/UPDATE_GEO_ARR', [
									geoData,
									{ lat: '', lon: '', city: '', state: '', zipcode: '' },
									{ lat: '', lon: '', city: '', state: '', zipcode: '' },
								]);
								// if have distance value, update new list of trial within the range
								if (this.distance !== null && this.distance.length > 0) {
									let trialsIDRaw = response.trial_sites;
									let trialsIDArr = Object.keys(trialsIDRaw);
									this.$store.dispatch(
										'trialsStore/updateTrialsIdForDistance',
										{
											ids: trialsIDArr,
											distance: this.distance,
											geoArr: this.geoArr
												.filter((geo) => geo.lat !== '')
												.map((geo) => {
													return {
														...geo,
														distance: this.distance,
													};
												}),
										}
									);
									//this.getTrialsForDistanceArr()
								}
								this.validZipCode = true;
							} else {
								// invalid zip code
								this.$store.commit('criteriaStore/UPDATE_GEO_ARR', [
									{ lat: '', lon: '', city: '', state: '', zipcode: '' },
									{ lat: '', lon: '', city: '', state: '', zipcode: '' },
									{ lat: '', lon: '', city: '', state: '', zipcode: '' },
								]);
								this.t_clearTrialsForDistanceArr();
								this.validZipCode = false;
							}
						});
					} else {
						// not correct zip format
						console.log('not correct zip format');
						this.$store.commit('criteriaStore/UPDATE_GEO_ARR', [
							// invalid zip code
							{ lat: '', lon: '', city: '', state: '', zipcode: '' },
							{ lat: '', lon: '', city: '', state: '', zipcode: '' },
							{ lat: '', lon: '', city: '', state: '', zipcode: '' },
						]);
						this.t_clearTrialsForDistanceArr();
					}
				}
				this.needFetch = true;
			},
			getTrialsForDistanceArr() {
				/* this.t_fetchTrialsForDistanceArr({
        distance: parseFloat(this.distance),
        geoArr: this.geoArr.filter(geo => geo.lat !== '').map(geo => {
          return {
            ...geo,
            distance: this.distance
          }
        })
      }); */

				if (this.distance > 0) {
					let url = `/get_sites_from_zip_distance?zips=${this.zipcode}&distance=${this.distance}`;
					this.distLoading = true;
					fetchService.getDataFiles(url).then((response) => {
						let trialsIDRaw = response.trial_sites;
						let trialsIDArr = Object.keys(trialsIDRaw);
						this.distLoading = false;
						this.$store.dispatch('trialsStore/updateTrialsIdForDistance', {
							ids: trialsIDArr,
							distance: this.distance,
							geoArr: this.geoArr
								.filter((geo) => geo.lat !== '')
								.map((geo) => {
									return {
										...geo,
										distance: this.distance,
									};
								}),
						});
					});
				}
			},
		},
		computed: {
			...mapGetters({
				geoArr: 'criteriaStore/getGeoArr',
			}),
			zipFormat() {
				const pattern = /^[0-9]{5}(?:-?[0-9]{4})?$/;
				const invalidMsg = 'Zip code format is invalid';
				if (this.zipcode.length > 0) {
					return pattern.test(this.zipcode) || invalidMsg;
				}
				return true;
			},
			validCountry() {
				if (this.zipcode == '') {
					return true;
				} else {
					//return (this.country == 'United States' || this.loading == true) || 'This is not a valid zip code';
					return this.validZipCode || 'This is not a valid zip code';
				}
			},
			disableDistance() {
				return this.geoArr.filter((geo) => geo.lat !== '').length == 0;
			},
		},
	};
</script>

<style></style>
