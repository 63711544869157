<template>
  <span :class="className">
    <v-icon v-if="value == true" class="brown--text darken-3" size="20">mdi-minus-circle-outline</v-icon>
    <v-icon v-else size="24" class="teal--text lighten-1">mdi-check-bold</v-icon>
  </span>
</template>

<script>
export default {
  name: 'MatchExc',
  props: {
    value: {
      type: [Boolean, String],
      required: true,
    },
  },
  computed: {
    className() {
      return this.value === true ? 'stop' : 'check';
    }
  }
};
</script>
<style></style>
