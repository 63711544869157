<template>
  <div>
    <v-menu v-model="userMenuOpen" :close-on-content-click="false" max-width="335" min-width="250" 
      offset-y transition="slide-y-transition" attach="#sign-out-menu-wrapper" >
      <template v-slot:activator="{ on }">
        <v-btn id="nav-account-btn" text v-on="on" 
          aria-label="User Account Information" >
          <v-avatar color="white" class="nav-menu" size="40">
            <!-- <div>{{$store.getters['authStore/getEmail'].slice(0,1).toUpperCase()}}</div>
            <div>{{username.slice(0,1).toUpperCase()}}</div> -->
            <v-icon size="32" aria-label="user profile" role="img" aria-hidden="false" color="#0080a5">mdi-account</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-card>
        <v-layout class="px-10 py-5" column align-center>
          <!-- <v-avatar color="grey darken-4" class="mb-3" size="66">
            <div class="white--text text-h3">{{$store.getters['authStore/getEmail'].slice(0,1).toUpperCase()}}</div>
            <div class="white--text text-h3">{{username.slice(0,1).toUpperCase()}}</div>
          </v-avatar> -->
          <!-- <strong v-text="userInfo.user_id"></strong>
          <div class="text-h5 my-2">{{username.toUpperCase()}}</div> -->
          <div class="body-2" v-if="email">{{email}}</div>
          
          <v-btn class="short mt-4" color="primary" @click="openTypesDialog" v-if="isAdmin">
            Admin
          </v-btn>
        </v-layout>

        <v-divider></v-divider>
        <v-layout class="py-2" align-center justify-center>
          <v-btn ref="btnSignOut" large text @click="signOut" class="primary--text">
            <v-icon size="24" aria-label="sign out" role="img" aria-hidden="true"> mdi-logout</v-icon >
            Sign out
          </v-btn>
        </v-layout>
      </v-card>
    </v-menu>
    <div id="sign-out-menu-wrapper" style="position: relative;"></div>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title>
          Sign Out
        </v-card-title>

        <v-card-text class="ml-n2 mr-0 pr-2">
          <div v-if="hasSignOut">
            <p class="text-h6">You have been signed out of FACTrial.</p>
            <p class="text-h6">We recommend that you close the FACTrial application.</p>
          </div>
          <div v-else>
            <p class="text-h6">Hang on a moment while we sign you out.</p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <types-dialog :typesDialog="typesDialogOpen" @typesDialogClosed="typesDialogOpen = false" />
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import AdminDialog from './dialog/AdminDialog.vue';
import TypesDialog from './dialog/TypesDialog.vue';

export default {
  name: 'userWidget',
  components: { AdminDialog, TypesDialog },
  data() {
    return {
      userMenuOpen: false,
      typesDialogOpen: false,
      openGoToSignInPageDialog: false,
      userData: null,
      dialog: false,
      hasSignOut: false
    };
  },
  created() {
    //this.fetchUser();
  },
  methods: {
    async signOut() {
      this.userMenuOpen = false;
      this.dialog = true;
      try {
        await Auth.signOut();
        this.$store.dispatch('authStore/setUser', null)
        setTimeout(() => {
          this.hasSignOut = true;
          setTimeout(() => {
            let url = `${window.location.origin}`
            window.open(url, '_self')
          },2000)
        }, 1000)
      } catch (error) {
        console.log('error signing out:', error)
      }
    },
    openTypesDialog() {
      /* this.typesDialogOpen = true; */
      this.userMenuOpen = false;
      this.$router.push({name: 'Admin'})
    }
  },
  mounted() {
    if (this.group?.includes('admin')) {
      this.$store.dispatch('typesStore/fetchCriteriaTypes');
      this.$store.dispatch('typesStore/fetchAllNctId');
    }
  },
  computed: {
    username() {
      return this.$store.getters['authStore/getUsername']
    },
    email() {
      return this.$store.getters['authStore/getEmail']
    },
    group() {
      return this.$store.getters['authStore/getGroup']
    },
    isAdmin() {
      return this.group?.includes('admin') ? true : false
    }
  }
};
</script>

<style lang="scss">
#sign-out-menu-wrapper {
  position: fixed;
  //min-width: 250px;
  max-width: 335px;
  top: -45px;
  right: 5px;
  .v-menu__content {
    right: 0;
    left: auto !important;
  }
  .primary--text .v-btn__content {
    text-transform: uppercase;
  }
}
#nav-account-btn {
  //padding: 0;
  color: #424242;
  cursor: pointer;
  font-weight: 700;
  //max-width: 40px;
  min-width: 40px;
  .v-ripple__container {
    display: none;
  }
  &:focus:before {
    opacity: 0;
  }
}
#app.v-application .v-tooltip__content.t-bottom.user:after {
    left: 70% !important;
}
.v-avatar.nav-menu {
  font-size: 20px;
  line-height: 40px;
  div {
    transform: translateX(-1px);
  }
}
</style>
