<template>
  <div>
    <summary-header @backToCriteriaTab="$emit('backToCriteriaTab')" />
    
    <div id="summary-table" v-resize="onResize">
      <div class="data-table d-flex">

        <!-- sidebar filter -->
        <div class="filter-wrapper flex-shrink-0" :class="{open: filterMenuOpen}">
          <div class="filter-container">
            <tooltip-icon :tooltip="filterMenuOpen ? 'Collapse Filters' : 'Open filters'" 
                          :icon="filterMenuOpen ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right'"
                          color="unstyled" @iconClicked="filterMenuOpen = !filterMenuOpen"
                          location="right" />

            <results-filter v-show="filterMenuOpen" @filterChanged="clearSearch" />
          </div>
        </div>

        <!-- data table section -->
        <div id="result-table-wrapper" class="flex-grow-1 overflow-hidden pl-md-2">

          <!-- data table header -->
          <div class="data-table-header d-flex mx-4 pt-6 pb-6 align-end">
            <div class="trials-info" :class="{'pl-5': !filterMenuOpen}">
              <div class="matching-trials text-h3 font-weight-bold mb-4">{{filterTrials.length}} Matching Trials</div>
              <div>Trials marked as hidden <strong>{{numberOfEye}}</strong></div>
              <div>Trials saved: <strong>{{numberOfStar}}</strong></div>
              <div class="mt-2 d-flex align-center">
                <v-btn id="new-search" data-test="new-search" depressed color="outlined" 
                  @click.stop="onNewSearch" class="d-md-block elevation-2 primary-bd">
                  <v-icon left color="primary">mdi-notebook-edit </v-icon> REFINE SEARCH
                </v-btn>
                <div class="ml-2">
                  <v-tooltip max-width="300" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="grey darken-1" size="18" dark v-bind="attrs" v-on="on" class="btn-group-tooltip-icon">
                        mdi-help-circle
                      </v-icon>
                    </template>
                    <span>To change you search criteria, please use the Refine Search button instead of selecting the back option of the browser.</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
            
            <v-spacer></v-spacer>
            <div class="d-flex align-center flex-wrap">
              <!-- <div id="ccdi-switch" v-if="selectedGroup == 'CCDI'" class="d-flex my-2 mb-md-0 my-lg-0"> -->
              <div id="ccdi-switch" v-if="shouldShowToggle == 'Y'" class="d-flex my-2 mb-md-0 my-lg-0">
                <span class="mr-1">All Trials</span>
                <v-switch :input-value="CCDIStatus" label="CCDI" hide-details
                  :trueValue="'CCDI'" :false-value="''"
                  @change="updateCCDIStatus"></v-switch>
              </div>
              <!-- <v-btn @click="refreshToken">Refresh Token</v-btn> -->
              <div v-show="gridList == 0" class="search-wrapper my-4 my-lg-0">
                <v-text-field v-model="search" :append-icon="'mdi-magnify'" label="Keyword Search" clearable
                  hide-details class="mr-8 mx-md-4 pt-0" single-line></v-text-field>
              </div>

              <!-- options filter -->
              <options-widget :gridList="gridList" class="my-4 my-lg-0 ml-4" />

              <!-- generate report -->
              <export-widget class="my-4 my-lg-0 ml-4" />

              <!-- btn group toggle grid/list view -->
              <div id="btn-group-grid-list" class="my-4 my-lg-0 ml-4">
                <v-btn-toggle :value="gridList" mandatory @change="toggleGridList">
                  <tooltip-icon tooltip="Table View" icon="mdi-table-large"/>
                  <tooltip-icon tooltip="List View" icon="mdi-menu"/>
                </v-btn-toggle>
              </div>
            </div>
            
          </div>

          <!-- grid data table -->
          <table-view v-show="gridList == 0" :searchStr="searchStr" />

          <!-- list data table -->
          <list-view v-show="gridList == 1" />
        </div>    
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters} from 'vuex';
import ExportWidget from './ExportWidget.vue';
import OptionsWidget from './OptionsWidget.vue';
import ResultsFilter from './ResultsFilter.vue';
import SummaryHeader from './SummaryHeader.vue';
import TableView from './TableView.vue';
import ListView from './ListView.vue';
import TooltipIcon from './TooltipIcon.vue';
import { Auth } from 'aws-amplify';

export default {
  name: 'Result',
  components: { ExportWidget, ListView, OptionsWidget, ResultsFilter, SummaryHeader, TableView, TooltipIcon },
  data() {
    return {
      search: '',
      searchStr: '',
      filterMenuOpen: true,
      selectedHeadersArr: [],
      gridList: 1,
    }
  },
  methods: {
    clearSearch() {
      this.search = ''
    },
    onNewSearch() {
      this.$emit('backToCriteriaTab')
    },
    toggleGridList(e) {
      this.gridList = e;
    },
    refreshToken() {
      Auth.currentSession()
        .then(data => {
          console.log(data)
        })
        .catch(err => console.log(err));
    },
    updateCCDIStatus(e) {
      this.$store.dispatch('trialsStore/updateCCDIStatus', e)
    },
    onResize() {
      if (window.innerWidth <= 1263) {
        this.gridList = 1;
      }
    }
  },
  computed: {
    ...mapGetters({
      loadingNCTId: 'trialsStore/getLoadingNCTId',
      trialsHeader: 'trialsStore/getTrialsHeader',
      trials: 'trialsStore/getTrials',
      filterTrials: 'trialsStore/getFilteredTrials',
      numberOfStar: 'trialsStore/getNumberOfStarSelected',
      //numberOfStarNCTId: 'trialsStore/getNumberOfStarNCTId',
      numberOfEye: 'trialsStore/getNumberOfEyeSelected',
      CCDIStatus: 'trialsStore/getCCDIStatus',
      selectedGroup: 'authStore/getSelectedGroup',
      shouldShowToggle: 'authStore/shouldShowToggle'
    }),
    activeTrialsHeader() {
      return this.trialsHeader.filter(header => {
        return header.show === true
      })
    },
  },
  watch: {
    search: debounce(function(newVal){
      this.searchStr = newVal
    }, 200)
  }
}
</script>

<style>

</style>
