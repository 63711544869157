export default {
  snakeCase: function(value) {
    if (!value) return ''
    return value.trim().toLowerCase().split(/[ _]+/).join('-')
  },
  sentenceCase: function(value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
  spaceCase: function(value) {
    return value.replace(/[_-]+/g, " ");
  },
  titleCase: function(value) {
    let wordsArray = value.trim().toLowerCase().split(/[ ,_-]+/)
    let capsArray = []

    wordsArray.forEach(word => {
        capsArray.push(word[0].toUpperCase() + word.slice(1))
    });

    return capsArray.join(' ')
  },
  pascalCase: function(value) {
    let wordsArray = value.toLowerCase().split(/[ ,_-]+/)
    let capsArray = []

    wordsArray.forEach(word => {
        capsArray.push(word[0].toUpperCase() + word.slice(1))
    });

    return capsArray.join('')
  },
  replaceDot: function(value) {
      return value.replace(/\./g, '-');
  }
}