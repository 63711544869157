export const criteriaCcodeDictionary = {
  sex_multiple: ['C46109', 'C46110'],
  age: 'C25150',

  weight: 'C81328',
  height: 'C164634',
  BMI: 'C16358',
  ableToSwallow_multiple: 'C141232',

  chemo_multiple: 'C62634',
  radiotherapy_multiple: 'C15313',
  surgery_multiple: 'C15329',

  brainCNS_multiple: 'C4015',
  recurrent_multiple: 'C38155',
  refractory_multiple: 'C39752',

  hiv_multiple: 'C15175',
  hepatitisB_multiple: 'C3097',
  hepatitisC_multiple: 'C3098',
  solidOrgan_multiple: 'C15289',
  wbc: 'C51948',
  platelets: 'C51951',
}
export const defaultPatientInfo = {
  BMI: '',
  weight: '',
  height: '',
  performance: null,
  ableToSwallow_multiple: 2,
  sex_multiple: 2,
  age: '',
  ageUnit: 'Years',
  geoArr: [
    { lat: '', lon: '', city: '', state: '', zipcode: '' },
    { lat: '', lon: '', city: '', state: '', zipcode: '' },
    { lat: '', lon: '', city: '', state: '', zipcode: '' }
  ],
  chemo_multiple: 2,
  radiotherapy_multiple: 2,
  surgery_multiple: 2,
  therapy: [],
  brainCNS_multiple: 2, //actually belong biomarkers
  solidOrgan_multiple: 2, 
}
export const defaultBiomarkers = {
  egfr_multiple: 2,
  alk_multiple: 2,
  ros1_multiple: 2,
  nras_multiple: 2,
  kras_multiple: 2,
  hras_multiple: 2,
  her2Neu_multiple: 2,
  biomarkersSelected: [],
}
export const biomarkersMultipleInfo = {
  egfr_multiple: {
    label: 'EGFR',
    positive: [{name: 'EGFR Positive', code: 'C134501'}, {name: 'EGFR Gene Mutation', code: 'C98357'},],
    negative: [{name: 'EGFR Negative', code: 'C150501'}]
  },
  alk_multiple: {
    label: 'ALK',
    positive: [{name: 'ALK Positive', code: 'C128831'}, {name: 'ALK Gene Mutation', code: 'C81945'},],
    negative: [{name: 'ALK Negative', code: '133707'}]
  },
  ros1_multiple: {
    label: 'ROS1',
    positive: [{name: 'ROS1 Positive', code: 'C155991'}, {name: 'ROS1 Gene Mutation', code: 'C130952'},],
    negative: [{name: 'ROS1 Negative', code: 'C153498'}]
  },
  nras_multiple: {
    label: 'NRAS',
    positive: [{name: 'NRAS Positive', code: 'C171618'}, {name: 'NRAS Gene Mutation', code: 'C41381'},],
    negative: [{name: 'NRAS Negative', code: 'C142837'}]
  },
  kras_multiple: {
    label: 'KRAS',
    positive: [{name: 'KRAS Positive', code: 'C142134'}, {name: 'KRAS Gene Mutation', code: 'C41361'},],
    negative: [{name: 'KRAS Negative', code: 'C142879'}]
  },
  hras_multiple: {
    label: 'HRAS',
    positive: [{name: 'HRAS Positive', code: 'C171617'}, {name: 'HRAS Gene Mutation', code: 'C45943'},],
    negative: [{name: 'HRAS Negative', code: 'C160373'}]
  },
  her2Neu_multiple: {
    label: 'HER2/Neu',
    positive: [{name: 'HER2/Neu Positive', code: 'C68748'}],
    negative: [{name: 'HER2/Neu Negative', code: 'C68749'}]
  }
}
export const defaultTestResults = {
  hiv_multiple: 2,
  hepatitisB_multiple: 2,
  hepatitisC_multiple: 2,
  //solidOrgan_multiple: 2,
  wbc: '',        
  platelets: '',
  //performance: {"name": "Unsure", "code": "C159685"},
}
export const defaultDisease = {
  diseaseSelected: [], //three dropdown
  gyn: [],
  lung: [],
  solid: [],
  allDisease: [], //disease tree
  diseaseModifiers: [],
  recurrent_multiple: 2,
  refractory_multiple: 2,
}
export const criteriaDict = {
  sex_multiple: 'Sex',
  age: 'Age',
  ageUnit: 'ageUnit',
  zipcode: 'Zip Code', 
  geoArr: 'Zip Codes',
  chemo_multiple: 'Prior Chemo or Immuno',
  radiotherapy_multiple: 'Prior Radiotherapy',
  surgery_multiple: 'Other Prior Surgery',
  therapy: 'Prior Therapy',
  //brainCNS_multiple: 'Brain/CNS metastases',
  brainCNS_multiple: 'CNS involvement',
  diseaseSelected: 'Diseases',
  gyn: 'Gyn',
  lung: 'Lung',
  solid: 'Solid',
  hiv_multiple: 'HIV',
  hepatitisB_multiple: 'Active Hepatitis B infection',
  hepatitisC_multiple: 'Active Hepatitis C infection',
  solidOrgan_multiple: 'Solid Organ Transplant Recipient',
  wbc: 'WBC (/uL)',        
  platelets: 'Platelets (/uL)',
  performance: 'Performance Status',
  height: 'Height',
  weight: 'Weight',
  BMI: 'BMI',
  ableToSwallow_multiple: 'Ability to Swallow',
  "C38155": 'Recurrent', 
  "C39752": 'Refractory'
}