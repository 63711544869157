<template>
  <div>
    <v-menu v-model="showHideOptionsMenu" :close-on-content-click="false" 
      offset-y z-index="100" transition="slide-y-transition"
      :attach="`#show-hide-options-container`">
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed color="outlined" v-bind="attrs" v-on="on" class="mt-n2 pr-2 mt-sm-0 elevation-2 primary-bd">
          <v-icon left color="primary">mdi-cog</v-icon> OPTIONS <v-icon class="ml-2">mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-card class="popup-list">
        <div id="show-hide-options" class="form-group">
          <div class="group-label purple--text">SHOW/HIDE TRIALS</div>
          <v-radio-group data-test="show-hide-trials" v-model="showHideStarHideOption"
            @change="showHideStarEye" class="pt-0 mt-0">
            <v-radio v-for="item in showHideOptionArr" :key="item.value" :label="item.label" :value="item.value" v-rb-focus></v-radio>
          </v-radio-group>

          <!-- <v-btn v-if="gridList == 0" data-test="show-hide-criteria" color="primary" class="mt-3" @click="toggleCriteria">{{criteriaShown == true ? 'Hide Criteria' : 'Show Criteria'}}</v-btn> -->

          <div v-if="gridList == 0" class="group-label purple--text mt-4">SHOW/HIDE COLUMNS</div>
          <show-hide-cols v-if="gridList == 0" :header="trialsHeader" @activeHeaderChange="updateTrialsHeader" />
        </div>
      </v-card>
    </v-menu>
    <div :id="`show-hide-options-container`" class="popup-menu-container"></div>
  </div>
</template>

<script>
import { mapGetters} from 'vuex';
import ShowHideCols from './ShowHideCols.vue';

export default {
  name: 'OptionsWidget',
  components: {ShowHideCols},
  props: {
    gridList: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      criteriaShown: false,
      showHideOptionsMenu: false,
      showHideOptionArr: [
        {label: 'Show all trials', value: 0}, 
        {label: 'Hide trials marked as hidden', value: 1}, 
        {label: 'Show saved trials only', value: 2},
        {label: 'Show hidden trials only', value: 3}
      ],
      showHideStarHideOption: 1,
    }
  },
  methods: {
    updateTrialsHeader(header) {
      this.$store.dispatch('trialsStore/updateTrialsHeader', header)
    },
    toggleCriteria() {
      this.criteriaShown = !this.criteriaShown;
      this.$store.dispatch('trialsStore/toggleCriteria', this.criteriaShown)
    },
    showHideStarEye() {
      this.showHideOptionsMenu = false;
      this.$store.dispatch('trialsStore/showHideStarHideTrials', this.showHideStarHideOption)
    },
  },
  computed: {
    ...mapGetters({
      trialsHeader: 'trialsStore/getTrialsHeader',
    }),
  }
}
</script>

<style>

</style>