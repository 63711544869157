import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0080A5',
        secondary: '#d83933',
        accent: '#00bde3',
        error: '#e52207',
        info: '#ffbe2e',
        success: '#04c585',
        warning: '#e41d3d',
      },
      /* dark: {
        primary: '#3f51b5',
        secondary: '#b0bec5',
        anchor: '#8c9eff',
      }, */
    },
  },
});
