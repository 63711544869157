<template>
	<v-app>
		<div
			id="navbar-container"
			class="px-4 px-md-8 py-2 d-flex align-center"
		>
			<button
				id="logo"
				class="text"
				@click="backToHome"
			>
				<span
					><img
						src="/img/FACTrialLogo.png"
						alt="emFACT Logo"
				/></span>
			</button>
			<!-- <span id="logo" class="text"><img src="/img/FACTrialLogo.png" alt="emFACT Logo" /></span> -->
			<!-- <span id="logo" class="img" v-else><img src="/img/emFACT-logo-for-darkbg-beta.png" alt="emFACT Logo"></span>
      <div class="program-name">{{selectedProgramName}}</div> -->
			<v-spacer></v-spacer>
			<!-- <sign-out-dialog /> -->
			<curated-upload-widget v-if="showSignOut" />
			<v-btn
				v-if="showSignOut"
				id="video-widget"
				text
				color="primary"
				dark
				class="no-ripple"
				@click="$store.commit('videoStore/UPDATE_VIDEO_DIALOG', true)"
			>
				<v-icon
					size="44"
					class="white--text"
					aria-label="intro video"
					role="img"
					aria-hidden="false"
					>mdi-help-circle</v-icon
				>
			</v-btn>

			<alert-widget v-if="showSignOut" />
			<starred-trial-widget v-if="showSignOut" />
			<user-widget v-if="showSignOut" />
		</div>

		<v-main>
			<div id="main-content">
				<v-container
					fluid
					class="px-0 py-0"
				>
					<transition
						mode="out-in"
						enter-active-class="page-slide-in"
						leave-active-class="page-zoom-out"
					>
						<router-view />
					</transition>
				</v-container>
			</div>

			<notification />
			<idle-dialog v-if="isIdle" />
			<video-dialog />
		</v-main>

		<fact-footer></fact-footer>
	</v-app>
</template>

<script>
	import AlertWidget from './components/AlertWidget.vue';
	import IdleDialog from './components/dialog/IdleDialog.vue';
	import SignOutDialog from './components/dialog/SignOutDialog.vue';
	import CuratedUploadWidget from './components/CuratedUploadWidget.vue';
	import VideoDialog from './components/dialog/VideoDialog.vue';
	import FactFooter from './components/FactFooter';
	import Notification from './components/Notification.vue';
	import StarredTrialWidget from './components/StarredTrialWidget.vue';
	import UserWidget from './components/UserWidget.vue';
	import { workerTimer } from '@/assets/scripts/workerTimer';

	workerTimer();

	export default {
		name: 'App',
		components: {
			FactFooter: FactFooter,
			Notification,
			SignOutDialog,
			UserWidget,
			CuratedUploadWidget,
			StarredTrialWidget,
			AlertWidget,
			IdleDialog,
			VideoDialog,
		},
		data() {
			return {};
		},
		methods: {
			backToHome() {
				this.$router.push({ name: 'Home' });
			},
		},
		computed: {
			showSignOut() {
				return this.$route.meta.requiresAuth;
			},
			isIdle() {
				return (
					(this.$store.state.idleVue.isIdle ||
						this.$store.getters['authStore/getIdleDialogStatus']) &&
					this.$route.name !== 'Login' &&
					this.$route.name !== 'Share'
				);
			},
			selectedGroup() {
				return this.$store.getters['authStore/getSelectedGroup'];
			},
			selectedProgramName() {
				let selectedGroup = this.$store.getters['authStore/getSelectedGroup'];
				if (selectedGroup !== '') {
					let programName = this.$store.getters['authStore/getPrograms'].find(
						(program) => program.cognito_group_name == selectedGroup
					).emfact_program_name;
					return programName;
				} else {
					return 'test';
				}
			},
		},
	};
</script>
