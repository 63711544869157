<template>
  <!-- sites -->
  <v-expansion-panel>
    <v-expansion-panel-header>SITES</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" sm="4" class="pt-4" v-for="(site, index) in sites" :key="index">
          <v-card class="py-0">
            <v-card-title class="details-card-title"><strong>{{siteNameArr(site)}}</strong></v-card-title>
            <v-card-text class="details-card-text">
              <p v-if="site.org_email">
                <v-icon class="teal--text darken-3 mr-2" size="18" aria-label="phone" role="img" aria-hidden="false">mdi-email</v-icon>
                <a :href="`mailto:${site.org_email}`">{{site.org_email}}</a>
              </p>
              <p v-if="site.contact_phone">
                <v-icon class="teal--text darken-3 mr-2" size="18" aria-label="phone" role="img" aria-hidden="false">mdi-phone</v-icon>
                <a :href="`tel:+1${site.contact_phone}`">{{site.contact_phone}}</a>
              </p>
              <p v-if="site.org_address_line_1">
                <v-icon class="teal--text darken-3 mr-2" size="18" aria-label="phone" role="img" aria-hidden="false">mdi-map-marker</v-icon>
                <a :href="formatAddress(site)" target="_blank">
                  {{site.org_address_line_1}}<br />
                  {{site.org_city}}, {{site.org_state_or_province}} {{site.org_postal_code}}<br />
                  {{site.org_country}}
                </a>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'ListViewItemSites',
  props: {
    sites: { type: Array, required: true },
  },
  methods: {
    formatAddress(site) {
      return `https://www.google.com/maps/place/${site.org_address_line_1},+${site.org_city},+${site.org_state_or_province}+${site.org_postal_code}`
    },
    siteNameArr(site) {
      let siteArr = [site.org_family, site.org_name]
      siteArr = siteArr.filter(function (item, pos) {
        return item !== null && siteArr.indexOf(item) == pos;
      })
      return siteArr.join(', ')
    }
  }
};
</script>

<style></style>
