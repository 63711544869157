<template>
  <!-- inclusion/exclusion -->
  <v-expansion-panel v-if="clusionArr.length > 0" class="mb-2">
    <v-expansion-panel-header>{{label}}</v-expansion-panel-header>
    <v-expansion-panel-content class="list-details-table">
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr v-for="(row, index) in clusionArr" :key="index">
              <td> 
                {{row.description}}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'ListViewItemIncExc',
  props: {
    label : {type: String, required: true},
    //clusionArr: { type: Array, required: true },
    inclusion_exclusion: { type: Array, required: true }
  },
  computed: {
    clusionArr() {
      let arr = [];
      if (this.label == 'Inclusion') {
        arr = this.inclusion_exclusion.filter(item => {
          return item.inclusion_indicator == 'true' || item.inclusion_indicator == true
        })
      } else if (this.label == 'Exclusion') {
        arr = this.inclusion_exclusion.filter(item => {
          return item.inclusion_indicator == 'false' || item.inclusion_indicator == false
        })
      }
      return arr;
    }
  }
};
</script>

<style></style>
