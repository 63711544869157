import { cloneDeep } from 'lodash';
import fetchService from '@/services/fetchService';

export default {
  state: {
    criteriaHeader: [
      {text: 'REMOVE', value: 'remove', align: 'center', sortable: false, show: true},
      {text: 'EDIT', value: 'edit', align: 'center', sortable: false, show: true},
      {text: 'NAME', value: 'search_name', sortable: true, show: true},
      {text: 'SEARCH ON', value: 'session_date', sortable: true, show: true},
      {text: 'SEARCH CRITERIA', value: 'search_info', sortable: false, show: true},
      {text: 'SEARCH', value: 'search', align: 'center', width: 100, sortable: false, show: true}
    ],
    recentCriteria: [],
    loadingRecentCriteria: false
  },
  getters: {
    getLoadingRecentCriteria: (state) => state.loadingRecentCriteria,
    getCriteriaHeader: (state) => state.criteriaHeader,
    getRecentCriteria: (state) => state.recentCriteria,
  },
  mutations: {
    UPDATE_RECENT_CRITERIA(state, data) {
      state.recentCriteria = data;
    },
    UPDATE_SEARCH_NAME(state, data) {
      let index = state.recentCriteria.findIndex(search => search.session_uuid == data.session_uuid);
      let cloneCriteriaItem = cloneDeep(state.recentCriteria)
      cloneCriteriaItem[index].search_name = data.search_name;

      state.recentCriteria = cloneCriteriaItem;
    },
    UPDATE_SEARCH_ITEM(state, data) {
      let index = state.recentCriteria.findIndex(search => search.session_uuid == data.item.session_uuid);
      if (data.type == 'delete') {
        state.recentCriteria.splice(index, 1);
      }
    }
  },
  actions: {
    fetchRecentCriteria({ commit, state }) {
      state.loadingRecentCriteria = true;
      return new Promise((resolve, reject) => {
        fetchService.getDataFiles('search_session_data')
          .then((response) => {
            state.loadingRecentCriteria = false;
            commit('UPDATE_RECENT_CRITERIA', response);
            resolve(response);
          })
          .catch((error) => {
            console.log('error', error);
            reject(error);
          });
      });
    },
    updateSearchName({commit}, payload) {
      return new Promise((resolve, reject) => {
        fetchService.putDataFiles('rename_search_sessions', [payload])
          .then(response => {
            commit('UPDATE_SEARCH_NAME', payload)
            resolve(response)
          })
          .catch((error) => {
            console.log('error', error);
            reject(error);
          });
      });
    },
    deleteSearchItem({commit}, searchItem) {
      commit('UPDATE_SEARCH_ITEM', {
        type: 'delete',
        item: searchItem
      })
      let payload = [searchItem.session_uuid];
      return new Promise((resolve, reject) => {
        fetchService.deleteDataFiles('delete_search_sessions', payload)
          .then(response => {
            resolve(response)
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
}