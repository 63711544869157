<template>
  <div>
    <div v-if="fetchingSavedMatch">
      <div class="text-h3 ma-8 text-center">
        <p><v-progress-circular indeterminate color="primary"></v-progress-circular></p>
        Loading page ...
      </div>
    </div>
    <div v-else>
      <div v-if="hasError">
        <div class="text-h3 ma-8">This page is no longer available</div>
      </div>
      <div v-else>
        <!-- <div v-if="shareSummary">
          <share-summary-header :shareSummary="shareSummary" />
        </div> -->

        <div id="share-result-msg" class="d-flex mt-8 mx-4 mb-0 pa-4 align-center">
          <div class="pr-2">
            <v-icon>mdi-information</v-icon>
          </div>
          <div>
            You are visiting FACTrial via a shareable link. All content on this page is read only for your reference. We encourage you to
            <router-link to="/" >CREATE AN ACCOUNT</router-link> to take full advantage of FACTrial such as its state-of-art search feature and ability to save trials.
          </div>
        </div>

        <div id="summary-table" v-resize="onResize">
          <div class="data-table d-flex">

            <!-- data table section -->
            <div id="result-table-wrapper" class="flex-grow-1 overflow-hidden pl-md-2">

              <!-- data table header -->
              <div class="data-table-header d-flex mx-4 pt-6 pb-6 align-end">
                <div class="trials-info">
                  <div class="matching-trials text-h3 font-weight-bold my-2">{{trials.length}} Matching Trials</div>
                </div>
              </div>

              <!-- list data table -->
              <share-list-view :trials="trials" :trialsHeader="trialsHeader" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { debounce } from 'lodash';
import { mapGetters} from 'vuex';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import ExportWidget from '../components/ExportWidget.vue';
import OptionsWidget from '../components/OptionsWidget.vue';
import ResultsFilter from '../components/ResultsFilter.vue';
import ShareSummaryHeader from '../components/ShareSummaryHeader.vue';
import TableView from '../components/TableView.vue';
import ShareListView from '../components/ShareListView.vue';
import TooltipIcon from '../components/TooltipIcon.vue';
import shareResultJSON from '@/assets/data/shareResult.json';

export default {
  name: 'ShareableResults',
  components: { ExportWidget, ShareListView, OptionsWidget, ResultsFilter, ShareSummaryHeader, TableView, TooltipIcon },
  props: ['id'],
  data() {
    return {
      shareSummary: null,
      trialsHeader: [],
      trials: [],
      hasError: false,
      fetchingSavedMatch: true
    }
  },
  created() {
    //fetch shareResultJSON
    this.fetchSavedMath();
    /* setTimeout(() => {
      this.shareSummary = shareResultJSON.summary;
      this.trialsHeader = shareResultJSON.trialsHeader;
      this.trials = shareResultJSON.trials;
      this.fetchingSavedMatch = false;
    }, 2000) */
  },
  methods: {
    fetchSavedMath() {
      const baseURL = process.env.VUE_APP_REMOTE === 'true' ? 'https://demo.uat.factrial.com/api/v1/' : '/api/v1/'; // full-url only use by developer's laptop; relative-url is what's used for deployment.
      this.fetchingSavedMatch = true;
      axios.get(`${baseURL}get_search_results?saved_search_id=${this.id}`)
      //fetchService.getDataFiles('get_search_results', {saved_search_id: this.id})
        .then(res => {
          console.log('shareResultJSON', res);
          this.shareSummary = res.data.data.summary;
          this.trialsHeader = res.data.data.trialsHeader;
          this.trials = res.data.data.trials
        }).catch(error => {
          console.log('error', error);
          this.hasError = true
        }).finally(() => {
          this.fetchingSavedMatch = false
        })
    },
    clearSearch() {
      this.search = ''
    },
    toggleGridList(e) {
      this.gridList = e;
    },
    refreshToken() {
      Auth.currentSession()
        .then(data => {
          console.log(data)
        })
        .catch(err => console.log(err));
    },
    updateCCDIStatus(e) {
      this.$store.dispatch('trialsStore/updateCCDIStatus', e)
    },
    onResize() {
      if (window.innerWidth <= 1263) {
        this.gridList = 1;
      }
    }
  },
  computed: {
    ...mapGetters({
      loadingNCTId: 'trialsStore/getLoadingNCTId',
    }),
    activeTrialsHeader() {
      return this.trialsHeader.filter(header => {
        return header.show === true
      })
    },
  },
  watch: {
    search: debounce(function(newVal){
      this.searchStr = newVal
    }, 200)
  }
}
</script>

<style>

</style>
