<template>
  <div id="summary-header" class="font-weight-bold elevation-4">
    <v-expansion-panels v-model="summaryPanelOpen">
      <v-expansion-panel active-class="summary-open">
        <v-expansion-panel-header>
          <template v-slot:actions>
            <tooltip-icon :tooltip="summaryOpen ? 'Collapse Summary' : 'Open Summary'" icon="mdi-chevron-double-down" color="unstyled"
              @iconClicked="summaryOpen = !summaryOpen" />
          </template>
          <template>
            <div id="summary-expansion-title">SELECTED SEARCH CRITERIA</div>

            <!-- <v-btn id="new-search" data-test="new-search" depressed color="outlined" 
              @click.stop="onNewSearch" class="d-none d-md-block mt-n2 mt-sm-0 elevation-2 primary-bd">
              <v-icon left color="primary">mdi-notebook-edit </v-icon> REFINE SEARCH
            </v-btn> -->
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="summary-info pt-5 pl-md-4 pr-md-3 pb-md-4"> 
            <v-row>
              <v-col cols="12" sm="10" class="pa-0 px-sm-0">
                <!-- <div class="text-h5 font-weight-bold mb-3">SELECTED SEARCH CRITERIA</div> -->

                <!-- patient info -->
                <div v-if="!isPatientInfoDefault">
                  <span class="summary-icon mr-2"><img alt="patient info" height="16" src="@/assets/patient_info.svg" /></span>
                  <span>
                    <span v-if="geoArrFiltered.length > 0" class="summary-criteria summary-patient-info"><strong>Geolocation:</strong> {{geoRender}}</span>
                    
                    <patient-info-criteria :criteria="patientInfo.sex_multiple" :label="'Sex'" :labelArr="['Male', 'Female']" />

                    <span v-if="patientInfo.age.length > 0" class="summary-criteria"><strong>Age:</strong> {{patientInfo.age}} {{patientInfo.ageUnit}}</span>
                    <span v-if="patientInfo.weight && patientInfo.weight.length > 0" class="summary-criteria"><strong>Weight:</strong> {{patientInfo.weight}}</span>
                    <span v-if="patientInfo.height && patientInfo.height.length > 0" class="summary-criteria"><strong>Height:</strong> {{patientInfo.height}}</span>
                    <span v-if="patientInfo.BMI && patientInfo.BMI.length > 0" class="summary-criteria"><strong>BMI:</strong> {{patientInfo.BMI}}</span>

                    <span v-if="patientInfo.performance" class="summary-criteria"><strong>Performance Status:</strong> {{patientInfo.performance.name}}</span>

                    <patient-info-criteria :criteria="patientInfo.ableToSwallow_multiple" :label="'Able to Swallow'" :labelArr="['Yes', 'No']" />
                    <patient-info-criteria :criteria="patientInfo.chemo_multiple" :label="'Prior Chemo or Immuno'" :labelArr="['Yes', 'No']" />
                    <patient-info-criteria :criteria="patientInfo.radiotherapy_multiple" :label="'Prior Radiotherapy'" :labelArr="['Yes', 'No']" />
                    <patient-info-criteria :criteria="patientInfo.surgery_multiple" :label="'Prior Surgery'" :labelArr="['Yes', 'No']" />
                    <patient-info-criteria :criteria="patientInfo.solidOrgan_multiple" :label="'Solid Organ Transplant'" :labelArr="['Yes', 'No']" />
                    
                    <span v-if="patientInfo.therapy.length > 0" class="summary-criteria"><strong>Other Prior Therapy:</strong> {{therapyRender}}</span>
                  </span>
                </div>
                <!-- disease details -->
                <span v-if="isDiseaseDetailsNotDefault" class="summary-icon mr-2" style="margin-left: 2px"><img alt="disease detail" height="16" src="@/assets/disease_detail.svg" /></span>
                <span>
                  <span v-if="combinedDisease.length > 0" class="summary-criteria"><strong>Diseases: </strong>{{combinedDisease.join(', ')}}</span>
                  <!-- <span v-if="disease.diseaseSelected.length > 0" class="summary-criteria"><strong>Diseases: </strong>{{disease.diseaseSelected.join(', ')}}</span>
                  <span v-if="disease.gyn.length > 0" class="summary-criteria"><strong>Gyn: </strong>{{disease.gyn.join(', ')}}</span>
                  <span v-if="disease.lung.length > 0" class="summary-criteria"><strong>Lung: </strong>{{disease.lung.join(', ')}}</span>
                  <span v-if="disease.solid.length > 0" class="summary-criteria"><strong>Solid: </strong>{{disease.solid.join(', ')}}</span>
                  <span v-if="disease.allDisease.length > 0" class="summary-criteria"><strong>Diseases: </strong>{{disease.allDisease.join(', ')}}</span> -->
                  
                  <!-- biomarkers -->
                  <span v-if="biomarkers.biomarkersSelected.length > 0 || biomarkersMultipleSelected.length > 0" class="summary-criteria">
                    <strong>Biomarkers:</strong> {{biomarkersSelectedRender}}
                  </span>

                  <!-- <patient-info-criteria :criteria="patientInfo.brainCNS_multiple" :label="'Brain/CNS metastases'" :labelArr="['Yes', 'No']" /> -->
                  <patient-info-criteria :criteria="patientInfo.brainCNS_multiple" :label="fieldToLabel('brainCNS_multiple')" :labelArr="['Yes', 'No']" />
                </span>
                <br />

                <!-- test results -->
                <span v-if="!isTestResultDefault" class="summary-icon mr-2"><img alt="test result" height="20" src="@/assets/test_results.svg" /></span>
                <span>
                  <patient-info-criteria v-for="(item, index) in testResultOnlyMultiple" :key="index" :criteria="item.value" :label="item.label" :labelArr="['Yes', 'No']"/>
                  <!-- <patient-info-criteria :criteria="testResults.hiv_multiple" :label="'HIV'" :labelArr="['Yes', 'No']" /> -->
                  <span v-if="testResults.wbc" class="summary-criteria"><strong>WBC (/uL):</strong> {{testResults.wbc}}</span>
                  <span v-if="testResults.platelets" class="summary-criteria"><strong>Platelets (/uL):</strong> {{testResults.platelets}}</span>
                  <span v-if="testResults.performance" class="summary-criteria"><strong>Performance Status:</strong> {{testResults.performance.name}}</span>
                </span>
              </v-col>
            </v-row>
            
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="px-6 py-4 d-flex d-md-none">
      <v-spacer></v-spacer>
      <!-- <v-btn id="new-search2" data-test="new-search" depressed color="outlined" 
        @click="onNewSearch" class="elevation-2 primary-bd">
        <v-icon left color="primary">mdi-notebook-edit </v-icon> REFINE SEARCH
      </v-btn> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PatientInfoCriteria from './PatientInfoCriteria.vue'
import TooltipIcon from './TooltipIcon.vue';
import { criteriaDict } from '@/store/initState'

export default {
  components: { PatientInfoCriteria, TooltipIcon },
  name: 'SummaryHeader',
  data() {
    return {
      summaryOpen: true,
      summaryPanelOpen: 0,
      criteriaDict
    }
  },
  methods: {
    onNewSearch() {
      this.$emit('backToCriteriaTab')
    },
    fieldToLabel(key) {
      return criteriaDict[key]
    }
  },
  computed: {
    ...mapGetters('summaryStore', {
      patientInfo: 'getPatientInfo',
      disease: 'getDisease',
      biomarkers: 'getBiomarkers',
      biomarkersMultipleSelected: 'getBiomarkersMultipleSelected',
      testResults: 'getTestResults',
      isPatientInfoDefault: 'isPatientInfoDefault',
      isTestResultDefault: 'isTestResultDefault',
      isDiseaseDefault: 'isDiseaseDefault',
      isBiomarkersDefault: 'isBiomarkersDefault'
    }),
    geoArrFiltered() {
      return this.patientInfo.geoArr.filter(geo => geo.lat !== '')
    },
    geoRender() {
      let str = ''
      this.geoArrFiltered.forEach((geo, index) => {
        if (index == 0) {
          str += `${geo.city}, ${geo.state}`
        } else {
          str += ` • ${geo.city}, ${geo.state}`
        }
      })
      return str
    },
    therapyRender() {
      return this.patientInfo.therapy.map(item => item.pref_name).join(', ')
    },
    biomarkersSelectedRender() {
      let combinedBiomarkers = [...this.biomarkersMultipleSelected, ...this.biomarkers.biomarkersSelected.map(item => item.biomarker)]
      return combinedBiomarkers.join(', ')
    },  
    isDiseaseDetailsNotDefault() {
      return !(this.isDiseaseDefault && this.isBiomarkersDefault)
    },
    combinedDisease() {
      /* let diseaseModifiersArr = this.disease.diseaseModifiers?.map(mod => {
        return criteriaDict[mod] || [];
      }) */
      return [...this.disease.diseaseSelected, ...this.disease.allDisease, ...this.disease.diseaseModifiers];
    },
    testResultOnlyMultiple() {
      let tempArr = [];
      Object.keys(this.testResults).forEach(key => {
        if (key.includes('multiple')) {
          tempArr.push({label: criteriaDict[key], value: this.testResults[key]})
        }
      })
      return tempArr
    }
  }
}
</script>

<style>

</style>