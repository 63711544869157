let clickHandler = function(el) {
  el.focus();
}

export default {
  install(Vue) {
    Vue.directive('cbFocus', {
      bind(el) {
        let div = el.getElementsByTagName('DIV')[0];
        let input = el.getElementsByTagName('INPUT')[0];
        div.addEventListener('click', clickHandler.bind({}, input))
      },
      unbind(el) {
        let div = el.getElementsByTagName('DIV')[0];
        let input = el.getElementsByTagName('INPUT')[0];
        div.removeEventListener('click', clickHandler.bind({}, input))
      }
    })
  }
}