<template>
	<v-dialog
		v-model="dialog"
		:width="currentStep === 1 ? '600px' : '900px'"
		persistent
	>
		<template v-slot:activator="{ on, attrs }">
			<v-icon
				v-bind="attrs"
				v-on="on"
				size="44"
				class="white--text mr-2"
				aria-label="curated upload"
				role="img"
				aria-hidden="false"
				>mdi-upload</v-icon
			>
		</template>

		<v-card class="pa-0">
			<!-- header -->
			<v-card-title
				class="d-flex justify-space-between align-center py-3 border-bottom"
			>
				<h3>
					{{ currentStep === 1 ? 'Upload Curated Data' : 'Validation Results' }}
				</h3>
				<v-btn
					@click="dialog = false"
					icon
					color="grey"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- body -->
			<v-card-text class="pa-0">
				<v-window v-model="currentStep">
					<!-- upload -->
					<v-window-item :value="1">
						<div
							class="pa-5"
							:class="{ loading: isUploading }"
						>
							<upload-field v-model="selectedFile" />
						</div>

						<!-- footer -->
						<div class="py-3 px-5 border-top">
							<!-- Error Message -->
							<v-alert
								v-if="uploadError"
								type="error"
								dismissible
							>
								{{ uploadError }}
							</v-alert>

							<!-- actions -->
							<div class="d-flex justify-space-between">
								<v-btn
									text
									@click="dialog = false"
								>
									Cancel
								</v-btn>

								<v-btn
									color="primary"
									@click="handleFileUpload"
									:disabled="!selectedFile"
									:loading="isUploading"
									append-icon="mdi-arrow-right"
								>
									Upload & Validate
								</v-btn>
							</div>
						</div>
					</v-window-item>

					<!-- review -->
					<v-window-item :value="2">
						<!-- Display two tables with results -->
						<div class="pa-5">
							<!-- selected file -->
							<div class="mb-1">Selected File:</div>
							<div
								class="mb-5 d-flex align-center justify-start body-1 black--text file"
							>
								<div class="d-flex align-center">
									<v-icon
										color="green"
										class="mr-2"
										>mdi-file-excel</v-icon
									>
									<span class="font-weight-bold">
										{{ selectedFile?.name }}
									</span>
								</div>
							</div>

							<!-- empty expressions -->
							<div class="mb-3 d-flex align-center body-1 black--text">
								<div>Empty Expressions Count:</div>
								<div class="font-weight-bold ml-1">
									{{ emptyExpressionsCount }}
								</div>
							</div>

							<hr class="my-5" />

							<h3 class="mb-3 black--text">Errors</h3>
							<v-data-table
								:items="errorsTableData"
								:headers="errorsHeaders"
							></v-data-table>

							<h3 class="mb-3 black--text">These records will be overridden</h3>
							<v-data-table
								:items="overrideTableData"
								:headers="overrideHeaders"
							></v-data-table>
						</div>

						<!-- footer -->
						<div class="py-3 px-5 border-top">
							<!-- Error Message -->
							<v-alert
								v-if="confirmError"
								type="error"
								dismissible
							>
								{{ confirmError }}
							</v-alert>

							<!-- actions -->
							<div class="d-flex justify-end">
								<v-btn
									text
									@click="currentStep = 1"
									:disabled="isConfirming"
									class="mr-2"
								>
									Back
								</v-btn>
								<v-btn
									color="primary"
									depressed
									@click="confirmUpload"
									:disabled="isConfirming"
								>
									Confirm & Submit
								</v-btn>
							</div>
						</div>
					</v-window-item>
				</v-window>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import { Auth } from 'aws-amplify';
	import axios from 'axios';
	import UploadField from './UploadField.vue';

	export default {
		name: 'CuratedUploadWidget',
		components: { UploadField },
		data() {
			return {
				dialog: false,
				currentStep: 1,
				selectedFile: null,
				presignedUrl: '',
				emptyExpressionsCount: 0,
				errorsTableData: [],
				overrideTableData: [],
				errorsHeaders: [
					// nct id
					{ text: 'NCT ID', value: 'nct_id' },
					// criteria
					{ text: 'Criteria', value: 'criteria' },
					// line
					{ text: 'Line', value: 'line' },
					// message
					{ text: 'Message', value: 'message' },
				],
				overrideHeaders: [
					// nct id
					{ text: 'NCT ID', value: 'nct_id' },
					// criteria
					{ text: 'Criteria', value: 'criteria' },
					// line
					{ text: 'Line', value: 'line' },
					// expression in db
					{ text: 'Expression in DB', value: 'expression_in_db' },
					// new expression
					{ text: 'New Expression', value: 'new_expression' },
					// expression are equal
					{ text: 'Expression are equal', value: 'expression_are_equal' },
				],

				// New loading and error state properties
				isUploading: false,
				uploadError: '',
				isConfirming: false,
				confirmError: '',
			};
		},
		methods: {
			async handleFileUpload() {
				this.isUploading = true;
				this.uploadError = '';
				const base64File = await toBase64(this.selectedFile);
				const mime_type = fileExtension === 'csv' ? 'csv' : 'xlsx';

				const token =
					process.env.VUE_APP_SKIP_AUTH === 'true'
						? `ABCD`
						: `Bearer ${(await Auth.currentSession())
								.getIdToken()
								.getJwtToken()}`;

				// Replace with your test API endpoint
				axios
					.post(
						process.env.VUE_APP_REMOTE === 'true'
							? process.env.ENDPOINT_URL + '/api/v1/validate_curated_for_upload'
							: '/api/v1/' + 'validate_curated_for_upload',
						{
							data: base64File,
							mime_type,
							action: 'validate',
						},
						{
							headers: {
								Authorization: token,
							},
						}
					)
					.then((response) => {
						const data = response.data;
						// Assume response contains presignedUrl, errorsTableData and overrideTableData
						this.presignedUrl = data?.url;
						this.errorsTableData = data?.validation_results?.errors.map(
							(error) => ({
								nct_id: error.nct_id,
								criteria: error.criteria,
								line: error.line,
								message: error.msg,
							})
						);
						this.overrideTableData = data?.validation_results?.existing.map(
							(item) => ({
								nct_id: item.nct_id,
								criteria: item.criteria,
								expression_in_db: item['expression in db'],
								new_expression: item['new expression'],
								expression_are_equal: item['expressions are equal'],
							})
						);
						this.emptyExpressionsCount =
							data?.validation_results?.empty_expression_count;
						this.currentStep = 2;
					})
					.catch((error) => {
						console.error('Error during file submission:', error);
						this.uploadError =
							'An error occurred during file upload. Please try again.';
					})
					.finally(() => {
						this.isUploading = false;
					});
			},
			confirmUpload() {
				this.isConfirming = true;
				this.confirmError = '';

				// Upload the file using the presigned URL
				axios
					.put(this.presignedUrl, this.selectedFile, {
						headers: {
							'Content-Type': this.selectedFile.type,
						},
					})
					.then(() => {
						console.log('File uploaded successfully');
						this.dialog = false;
					})
					.catch((error) => {
						console.error('Error uploading file to presigned URL:', error);
						this.confirmError =
							'An error occurred during file confirmation. Please try again.';
					})
					.finally(() => {
						this.isConfirming = false;
					});
			},
		},
	};

	export const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				// Extract the base64 string from the result
				const base64String = reader.result.split(',')[1];
				resolve(base64String);
			};
			reader.onerror = (error) => reject(error);
		});
</script>

<style scoped>
	.v-window {
		height: 100%;
	}

	.border-bottom {
		border-bottom: 1px solid #e0e0e0;
	}

	.border-top {
		border-top: 1px solid #e0e0e0;
	}

	.loading {
		opacity: 0.5;
		pointer-events: none;
	}

	.file {
		background-color: #f9f9f9;
		padding: 8px 12px;
		border-radius: 4px;
		margin-bottom: 5px;
		border: 1px solid #ddd;
	}
</style>
