<template>
	<v-col
		cols="12"
		lg="4"
	>
		<v-text-field
			data-test="zipcode"
			id="zipcode"
			:label="`Zip ${index + 1}`"
			type="number"
			ref="tf"
			max="99999"
			:loading="loading"
			v-model="zipcode"
			:rules="[zipFormat, validCountry]"
			validate-on-blur
			@input="fetchLatLon"
			@keydown.enter.prevent.stop
		></v-text-field>
	</v-col>
</template>

<script>
	import axios from 'axios';
	import fetchService from '@/services/fetchService';

	export default {
		name: 'ZipCodeWidget',
		props: ['index'],
		data() {
			return {
				country: '',
				doneSearch: true,
				zipcode: '',
				needFetch: true,
				loading: false,
			};
		},
		methods: {
			fetchLatLon(zipcode) {
				if (this.needFetch) {
					if (zipcode.length == 5 && this.doneSearch) {
						this.loading = true;
						//this.doneSearch = false;
						const BingMapsKey =
							'AsVg8t53z1wEyJbak6Oao7Ez0oolR5Z2vPCzK4y3LQ7xOZEfgv40OfsX0PcbXk4J';
						let url = `/get_lat_lon_from_address?address=${zipcode}`;
						//let url = process.env.VUE_APP_SKIP_AUTH === 'true' ? `/api/v1/Locations?query=${zipcode}&key=localKey` : `https://dev.virtualearth.net/REST/v1/Locations?query=${zipcode}&key=${BingMapsKey}`;
						//axios.get(url)
						fetchService.getDataFiles(url).then((res) => {
							let geoData = {
								lat: res.lat,
								lon: res.lon,
								city: res.city,
								state: res.state,
								zipcode: res.postal_code,
							};
							this.loading = false;
							this.doneSearch = true;
							this.country = res.country;

							this.$emit('geoUpdated', {
								geoData,
								index: this.index,
							});

							if (this.country == 'United States' && zipcode.length == 5) {
								this.$emit('zipCodeValid', {
									index: this.index,
									status: true,
								});
							} else {
								this.$emit('zipCodeValid', {
									index: this.index,
									status: false,
								});
							}
						});
					} else {
						if (zipcode == '') {
							this.zipError = '';
							this.$emit('zipCodeValid', {
								index: this.index,
								status: true,
								refresh: true,
							});
						} else {
							this.$emit('zipCodeValid', {
								index: this.index,
								status: false,
							});
						}
					}
				}
				this.needFetch = true;
			},
			reset() {
				this.zipcode = '';
			},
			setGeo(zipcode) {
				this.needFetch = false;
				this.country = 'United States';
				this.zipcode = zipcode;
			},
		},
		computed: {
			zipFormat() {
				const pattern = /^[0-9]{5}(?:-?[0-9]{4})?$/;
				const invalidMsg = 'Zip code format is invalid';
				if (this.zipcode.length > 0) {
					//this.zipError = pattern.test(this.zipcode) ? '' : invalidMsg;
					return pattern.test(this.zipcode) || invalidMsg;
				}
				return true;
			},
			validCountry() {
				if (this.zipcode == '') {
					return true;
				} else {
					return (
						this.country == 'United States' ||
						this.loading == true ||
						'This is not a valid zipcode'
					);
				}
			},
		},
	};
</script>

<style></style>
