<template>
  <div id="alert-widget" v-if="alerts.length > 0">
    <v-menu :close-on-content-click="closeOnContentClick" :value="menuOpened"
      transition="slide-y-transition" offset-y max-width="500" min-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn id="alert-trials" text color="primary" dark v-bind="attrs" v-on="on" 
          @click="menuOpened = true" class="no-ripple" >
          <v-icon size="40" class="white--text"
            aria-label="alert" role="img" aria-hidden="false">mdi-bell</v-icon>
            <div class="chip-notification white--text">{{alerts.length}}</div>
        </v-btn>      
      </template>

      <div id="alert-widget-container" class="mt-1">
        <div class="widget-header">
          <p class="trial-widget-title">My Alert <span>({{alerts.length}})</span></p>
          <p class="mb-0">Alerts will be available for 30 days. Use "
            <v-icon size="24" class="white--text"
            aria-label="star" role="img" aria-hidden="false">mdi-close-circle-outline</v-icon>
            " button to manually remove an alert from this list.</p>
        </div>
        <div class="widget-body">
          <div v-for="alert in alerts" :key="alert.nct_id" class="alert-widget-card">
            <div class="alert-widget-card-title d-flex align-center">
              <v-chip class="mr-2">NEW</v-chip>
              <span>{{alert.date}}</span>
              <v-spacer></v-spacer>
              <!-- <span>Starred on Mar 22, 2022</span> -->
              <v-btn text color="primary" dark class="pa-0 ml-2" @click="toggleStarStatus(trial)">
                <v-icon size="28" aria-label="remove" role="img" aria-hidden="false">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div>
            <div class="alert-widget-card-body">
              <p>{{alert.msg}}<br /><a href="#">alert.nct_id</a></p>
            </div>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
  
</template>

<script>
export default {
  name: 'AlertWidget',
  data() {
    return {
      menuOpened: false,
      closeOnContentClick: false,
      alerts: [
        /* {
          date: 'Mar 22, 2022 03:12 PM',
          msg: 'There is a new trial available for enrollment on Mar. 01, 2022 that you might be interested in. Check it out:',
          nct_id: "NCT01424982",
        },
        {
          date: 'Mar 22, 2022 03:12 PM',
          msg: 'There is a new trial available for enrollment on Mar. 01, 2022 that you might be interested in. Check it out:',
          nct_id: "NCT01424982",
        } */
      ]
    }
  }
}
</script>

<style>

</style>