<template>
  <div>
    <v-dialog content-class="edit-dialog" persistent v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="#8E389B" class="short px-0 no-min-width" v-bind="attrs" v-on="on">
          <v-icon aria-label="Delete Criteria Types" role="img" aria-hidden="false">
            mdi-close-circle-outline
          </v-icon>
        </v-btn>
      </template>

      <v-card class="edit-card">
        <v-icon role="img" aria-hidden="true" class="white--text title-icon">
          mdi-pencil
        </v-icon>

        <div class="btn-close">
          <v-icon aria-label="close dialog" role="img" aria-hidden="false" 
            @click="dialog = false">mdi-close-thick</v-icon>
        </div> 
        
        <v-card-text class="ml-n2 mr-0 pr-2">
          <div class="form-progress-wrapper">
            <v-progress-linear v-if="deleting" indeterminate color="primary" class="mb-8"></v-progress-linear>
          </div>
          
          <div style="line-height: 1.2">
            <p class="text-h6 text-center">Are you sure you want to delete {{item.nct_id}} for this criteria type id: {{item.criteria_type_id}}?</p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="purple darken-1" outlined @click="dialog = false" >CANCEL</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteTrialByType">DELETE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'TrialCriteriaDeleteDialog',
  props: {
    item: { type: Object, required: true},
    type: { type: String, required: true}
  },
  data() {
    return {
      dialog: false,
      deleting: false,
      count: 0
    }
  },
  methods: {
    deleteTrialByType() {
      this.deleting = true;
      this.$store.dispatch('typesStore/deleteTrialByType', {
          nct_id: this.item.nct_id,
          criteria_type_id: this.item.criteria_type_id
        })
        .then(res => {
          this.dialog = false;
          this.deleting = false;
          if (this.type == 'type') {
            this.$store.dispatch('typesStore/fetchTrialByType', {
              criteria_type_id: this.item.criteria_type_id
            });
          } else if (this.type == 'id') {
            this.$store.dispatch('typesStore/fetchTrialById', {
              nct_id: this.item.nct_id
            });
          }
        })
    },
  },
};
</script>

<style></style>
