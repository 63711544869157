<template>
  <div class="d-flex">
    <div class="type-sidebar flex-shrink-0">
      <div class="type-sidebar-title">Title</div>
      <div class="type-sidebar-list">
        <ul>
          <li v-for="type in criteriaTypes" :key="type.criteria_type_id" 
            :class="{selected: currentSelectedTypeId == type.criteria_type_id}"
            @click="onSelectType(type)">{{type.criteria_type_title}}</li>
        </ul>
      </div>
    </div>
    <div class="type-table flex-grow-1">
      <trial-criteria-add-dialog :currentSelectedTypeId="currentSelectedTypeId" />

      <v-data-table id="criteria-types-table" class="sticky-header"
        :headers="byTypeHeader" :items="trialByType"
        :items-per-page="itemsPerPage" :page="page" :sort-by="sortBy" :sort-desc="sortDesc"
        :footer-props="{ 'items-per-page-options': [5, 10, 20, 50, 100, -1] }"
        @pagination="onPaginationChange"
        must-sort
        :loading="loadingTrialByType"
        loading-text="Loading Trial Criteria By Type"
        height="600px"
      >
        <template v-slot:[`item.remove`]="{ item }">
          <trial-criteria-delete-dialog :item="item" type="type" />
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <trial-criteria-edit-dialog :item="item" type="type" />
        </template>
        <template v-slot:[`item.update_date`]="{value}">
          {{value.trim() | moment('timezone', $store.getters['criteriaStore/getTZ'], 'MMM DD, YYYY hh:mm A')}}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TrialCriteriaEditDialog from './dialog/TrialCriteriaEditDialog.vue';
import TrialCriteriaDeleteDialog from './dialog/TrialCriteriaDeleteDialog.vue';
import TrialCriteriaAddDialog from './dialog/TrialCriteriaAddDialog.vue';

export default {
  name: 'TabByType',
  components: { TrialCriteriaEditDialog, TrialCriteriaDeleteDialog, TrialCriteriaAddDialog },
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      sortBy: '',
      sortDesc: true,
      loadingSearch: false,
      currentSelectedTypeId: 0,
    };
  },
  created() {
    if (this.$store.getters['typesStore/getCriteriaTypes'].length == 0) {
      this.$store.dispatch('typesStore/fetchCriteriaTypes')
        .then(res => {
          this.setInit()
        });
    } else {
      this.setInit()
    }
    
  },
  methods: {
    setInit() {
      this.currentSelectedTypeId = this.$store.getters['typesStore/getCriteriaTypes'][0].criteria_type_id
      //fetch trial criteria by type
      this.$store.dispatch('typesStore/fetchTrialByType', {
        criteria_type_id: this.currentSelectedTypeId
      })
    },
    onPaginationChange(e) {
      this.page = e.page;
      this.itemsPerPage = e.itemsPerPage;
    },
    onSortBy(e) {
      this.sortBy = e;
    },
    onSortDesc(e) {
      this.sortDesc = e;
    },
    onSelectType(type) {
      this.currentSelectedTypeId = type.criteria_type_id;
      this.$store.dispatch('typesStore/fetchTrialByType', {
        criteria_type_id: this.currentSelectedTypeId
      })
    }
  },
  computed: {
    ...mapGetters('typesStore', {
      loadingTrialByType: 'getLoadingTrialByType',
      typesHeader: 'getTypesHeader', //remove later
      byTypeHeader: 'getByTypeHeader',
      trialByType: 'getTrialByType',
      criteriaTypes: 'getCriteriaTypes' //for left sidebar
    }),
  },
};
</script>

<style>
#app.v-application #criteria-types-table .v-data-table-header tr > th:nth-child(1) {
    padding-left: 16px;
}
#app.v-application #criteria-types-table .v-data-table-header tr > th:last-child {
    padding-right: 16px;
}
</style>
