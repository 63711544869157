export default {
	state: {
		videoDialog: false,
	},
	getters: {
		getVideoDialog: (state) => state.videoDialog,
	},
	mutations: {
		UPDATE_VIDEO_DIALOG(state, status) {
			state.videoDialog = status;
		},
	},
};
