<template>
  <div>
    <div id="criteria-type-wrapper">
      <div id="btn-back-to-home">
        <router-link class="short" color="primary" text to="/home">
          &#x25C0; &nbsp; Back to home
        </router-link>
      </div>
      <div id="btn-create-criteria-type">
        <v-btn class="short mt-6" color="primary" @click="openTypesDialog">
          CREATE CRITERIA TYPE
        </v-btn>
      </div>
      <!-- tabs -->
      <div id="top-tabs">
        <v-tabs class="pt-10" height="42" hide-slider 
          v-model="sectionTabIndex" >
          <v-tab data-test="byTypes">Trial Criteria By Type</v-tab>
          <v-tab data-test="byId">Criteria Per Trial</v-tab>
        </v-tabs>
      </div>
      <v-container fluid class="px-0 py-0">
        <!-- tab content -->
        <v-tabs-items v-model="sectionTabIndex">
          <!-- trial criteria by type -->
          <v-tab-item>
            <tab-by-type />
          </v-tab-item>

          <!-- criteria per trial -->
          <v-tab-item>
            <tab-by-id />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </div>

    <types-dialog :typesDialog="typesDialogOpen" @typesDialogClosed="typesDialogOpen = false" />

    <!-- <idle-dialog v-if="isIdle" /> -->
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import TabByType from '@/components/TabByType';
import TabById from '@/components/TabById.vue';
import TypesDialog from '@/components/dialog/TypesDialog.vue';

export default {
  name: 'Admin',
  components: {
    TypesDialog,
    TabByType,
    TabById,
  },
  data() {
    return {
      dialog: true,
      sectionTabIndex: 0,
      typesDialogOpen: false,
    };
  },
  created() {
  },
  methods: {
    openTypesDialog() {
      this.typesDialogOpen = true;
    }
  },
};
</script>

<style lang="scss">
#criteria-type-wrapper {
  position: relative;
  #btn-create-criteria-type {
    position: absolute;
    top: 0;
    right: 32px;
    z-index: 1;
  }
  #btn-back-to-home {
    position: absolute;
    top: 10px;
    left: 32px;
    z-index: 1;
    a {
      text-decoration: none;
    }
  }
}
</style>
