<template>
  <span>
    <v-btn color="#8E389B" text class="short px-0 no-min-width" 
      @click="deleteSearchItem" >
      <v-icon size="24" aria-label="bookmark" role="img" aria-hidden="false" >
        mdi-close-circle-outline</v-icon>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'DeleteSearch',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteSearchItem() {
      this.$store.dispatch('searchStore/deleteSearchItem', this.item)
    }
  }
};
</script>

<style></style>
