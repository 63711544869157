<template>
  <div class="flex-grow-1 pl-1 pl-md-4 pr-md-3">
    <!-- expansion items -->
    <div class="pr-1" id="list-view-progress-wrapper">
      <v-progress-linear v-show="loadingNCTId" indeterminate color="primary"></v-progress-linear>
    </div>
    <div id="expansion-items-wrapper" class="pr-1 pr-mb-6 pb-4" v-if="!loadingNCTId">
      <v-expansion-panels multiple>
        <list-view-item  v-for="trial in filteredTrials.slice(0, this.numOfTrialsLoad)" :key="trial.nct_id" :trial="trial" />
      </v-expansion-panels>
    </div>

    <div id="lazy-load-wrapper" v-intersect.quiet="onIntersect"></div>
  </div>
</template>

<script>
import { mapGetters} from 'vuex';
import ListViewItem from './ListViewItem.vue';

export default {
  name: 'ListView',
  data() {
    return {
      numOfTrialsLoad: 10,
      loadMoreItem: true
    }
  },
  components: { ListViewItem },
  methods: {
    onIntersect(entries, observer) {
      if (this.loadMoreItem) {
        let newNumOfTrialsLoad = this.numOfTrialsLoad + 10;
        newNumOfTrialsLoad = newNumOfTrialsLoad > this.filteredTrials.length ? newNumOfTrialsLoad = Math.max(10, this.filteredTrials.length) : newNumOfTrialsLoad
        this.numOfTrialsLoad = newNumOfTrialsLoad
      }
    }
  },
  computed: {
    ...mapGetters({
      loadingNCTId: 'trialsStore/getLoadingNCTId',
      filteredTrials: 'trialsStore/getFilteredTrials',
    }),
    renderedTrials() {
      return this.filteredTrials.slice(0, this.numOfTrialsLoad)
    }
  },
  updated() {
    setTimeout(() => {
      this.loadMoreItem = true
    }, 200);
  }
}
</script>

<style lang="scss">

</style>