import { isEqual, cloneDeep } from 'lodash';
import { buildJSONObj, buildFlatTree } from '@/store/buildTree'
import { defaultDisease } from '@/store/initState'
//import gynData from '@/mockData/dt_gyn_tree.json'
//import lungData from '@/mockData/dt_lung_tree.json'
//import solidData from '@/mockData/dt_solid_tree.json'
import fetchService from '@/services/fetchService';

export default {
  state: {
    //gynData: buildJSONObj(gynData),
    //gynFlatData: buildFlatTree(gynData),
    //lungData: buildJSONObj(lungData),
    //lungFlatData: buildFlatTree(lungData),
    //solidData: buildJSONObj(solidData),
    //solidFlatData: buildFlatTree(solidData),
    gynData: {},
    gynFlatData: [],
    lungData: {},
    lungFlatData: [],
    solidData: {},
    solidFlatData: [],
    disease: cloneDeep(defaultDisease),

    allDiseaseData: {},
    allDiseaseFlatData: []
  },
  getters: {
    getGynData: state => state.gynData,
    getLungData: state => state.lungData,
    getSolidData: state => state.solidData,
    getAllDiseaseData: state => state.allDiseaseData,

    getGynFlatData: state => state.gynFlatData,
    getLungFlatData: state => state.lungFlatData,
    getSolidFlatData: state => state.solidFlatData,
    getAllDiseaseFlatData: state => state.allDiseaseFlatData,

    getDisease: state => state.disease,
  },
  mutations: {
    ADD_DISEASE(state, payload) {
      if (payload.type == 'diseaseSelected') {
        payload.diseaseName.forEach(name => {
          if (state.disease[payload.type].indexOf(name) == -1) {
            state.disease[payload.type].push(name)
          }
        })
      } else {
        if (state.disease[payload.type].indexOf(payload.diseaseName) == -1) {
          state.disease[payload.type].push(payload.diseaseName)
        }
      }
    },
    REMOVE_DISEASE(state, payload) {
      state.disease[payload.type].splice(payload.diseaseIndex, 1)
    },
    RESET_DISEASE(state) {
      state.disease = cloneDeep(defaultDisease)
    },
    SET_DEMO(state, data) {
      state.disease = {
        ...state.disease,
        ...data
      }
    },
    UPDATE_DISEASE_MODIFIERS(state, modifiersSelection) {
      state.disease.diseaseModifiers = modifiersSelection
    }
  },
  actions: {
    addDisease({commit}, payload) {
      commit('ADD_DISEASE', payload)
    },
    removeDisease({commit}, payload) {
      commit('REMOVE_DISEASE', payload)
    },
    reset({commit}) {
      commit('RESET_DISEASE')
    },
    setDemo({commit}, data) {
      commit('SET_DEMO', data)
    },
    fetchDiseaseTree({state}, payload) {
      return new Promise((resolve, reject) => {
        fetchService.postDataFiles('disease_tree_data', {
          ccode: payload.ccode
        })
          .then(response => {
            state[`${payload.disease}Data`] = buildJSONObj(response),
            state[`${payload.disease}FlatData`] = buildFlatTree(response)
            resolve(response)
          })
          .catch(error => {
            console.log('error', error)
            reject(error)
          })
      })
    }
  },
};
