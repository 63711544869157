<template>
  <v-dialog v-model="dialog" width="90%" persistent>
    <v-card>
      <v-card-title>
        Criteria Types
        <v-spacer></v-spacer>
        <div class="btn-close">
          <v-icon @click="hideDialog" aria-hidden="false" aria-label="close dialog" role="img">
            mdi-close-thick
          </v-icon>
        </div>
      </v-card-title>

      <v-card-text class="ml-n2 mr-0 pr-2">
        <types-create-dialog />
        <criteria-types />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Auth } from 'aws-amplify';
import CriteriaTypes from '../CriteriaTypes.vue';
import TypesCreateDialog from './TypesCreateDialog.vue';

export default {
  name: 'TypesDialog',
  components: { CriteriaTypes, TypesCreateDialog },
  props: {
    typesDialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    this.dialog = this.typesDialog
  },
  methods: {
    hideDialog() {
      this.dialog = false;
      this.$emit('typesDialogClosed')
    },
  },
  watch: {
    typesDialog() {
      this.dialog = this.typesDialog
    }
  }
};
</script>

<style></style>
