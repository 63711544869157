export default {
  install(Vue) {
    Vue.directive('tfAttrs', {
      bind(el, binding) {
        let input = el.getElementsByTagName('INPUT')[0];
        if (binding.arg == 'autocomplete') {
          input.setAttribute('autocomplete', binding.expression)
        }
      },
    });
  },
};
