<template>
  <div class="list-view-item-side">
    <p class="mb-4"><span class="font-weight-bold">PHASES</span><br /> {{trial.phase}}</p>
    <p class="mb-4"><span class="font-weight-bold">DISEASE(S) OF FOCUS</span><br /> {{trial.disease_names_lead}}</p>
    <p class="mb-4"><span class="font-weight-bold">ALL DISEASES</span><br /> {{trial.disease_names}}</p>

    <template v-for="(value, name) in trial">
      <p v-if="name.includes('_text')" :key="name" class="mb-2 mb-md-4">
        <span class="font-weight-bold">{{mapNameToLabel(name).toUpperCase().replace('TEXT', '')}}</span><br />
        {{value == null ? "" : value}}
      </p>
    </template>
    <!-- Lead org: -->
    <p class="mb-2 mb-md-4">
      <span class="font-weight-bold">LEAD ORGANIZATION</span><br /> {{trial.lead_org}}
    </p>
    <!-- sex / gender -->
    <p class="mb-2 mb-md-4">
      <span class="font-weight-bold">SEX</span><br /> {{trial.gender}}
    </p>
    <!-- age -->
    <p class="mb-2 mb-md-4">
      <span class="font-weight-bold">MIN AGE</span><br /> {{trial.min_age_in_years}}
    </p>
    <p class="mb-2 mb-md-4">
      <span class="font-weight-bold">MAX AGE</span><br /> {{trial.max_age_in_years}}
    </p>
    <!-- Study Source -->
    <p class="mb-2 mb-md-4">
      <span class="font-weight-bold">STUDY SOURCE</span><br /> {{trial.study_source}}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ListViewItemSide',
  props: {
    trial: {
      type: Object,
      required: true
    }
  },
  methods: {
    mapNameToLabel(name) {
      return this.trialsHeader.find(header => header.value == name).text
    },
  },
  computed: {
    trialsHeader() {
      return this.$store.getters['trialsStore/getTrialsHeader']
    },
  }
}
</script>

<style>

</style>