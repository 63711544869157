<template>
  <div class="flex-grow-1 pl-1 pl-md-4 pr-md-3">
    <div id="expansion-items-wrapper" class="pr-1 pr-mb-6 pb-4">
      <v-expansion-panels multiple>
        <share-list-view-item  v-for="trial in trials.slice(0, this.numOfTrialsLoad)" :key="trial.nct_id" 
          :trial="trial" :trialsHeader="trialsHeader" />
      </v-expansion-panels>
    </div>

    <div id="lazy-load-wrapper" v-intersect.quiet="onIntersect"></div>
  </div>
</template>

<script>
import ShareListViewItem from './ShareListViewItem.vue';

export default {
  name: 'ShareListView',
  props: {
    trials: { type: Array, required: true },
    trialsHeader: { type: Array, required: true }
  },
  data() {
    return {
      numOfTrialsLoad: 10,
      loadMoreItem: true,
    }
  },
  components: { ShareListViewItem },
  methods: {
    onIntersect(entries, observer) {
      if (this.loadMoreItem) {
        let newNumOfTrialsLoad = this.numOfTrialsLoad + 10;
        newNumOfTrialsLoad = newNumOfTrialsLoad > this.trials.length ? newNumOfTrialsLoad = Math.max(10, this.trials.length) : newNumOfTrialsLoad
        this.numOfTrialsLoad = newNumOfTrialsLoad
      }
    }
  },
  computed: {
    renderedTrials() {
      return this.trials.slice(0, this.numOfTrialsLoad)
    }
  },
  updated() {
    setTimeout(() => {
      this.loadMoreItem = true
    }, 200);
  }
}
</script>

<style lang="scss">

</style>