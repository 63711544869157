import camelCase from 'lodash/camelCase'
const requireModule = require.context(
  // Search for files in the current directory.
  '.',
  // Search for files in subdirectories.
  true,
  // Include any .js files that are not this file or a unit test.
  /^((?!index|\.unit\.).)*\.js$/
)

const modules = {}

requireModule.keys().forEach((fileName) => {
  if (fileName === './index.js') return

  const moduleName = camelCase(
    fileName.replace(/(\.\/|\.js)/g, '')
  )
  
  const moduleDefinition = requireModule(fileName).default || requireModule(fileName)
  modules[moduleName] = {
    namespaced: true,
    ...moduleDefinition
  }
})

export default modules
