<template>
  <span class="btn-star">
    <v-btn text color="primary" class="short px-0 no-min-width" @click="$loadSavedCriteria(item.search_info, item.session_uuid)">
      <v-icon size="24" class="primary--text" 
        aria-label="bookmark" role="img" aria-hidden="false" >
          mdi-magnify</v-icon><span class="d-md-none">VIEW RESULTS</span>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'SearchCriteria',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  inject: ['$loadSavedCriteria'], //provide from Home
};
</script>

<style></style>
