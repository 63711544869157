<template>
  <div>
    <v-data-table id="criteria-types-table" class="sticky-header"
      :headers="typesHeader" :items="criteriaTypes"
      :items-per-page="itemsPerPage" :page="page" :sort-by="sortBy" :sort-desc="sortDesc"
      :footer-props="{ 'items-per-page-options': [5, 10, 20, 50, 100, -1] }"
      @pagination="onPaginationChange"
      must-sort
      :loading="loadingTypes"
      loading-text="Loading Criteria Types"
      height="500px"
    >
      <template v-slot:[`item.remove`]="{ item }">
        <types-delete-dialog :item="item" />
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <types-edit-dialog :item="item" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TypesEditDialog from './dialog/TypesEditDialog.vue';
import TypesDeleteDialog from './dialog/TypesDeleteDialog.vue';

export default {
  name: 'CriteriaTypes',
  components: { TypesEditDialog, TypesDeleteDialog },
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      sortBy: '',
      sortDesc: true,
      loadingSearch: false,
    };
  },
  created() {
    this.$store.dispatch('typesStore/fetchCriteriaTypes');
  },
  methods: {
    onPaginationChange(e) {
      this.page = e.page;
      this.itemsPerPage = e.itemsPerPage;
    },
    onSortBy(e) {
      this.sortBy = e;
    },
    onSortDesc(e) {
      this.sortDesc = e;
    },
  },
  computed: {
    ...mapGetters({
      loadingTypes: 'typesStore/getLoadingTypes',
    }),
    typesHeader() {
      return this.$store.getters['typesStore/getTypesHeader'];
    },
    criteriaTypes() {
      return this.$store.getters['typesStore/getCriteriaTypes'];
    },
  },
};
</script>

<style>
#app.v-application #criteria-types-table .v-data-table-header tr > th:nth-child(1) {
    padding-left: 16px;
}
#app.v-application #criteria-types-table .v-data-table-header tr > th:last-child {
    padding-right: 16px;
}
</style>
