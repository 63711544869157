<template>
  <div class="d-flex">
    <div id="all-nctid-sidebar" class="type-sidebar flex-shrink-0">
      <div class="type-sidebar-title">NCT ID</div>
      <div>
        <v-text-field label="ID" v-model="searchNctId" prefix="NCT" single-line clearable></v-text-field>
      </div>
      <div class="type-sidebar-list">
        <ul>
          <li v-for="nctid in filterByCurrentNctId" :key="nctid" 
            :class="{selected: currentNctId == nctid}"
            @click="onSelectId(nctid)">{{nctid}}</li>
        </ul>
      </div>
    </div>
    <div class="type-table flex-grow-1">
      <trial-criteria-add-dialog  :currentNctId="currentNctId" />

      <v-data-table id="criteria-types-table" class="sticky-header"
        :headers="byTypeHeader" :items="trialById"
        :items-per-page="itemsPerPage" :page="page" :sort-by="sortBy" :sort-desc="sortDesc"
        :footer-props="{ 'items-per-page-options': [5, 10, 20, 50, 100, -1] }"
        @pagination="onPaginationChange"
        must-sort hide-default-footer
        :loading="loadingTrialById"
        loading-text="Loading Trial Criteria By NCT ID"
        height="600px"
      >
        <template v-slot:[`item.remove`]="{ item }">
          <trial-criteria-delete-dialog :item="item" type="id" />
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <trial-criteria-edit-dialog :item="item" type="id" />
        </template>
        <template v-slot:[`item.update_date`]="{value}">
          {{value.trim() | moment('timezone', $store.getters['criteriaStore/getTZ'], 'MMM DD, YYYY hh:mm A')}}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TrialCriteriaEditDialog from './dialog/TrialCriteriaEditDialog.vue';
import TrialCriteriaDeleteDialog from './dialog/TrialCriteriaDeleteDialog.vue';
import TrialCriteriaAddDialog from './dialog/TrialCriteriaAddDialog.vue';

export default {
  name: 'TabById',
  components: { TrialCriteriaEditDialog, TrialCriteriaDeleteDialog, TrialCriteriaAddDialog },
  data() {
    return {
      page: 1,
      itemsPerPage: 20,
      sortBy: '',
      sortDesc: true,
      currentNctId: '',
      searchNctId: '',
    };
  },
  created() {

  },
  methods: {
    onPaginationChange(e) {
      this.page = e.page;
      this.itemsPerPage = e.itemsPerPage;
    },
    onSortBy(e) {
      this.sortBy = e;
    },
    onSortDesc(e) {
      this.sortDesc = e;
    },
    onSelectId(nctid) {
      this.currentNctId = nctid;
      this.$store.dispatch('typesStore/fetchTrialById', {
        nct_id: this.currentNctId
      })
    }
  },
  computed: {
    ...mapGetters('typesStore', {
      loadingTrialById: 'getLoadingTrialById',
      byTypeHeader: 'getByTypeHeader', //shared with byId and byType
      trialById: 'getTrialById',
      allNctId: 'getAllNctId' //for left sidebar
    }),
    filterByCurrentNctId() {
      if (this.searchNctId !== '') {
        return this.allNctId.filter(id => {
          return id.includes(this.searchNctId)
        })
      }
      return this.allNctId;
    }
  },
};
</script>

<style>
#app.v-application #criteria-types-table .v-data-table-header tr > th:nth-child(1) {
    padding-left: 16px;
}
#app.v-application #criteria-types-table .v-data-table-header tr > th:last-child {
    padding-right: 16px;
}
</style>
