<template>
  <div>
    <div class="btn-star btn-list-item-actions" v-if="starStatus == false">
      <v-btn text block color="lime darken-2" class="short px-0 no-min-width" 
        @click="toggleStarStatus" >
        <v-icon size="20" class="lime--text text--darken-4" 
          aria-label="star" role="img" aria-hidden="true" >
          mdi-bookmark</v-icon> SAVE
      </v-btn>
    </div>

    <div v-else class="btn-star btn-list-item-actions selected">
      <v-btn text block color="grey--text text--darken-4" class="short px-0 no-min-width" 
        @click="toggleStarStatus">
        <v-icon size="20" class="grey--text text--darken-4"
          aria-label="star" role="img" aria-hidden="true"
          >mdi-bookmark</v-icon> SAVED
      </v-btn>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'StarBox',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleStarStatus() {
      this.$store.dispatch('trialsStore/toggleStarStatus', this.item);
    },
  },
  computed: {
    starStatus() {
      //return this.$store.getters['trialsStore/getStarStatus'](this.item.id - 1)
      return this.item.starred;
    },
  },
};
</script>

<style></style>
