<template>
  <div class="filter-content">
    <div class="filter-header d-flex align-center">
      <div>
        <v-icon>mdi-tune</v-icon> FILTER BY
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn text color="primary" class="short mr-4 small">RESET ALL</v-btn> -->
    </div>
    <div style="font-size: 14px; line-height: 16px; padding: 5px 25px 0 0;">To further refine your results by location or cancer center, please select from the additional filters below.</div>
    <div id="result-filter-list" class="filter-form-wrapper">
      <!-- distance -->
      <div class="form-group mt-2">
        <zip-distance  @updateZipcodesValid="updateZipcodesValid" />
        <!-- <v-row>
          <v-col id="distance-wrapper" cols="12" class="pb-0" :class="{'sh-details': disableDistance}">
            <v-text-field id="distance" label="Distance" type="number" min="0" suffix="mi"
              :disabled="disableDistance" :hint="disableDistance ? 'Please enter zipcode in search criteria' : ''" persistent-hint
              :rules="[rules.distanceFormat]"
              v-model="distance" @input="d_getTrialsForDistanceArr">
            </v-text-field>
          </v-col> 
        </v-row> -->
      </div>
      <!-- cancer center -->
      <div id="cancer-center-filter" class="chip-multilines form-group pt-1 mb-0">
        <v-autocomplete id="cancer-center-search" :items="cancerCenterData" label="Cancer Center" 
          item-text="name" item-value="name"
          multiple clearable chips small-chips
          v-model="cancerCenter"
          @change="getTrialsForCancerCenter">
          <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
              @click:close="updateCancerCenter(data.item.name)">
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <!-- participant attribute -->
      <div id="participant-attr-filter" class="chip-multilines form-group pt-1 mb-0">
        <v-autocomplete id="therapy-search" :items="participantAttrData" label="Participant Attributes" 
          multiple clearable chips small-chips
          v-model="filterSelections.participantAttr" item-text="label" item-value="field_name"
          ref="participantDD">
          <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" close
              @click="data.select"
              @click:close="updateParticipantAttr(data.item)"
            >
              {{ data.item.label }}
            </v-chip>
          </template>
          <template v-slot:prepend-item>
            <v-btn small color="primary" class="mx-4 my-2" @click="selectAllParticipantAttr">Select All</v-btn>
          </template>
        </v-autocomplete>
      </div>
      <!-- search type -->
      <div id="search-type" class="form-group">
        <v-row>
          <v-col cols="12" class="pb-0">
            <div class="group-label">Search Type
              <v-tooltip right max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey darken-1" size="18" dark v-bind="attrs" v-on="on" class="btn-group-tooltip-icon">
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Focused means that the trials shown are specifically studying the disease you selected (example: lung cancer search only returns lung cancer trials). General means that the trials shown are also looking at other diseases - select "General" to view additional trials.</span>
            </v-tooltip>
            </div>
            <v-radio-group hide-details="" v-model="filterSelections.searchType" class="pt-0 mt-2">
              <v-radio label="Focused" :value="0" v-rb-focus></v-radio>
              <v-radio label="General" :value="1" v-rb-focus></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>
      <!-- phase -->
      <div id="phase-filter" class="chip-multilines form-group pt-1 mb-0">
        <v-autocomplete id="phase-search" :items="phasesData" label="Phases" 
          multiple clearable chips small-chips
          v-model="filterSelections.phases" item-text="label" item-value="value">
          <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" 
               @click:close="updatePhases(data.item)">
              {{ data.item.label }}
            </v-chip>
          </template>
        </v-autocomplete>
      </div>

      <!-- additional filters -->
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header dark color="primary" class="white--text">
            Additional Filters
            <template v-slot:actions>
              <v-icon color="white">
                mdi-chevron-down
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- va / nih -->
            <!-- <div id="vanih-attr-filter" class="chip-multilines form-group pt-6 mb-0">
              <v-autocomplete id="va-nih-search" :items="vaNIHData" label="VA or NIH CC Sites" 
                multiple clearable chips small-chips
                v-model="filterSelections.vaNIH" item-text="label" item-value="value">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" 
                    @click:close="updateVaNIH(data.item)">
                    {{ data.item.label }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </div> -->
            <!-- study source -->
            <div id="study-source-filter" class="chip-multilines form-group pt-1 mb-0">
              <v-autocomplete id="va-nih-search" :items="studySourceData" label="Study Source" 
                multiple clearable chips small-chips
                v-model="filterSelections.studySource" item-text="label" item-value="value">
                <template v-slot:selection="data">
                  <v-chip :input-value="data.selected" @click:close="updateStudySource(data.item)" 
                    @click="data.select" close v-bind="data.attrs">
                    {{ data.item.label }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </div>
            <!-- lead orgs -->
            <div id="lead-orgs-filter" class="chip-multilines form-group pt-1 mb-0">
              <v-autocomplete id="lead-orgs-search" :items="leadOrgsData" label="Lead Organization" 
                multiple clearable chips small-chips
                v-model="leadOrg"
                @change="getTrialsForLeadOrg">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                    @click:close="updateLeadOrg(data.item)">
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { debounce, cloneDeep } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import ZipDistance from './ZipDistance.vue';

export default {
  components: { ZipDistance },
  name: 'ResultsFilter',
  data() {
    return {
      distance: '',
      cancerCenter: [],
      leadOrg: [],
      filterSelections: null,

      phasesData: [
        {label: 'I', value: 'I'},
        {label: 'II', value: 'II'},
        {label: 'III', value: 'III'},
        {label: 'IV', value: 'IV'},
      ],
      vaNIHData: [
        {label: 'VA', value: 'va_matches'},
        {label: 'NIH CC', value: 'nih_cc_matches'}
      ],
      studySourceData: [
        {label: 'National', value: 'National'},
        {label: 'Institutional', value: 'Institutional'},
        {label: 'Externally Peer Reviewed', value: 'Externally Peer Reviewed'},
        {label: 'Industrial', value: 'Industrial'}
      ],
      d_getTrialsForDistance: null,
      rules: {
        distanceFormat: value => {
          if (value != '') {
            return value > 0 || 'Distance must be greater than 0'
          } else {
            return true
          }
        }
      }
    }
  },
  created() {
    this.d_getTrialsForDistance = debounce(this.getTrialsForDistance, 1000)
    this.d_getTrialsForDistanceArr = debounce(this.getTrialsForDistanceArr, 1000)
    this.filterSelections = this.$store.getters['trialsStore/getFilterSelections']
  },
  methods: {
    ...mapActions('trialsStore', {
      t_fetchTrialsForDistance: 'fetchTrialsForDistance',
      t_fetchTrialsForDistanceArr: 'fetchTrialsForDistanceArr',
      t_fetchTrialsForCancerCenter:'fetchTrialsForCancerCenter',
      t_fetchTrialsForLeadOrg:'fetchTrialsForLeadOrg',
      t_updateFilterSelections:'updateFilterSelections'
    }),
    updateCancerCenter(item) {
      const index = this.cancerCenter.indexOf(item);
      if (index >= 0) this.cancerCenter.splice(index, 1);
      //this.$store.dispatch('trialsStore/fetchTrialsForCancerCenter', this.cancerCenter);
      this.getTrialsForCancerCenter()
    },
    updateLeadOrg(item) {
      const index = this.leadOrg.indexOf(item);
      if (index >= 0) this.leadOrg.splice(index, 1);
      this.getTrialsForLeadOrg()
    },
    updateParticipantAttr(item) {
      const index = this.filterSelections.participantAttr.indexOf(item.field_name);
      if (index >= 0) this.filterSelections.participantAttr.splice(index, 1);
    },
    updatePhases(item) {
      const index = this.filterSelections.phases.indexOf(item.value);
      if (index >= 0) this.filterSelections.phases.splice(index, 1);
    },
    selectAllParticipantAttr() {
      this.filterSelections.participantAttr = [...this.participantAttrData.map(item => item.field_name)];
      this.$refs.participantDD.blur();
    },
    updateVaNIH(item) {
      const index = this.filterSelections.vaNIH.indexOf(item.value);
      if (index >= 0) this.filterSelections.vaNIH.splice(index, 1);
    },
    updateStudySource(item) {
      const index = this.filterSelections.studySource.indexOf(item.value);
      if (index >= 0) this.filterSelections.studySource.splice(index, 1);
    },
    getTrialsForDistance() {
      this.t_fetchTrialsForDistance({
        geo: this.geo,
        distance: parseFloat(this.distance)
      });
    },
    getTrialsForDistanceArr() {
      this.t_fetchTrialsForDistanceArr({
        distance: parseFloat(this.distance),
        geoArr: this.geoArr.filter(geo => geo.lat !== '').map(geo => {
          return {
            ...geo,
            distance: this.distance
          }
        })
      });
    },
    getTrialsForCancerCenter() {
      this.t_fetchTrialsForCancerCenter({
        cancer_centers: this.cancerCenter
      })
    },
    getTrialsForLeadOrg() {
      this.t_fetchTrialsForLeadOrg({
        lead_orgs: this.leadOrg
      })
    },
    updateZipcodesValid(e) {
      this.$emit('zipcodeValid', e)
    },
  },
  computed: {
    ...mapGetters({
      cancerCenterData: 'trialsStore/getCancerCenterData',
      leadOrgsData: 'trialsStore/getLeadOrgsData',
      participantAttrData: 'trialsStore/getParticipantAttrData',
      geo: 'criteriaStore/getGeo',
      geoArr: 'criteriaStore/getGeoArr'
    }),
    disableDistance() {
      return this.geoArr.filter(geo => geo.lat !== '').length == 0
    }
  },
  watch: {
    filterSelections: {
      deep: true,
      handler() {
        this.$emit('filterChanged');
        this.t_updateFilterSelections(this.filterSelections)
      }
    }
  }
}
</script>

<style>

</style>