<template>
  <div>
    <div class="trials-info mb-4">
      <div class="matching-trials"><strong>{{recentCriteria.length}}</strong> Recent Searches found</div>
      <div>Your recent searches will be available for <strong> 30 days</strong>.</div>
    </div>
    <v-data-table
      id="search-data-table" class="sticky-header mobile-wrapper d-none d-md-block"
      :headers="criteriaHeader"
      :items="recentCriteria"
      :items-per-page="itemsPerPage" :page="page" :sort-by="sortBy" :sort-desc="sortDesc"
      :footer-props="{'items-per-page-options':[5, 10, 20, 50, 100, -1]}"
      @pagination="onPaginationChange" must-sort
      :loading="loadingRecentCriteria"
      loading-text="Loading Search"
      height="500px"
    >
        <template v-slot:[`item.remove`]="{item}">
          <delete-search :item="item" />
        </template>
        <template v-slot:[`item.edit`]="{item}">
          <search-edit :search="item" />
        </template>
        <template v-slot:[`item.search_info`]="{value}">
          <selected-criteria :criteriaStr="value" />
        </template>
        <template v-slot:[`item.search`]="{item}">
          <search-criteria :item="item" />
        </template>
        <template v-slot:[`item.session_date`]="{value}">
          {{value.trim() | moment('timezone', $store.getters['criteriaStore/getTZ'], 'MMM DD, YYYY hh:mm A')}}
          <!-- {{$moment.utc(value).local().format('MMM DD, YYYY hh:mm A')}} -->
        </template>
    </v-data-table>
    <div class="recent-searches-card-wrapper d-md-none">
      <div class="text-center mt-16" v-if="loadingRecentCriteria">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else>
        <div class="recent-searches-card elevation-2 mb-6" 
          v-for="(item, index) in recentCriteria" :key="index">
          <div class="px-4 pt-4">
            <div class="prop-title">Search Name</div>
            <div class="prop-value">{{item.search_name}}</div>
            <div class="prop-title">Search Date/Time</div>
            <div class="prop-value">{{item.session_date.trim() | moment('timezone', $store.getters['criteriaStore/getTZ'], 'MMM DD, YYYY hh:mm A')}}</div>
            <div class="prop-title">Search Criteria</div>
            <div class="prop-value">
              <selected-criteria :criteriaStr="item.search_info" />
            </div>
          </div>
          <div class="btn-actions-wrapper d-flex">
            <div>
              <v-btn text color="#8E389B" @click="deleteSearchItem(item)"> 
                <v-icon aria-label="Edit Search Name" role="img" aria-hidden="true"
                  >mdi-close-circle-outline</v-icon> REMOVE 
              </v-btn>
            </div>
            <div>
              <search-edit :search="item" />
            </div>
            <div>
              <search-criteria :item="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters} from 'vuex';
import SelectedCriteria from './SelectedCriteria.vue';
import SearchCriteria from './SearchCriteria.vue';
import SearchEdit from './SearchEdit.vue';
import DeleteSearch from './DeleteSearch.vue';

export default {
  components: { SelectedCriteria, SearchCriteria, SearchEdit, DeleteSearch },
  name: 'RecentSearch',
  data() {
    return {
      //criteriaStr: '{"patientInfo":{"sex_multiple":1,"age":"55","ageUnit":"Years","geoArr":[{"lat":41.99173355102539,"lon":-87.66214752197266,"city":"Chicago","state":"IL","zipcode":"60660"},{"lat":34.08138656616211,"lon":-118.39927673339844,"city":"Beverly Hills","state":"CA","zipcode":"90210"},{"lat":"","lon":"","city":"","state":"","zipcode":""}],"chemo_multiple":1,"radiotherapy_multiple":1,"surgery_multiple":0,"therapy":[{"code":"C116377","pref_name":"Osimertinib"}],"brainCNS_multiple":0,"zipError":""},"disease":{"diseaseSelected":["Anal Cancer","Anal Adenocarcinoma"],"gyn":["Female Reproductive System Carcinoma","Vulvar Cancer"],"lung":["Bronchogenic Lung Cancer","Lung Cancer by  Stage"],"solid":["Extracranial Solid Tumor","Metastatic Extracranial Malignant Solid Neoplasm"],"allDisease":["Extracranial Solid Tumor","Metastatic Extracranial Malignant Solid Neoplasm"]},"biomarkers":{"egfr_multiple":0,"alk_multiple":1,"ros1_multiple":0,"nras_multiple":1,"kras_multiple":0,"hras_multiple":1,"her2Neu_multiple":0,"biomarkersSelected":[{"code":"C126892","biomarker":"EGFR Exon 19 Deletion Mutation"}]},"testResults":{"hiv_multiple":0,"wbc":"6000","platelets":"175000","performance":{"name":"2: Symptomatic, in bed less than 50% of day","code":"C105725"}}}',
      page: 1,
      itemsPerPage: 10,
      sortBy: '',
      sortDesc: true,
      loadingSearch: false
    }
  },
  created() {
    this.$store.dispatch('searchStore/fetchRecentCriteria')
  },
  methods: {
    onPaginationChange(e) {
      this.page = e.page;
      this.itemsPerPage = e.itemsPerPage;
    },
    onSortBy(e) {
      this.sortBy = e
    },
    onSortDesc(e) {
      this.sortDesc = e
    },
    deleteSearchItem(item) {
      this.$store.dispatch('searchStore/deleteSearchItem', item)
    }
  },
  computed: {
    ...mapGetters({
      loadingRecentCriteria: 'searchStore/getLoadingRecentCriteria'
    }),
    criteriaHeader() {
      return this.$store.getters['searchStore/getCriteriaHeader']
    },
    recentCriteria() {
      return this.$store.getters['searchStore/getRecentCriteria']
    },
  }
}
</script>

<style>

</style>