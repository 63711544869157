<template>
  <section id="loading-wrapper" class="root-section">
    <v-container class="pt-10">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="5">
          <div class="text-center d-flex align-center justify-center">
            <span><img src="/img/FACTrialLogo.png" alt="FACTrial Logo"></span>
          </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="5">
          <v-card elevation="2" class="pa-10">
            <div>
              <p class="form-title  pb-5">
                <strong>RESET PASSWORD</strong>
              </p>

              <v-form data-test="reset-form" ref="form" v-model="valid" >
                <v-text-field data-test="old-password" type="password"
                  v-model="oldPassword" :rules="oldPasswordRules" label="Old Password*" required ></v-text-field>
                <v-text-field data-test="new-password" type="password"
                  hint="Must contain at least 12 characters and  at least one character from each of the following four categories: Lowercase letters (a-z), Uppercase letters (A-Z), Numbers (0-9) and symbol (! @ # $ % ^ &amp; *)"        
                  v-model="newPassword" :rules="passwordRules" label="New Password*" required ></v-text-field>
                <p v-if="errorMsg" class="error--text v-messages mt-0">
                  {{ errorMsg }}
                </p>

                <v-btn data-test="register" block :disabled="!valid" @click="resetPassword" class="mt-16 btn-search" > 
                  RESET PASSWORD 
                </v-btn>
              </v-form>
            </div>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { Auth } from 'aws-amplify';

export default {
  name: 'Register',
  components: {},
  data() {
    return {
      valid: false,
      oldPassword: '',
      newPassword: '',
      code: '',
      user: null,
      oldPasswordRules: [
        (v) => !!v || 'Password is required'
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => {
          const pattern = /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/;
          return pattern.test(v) || 'Email format is invalid'
        }
      ],
      errorMsg: '',
    };
  },
  mounted() {
    //this.valid = false;
  },
  methods: {
    ...mapActions('authStore', {
      a_setUser: 'setUser',
    }),
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    resetPassword() {
      Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, this.oldPassword, this.newPassword);
        })
        .then(data => {
          console.log(data)
          this.$router.push({name: 'Login'})
        })
        .catch(err => console.log(err));
    }
  },
};
</script>

<style lang="scss">
form[data-test='register-form'] {
  //.v-messages {display: none;}
  .error--text {
    .v-messages {
      display: flex;
    }
  }
  .v-messages__wrapper {
    margin-bottom: 10px;
  }
}
</style>
