<template>
  <vue-ellipse-progress                   
    :progress="score"
    :color="color"
    v-bind="options"
    class="score-circle matching-level">

    <span slot="legend-value">%</span>
    <p slot="legend-caption" class="legend-caption mb-0"></p>
    <!-- <p slot="legend-caption" class="legend-caption mb-0">match</p> -->
  </vue-ellipse-progress>
</template>

<script>
export default {
  name: 'ScoreCircle',
  props: ['score', 'trial'],
  data() {
    return {
      progress: 0,
      options: {
        angle: 270,
        fontColor: '#3d4551',
        emptyColor: '#f0f0f0',
        emptyColorFill: '#FFFFFF',                      
        size: 50, //74
        thickness: 8, //12
        emptyThickness: 8, //12
        lineMode: 'in-over',
        legendClass: 'legend-custom-style',
        animation: 'default 700 400',
        noData: false,  
        fontSize: '0.75rem'
      }
    }
  },
  created() {
    this.progress = this.score
  },
  computed: {
    color() {
      if (this.score >= 67) {
        return '#1CB4C7'
      } else if (this.score >= 34) {
        return '#AA6AB4'
      } else {
        return '#F08350'
      }
    }
  }
}
</script>

<style lang="scss">
.score-circle {
  z-index: 5;
  circle {
    stroke-linecap: butt;
  }
  .legend-custom-style {
    color: #3d4551;
    line-height: 13px;
  }
  .legend-caption {
    font-size: 12px;
    color: #3d4551;
    line-height: 13px;
  }
}
</style>