<template>
  <div class="form-group">
    <!-- <div class="group-label pl-1">Biomarkers</div>
    <div>
      <v-btn data-test="biomarker-btn" depressed color="outlined" class="mr-2 mb-3"
        @click="biomarkersDialog = true">
        Biomarkers
      </v-btn>
    </div> -->

    <biomarkers-dialog-home ref="biomarkersDialog" :biomarkersDialog="biomarkersDialog" />

    <!-- <div v-if="biomarkersSearch.length == 0 && biomarkersMultipleSelected.length == 0" class="no-data">No biomarker selected. Please add one or more biomarkers if known.</div>
    <v-simple-table v-else dense class="mb-4">
      <template v-slot:default>
        <tbody>
          <tr v-for="item in biomarkersMultipleSelected" :key="item">
            <td class="pre-btn-remove">{{ item }}</td>
            <td width="20" class="pr-2 btn-remove" @click="removeBiomarkerMultiple(item)"><v-icon small>mdi-close-circle</v-icon></td>
          </tr>
          <tr v-for="(item, index) in biomarkersSearch" :key="item.code">
            <td class="pre-btn-remove">{{ item.biomarker }}</td>
            <td width="20" class="pr-2 btn-remove" @click="removeBiomarkerAtIndex(index)"><v-icon small>mdi-close-circle</v-icon></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->

    <!-- <biomarkers-dialog ref="biomarkersDialog" :biomarkersDialog="biomarkersDialog" @biomarkersDialogClose="closeBiomarkesDialog" /> -->
  </div>
</template>

<script>
import BiomarkersDialog from './dialog/BiomarkersDialog.vue'
import BiomarkersDialogHome from './dialog/BiomarkersDialogHome.vue';

export default {
  components: { BiomarkersDialog, BiomarkersDialogHome },
  name: 'Biomarkers',
  data() {
    return {
      biomarkersDialog: false,
    }
  },
  methods: {
    closeBiomarkesDialog() {
      this.biomarkersDialog = false;
    },
    removeBiomarkerAtIndex(index) {
      this.$store.dispatch('criteriaStore/removeBiomarkerAtIndex', index)
    },
    removeBiomarkerMultiple(item) {
      let biomarkerName = item.split(' ')[0]
      if (biomarkerName === 'HER2/Neu') {
        biomarkerName = 'her2Neu_multiple'
      } else {
        biomarkerName = biomarkerName.toLowerCase() + '_multiple'
      }
      this.$store.dispatch('criteriaStore/removeBiomarkerMultiple', biomarkerName)
    },
    reset() {
      this.$refs.biomarkersDialog.reset()
    },
    setDemo(data) {
      this.$refs.biomarkersDialog.setDemo(data)
    }
  },
  computed: {
    biomarkersSearch() {
      return this.$store.getters['criteriaStore/getBiomarkers'].biomarkersSelected
    },
    biomarkersMultipleSelected() {
      return this.$store.getters['criteriaStore/getBiomarkersMultipleSelected']
    }
  }
}
</script>

<style>

</style>