<template>
  <div id="select-search-panel">
    <div class="welcome-msg-title">Welcome to the FACTrial cancer clinical trial search tool</div>
    <p class="welcome-msg-body tl py-2 py-md-4">We offer two ways to search for open clinical trials. The only information required for both search methods is a diagnosis or disease name, but Advanced Search allows you to enter more information that will narrow down your list of the most relevant trials for you. Please select an option below to begin your search (you can switch between Basic and Advanced Search at any time).</p>
  
    <div id="search-options-wrapper" class="d-flex justify-center">
      <button class="search-option-card basic elevation-2 mx-0 mx-lg-8" data-test="basicSearch"
        @click="selectSearchType('basic')">
        <div>
          <v-icon class="white--text">mdi-auto-fix</v-icon>
        </div>
        <div>
          <p class="search-option-card-title">BASIC SEARCH</p>
          <!-- <p class="search-option-card-subtitle">Designed for novice users</p> -->
          <p class="mb-0">Recommended for those with more general information. For example, a newly diagnosed patient who does not have their molecular profile and is awaiting test results.</p>
        </div>
      </button>
      <button class="search-option-card advanced elevation-2 mx-0 mx-md-8" data-test="advancedSearch"
        @click="selectSearchType('advanced')">
        <div>
          <v-icon class="white--text">mdi-text-box-search-outline</v-icon>
        </div>
        <div>
          <p class="search-option-card-title">ADVANCED SEARCH</p>
          <!-- <p class="search-option-card-subtitle">Designed for experienced users</p> -->
          <p class="mb-0">Recommended for those with more specific information. For example, a refractory patient who has details on their treatment, molecular profile, and recent test results.</p>
        </div>
      </button>
    </div>

    <div id="disclaimer">
      <p class="large-font">What is FACTrial (Find a Clinical Trial)?</p>
      <p>FACTrial is a robust search engine for finding cancer-specific clinical trials that are open for enrollment.</p>
      <p>FACTrial provides patients, doctors, and caregivers the ability to find available clinical trials by selecting specific criteria. These criteria were developed to assist pediatric and adolescent and young adult (AYA) patients in finding a trial for which they might be eligible.</p>

      <p class="large-font">Who can use this tool?</p>
      <p>Anyone can use FACTrial, including patients, parents, caregivers, clinicians, and advocates. We provide a list of the most relevant cancer clinical trials that match your diagnosis and clinical profile so you can have a focused discussion with your family and care providers.</p>
    
      <p class="large-font">What information do I need to use FACTrial?</p>
      <p>The only information required to use FACTrial is a diagnosis (or disease name). All other fields are optional, but your search results will be better if you enter as much information as you can on the Search Criteria page. Click Basic Search or Advanced Search to get started.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectSearch',
  props: {
    selectedGroup: {type: String, required: true}
  },
  methods: {
    selectSearchType(type) {
      this.$store.commit('authStore/SET_SEARCH_TYPE', type)
      setTimeout(() => {
        window.scroll({top: 0})
      }, 500)
    }
  },
}
</script>

<style>

</style>