<template>
  <footer class="footer">
    <v-container fluid class="well gray">
      <v-row>
        <v-col cols="12" sm="5" class="pt-0 pr-sm-8">
          For technical questions, please contact
          <a href="mailto:FACTSupport@nih.gov?subject=FACTrial Technical Support Inquiry">FACTSupport@nih.gov</a>.<br />
          For trial related questions, please talk to your doctor or cancer association.
        </v-col>
        <v-col cols="12" sm="3" class="pt-0">
          <div class="footer-link-col">
            <div class="footer-col-title">POLICIES</div>
            <ul id="Policies" class="footer-list">
              <li>
                <a href="https://www.cancer.gov/policies/accessibility" target="_blank" class="text-link">Accessibility</a >
              </li>
              <li>
                <a href="https://www.cancer.gov/policies/disclaimer" target="_blank" class="text-link" >Disclaimer</a >
              </li>
              <li>
                <a href="https://www.cancer.gov/policies/foia" target="_blank" class="text-link" >FOIA</a >
              </li>
              <li>
                <a href="https://www.cancer.gov/policies/privacy-security" target="_blank" class="text-link" >Privacy &amp; Security</a >
              </li>
              <li>
                <a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html" target="_blank" class="text-link" >Vulnerability Disclosure</a >
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" sm="4" class="pt-0">
          <span class="mt-0 d-block" @click.shift="resetLoginCount">Powered by CTS Api and NCIt.</span>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "FactFooter",
  data() {
    return {};
  },
  mounted() {
    this.buildTime = process.env.VUE_APP_BUILD_TIME;
  },
  methods: {
    async resetLoginCount(e) {
      let user = this.$store.getters["authStore/getUser"];
      await Auth.deleteUserAttributes(user, ["custom:login_count"]);
    },
  },
};
</script>

<style lang="scss">
/* footer */
#app.v-application {
  .footer {
    .well {
      &.gray {
        background: gray;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        a {
          color: #ffffff;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      min-height: 20px;
      padding: 19px;
      margin-bottom: 0;
      background-color: #f5f5f5;
    }
    .btn-social {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      text-align: center;
      overflow: hidden;
      background-color: #ffffff;
      &:hover {
        .fab {
          color: #333;
        }
      }
    }
    .fab {
      display: block;
      line-height: 34px;
      margin: auto;
      font-size: 20px;
      color: gray;
    }
    @media only screen and (max-width: 599px) {
      text-align: center;
    }
  }
}
</style>
