import { cloneDeep } from 'lodash';
import fetchService from '@/services/fetchService';

export default {
  state: {
    typesHeader: [
      { text: 'REMOVE', value: 'remove', align: 'center', sortable: false},
      { text: 'EDIT', value: 'edit', align: 'center', sortable: false},
      { text: 'ID', value: 'criteria_type_id', align: 'center', sortable: false},
      { text: 'CODE', value: 'criteria_type_code', sortable: false},
      { text: 'TITLE', value: 'criteria_type_title', sortable: true},
      { text: 'DESCRIPTION', value: 'criteria_type_desc', sortable: true},
      { text: 'ACTIVE', value: 'criteria_type_active', align: 'center', sortable: true},
      { text: 'SENSE', value: 'criteria_type_sense', align: 'center', sortable: true},
      { text: 'COLUMN INDEX', value: 'criteria_column_index', align: 'center', sortable: true},
    ],
    byTypeHeader: [
      //{ text: '', value: 'id', sortable: false, width: 40},
      { text: 'REMOVE', value: 'remove', align: 'center', sortable: false, width: '60'},
      { text: 'Edit', value: 'edit', sortable: false, width: "40", align: 'center'},
      { text: 'NCT ID', value: 'nct_id', sortable: true, width: "100"},
      { text: 'Type ID', value: 'criteria_type_id', sortable: false, align: 'center', width: "110"},
      { text: 'Original Text', value: 'trial_criteria_orig_text', sortable: false, width: '150'},
      { text: 'Refined Text', value: 'trial_criteria_refined_text', sortable: false, width: '250'},
      { text: 'Expression', value: 'trial_criteria_expression', sortable: false},
      { text: 'Last Updated', value: 'update_date', sortable: true, width: '180'},
      { text: 'Last Updated By', value: 'update_by', sortable: true, width: "150"},
    ],
    loadingTypes: false,
    loadingTrialByType: false,
    loadingTrialById: false,
    criteriaTypes: [],
    trialByType: [],
    trialById: [],
    allNctId: [],
  },
  getters: {
    getLoadingTypes: (state) => state.loadingTypes,
    getLoadingTrialByType: (state) => state.loadingTrialByType,
    getLoadingTrialById: (state) => state.loadingTrialById,
    getTypesHeader: (state) => state.typesHeader,
    getCriteriaTypes: (state) => state.criteriaTypes,
    getTrialByType: (state) => state.trialByType,
    getTrialById: (state) => state.trialById,
    getByTypeHeader: (state) => state.byTypeHeader,
    getAllNctId: (state) => state.allNctId,
  },
  mutations: {
    UPDATE_TYPES(state, data) {
      state.criteriaTypes = data;
    },
    UPDATE_TRIAL_BY_TYPES(state, data) {
      state.trialByType = data;
    },
    UPDATE_TRIAL_BY_ID(state, data) {
      state.trialById = data;
    },
    UPDATE_ALL_NCT_ID(state, data) {
      state.allNctId = data;
    },
    /* UPDATE_SEARCH_ITEM(state, data) {
      let index = state.recentCriteria.findIndex(
        (search) => search.session_uuid == data.item.session_uuid
      );
      if (data.type == 'delete') {
        state.recentCriteria.splice(index, 1);
      }
    }, */
  },
  actions: {
    fetchCriteriaTypes({ commit, state }) {
      state.loadingTypes = true;
      return new Promise((resolve, reject) => {
        fetchService
          .getDataFiles('get_criteria_type_records', {rnd: Math.floor(Math.random() * 100000000)})
            .then((response) => {
              state.loadingTypes = false;
              commit('UPDATE_TYPES', response);
              resolve(response);
            })
            .catch((error) => {
              console.log('error', error);
              reject(error);
            });
      });
    },
    fetchTrialById({ commit, state }, payload) {
      state.loadingTrialById = true;
      return new Promise((resolve, reject) => {
        ///get_trial_criteria_by_nct_id?nct_id=NCT01629498
        fetchService
          .getDataFiles('get_trial_criteria_by_nct_id', payload)
            .then((response) => {
              state.loadingTrialById = false;
              commit('UPDATE_TRIAL_BY_ID', response);
              resolve(response);
            })
            .catch((error) => {
              console.log('error', error);
              reject(error);
            });
      });
    },
    fetchTrialByType({ commit, state }, payload) {
      state.loadingTrialByType = true;
      return new Promise((resolve, reject) => {
        //get_trial_criteria_by_type?criteria_type_id=8
        fetchService
          .getDataFiles('get_trial_criteria_by_type', payload)
            .then((response) => {
              state.loadingTrialByType = false;
              commit('UPDATE_TRIAL_BY_TYPES', response);
              resolve(response);
            })
            .catch((error) => {
              console.log('error', error);
              reject(error);
            });
      });
    },
    fetchAllNctId({ commit, state }) {
      return new Promise((resolve, reject) => {
        //get_nct_ids
        fetchService
          .getDataFiles('get_nct_ids')
            .then((response) => {
              commit('UPDATE_ALL_NCT_ID', response);
              resolve(response);
            })
            .catch((error) => {
              console.log('error', error);
              reject(error);
            });
      });
    },
    createCriteriaTypes(_, payload) {
      return new Promise((resolve, reject) => {
        fetchService.postDataFiles('post_create_criteria_type', payload)
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },
    updateTypes(_, payload) {
      return new Promise((resolve, reject) => {
        fetchService
          .putDataFiles('put_update_criteria_type', payload)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    updateTrialCriteriaType({ dispatch }, {payload, type}) {
      return new Promise((resolve, reject) => {
        fetchService.putDataFiles('put_update_trial_criteria', payload)
          .then((response) => {
            switch(type) {
              case 'type':
                dispatch('fetchTrialByType', {criteria_type_id: payload.record.criteria_type_id});
                break;
              case 'id': 
                dispatch('fetchTrialById', {nct_id: payload.record.nct_id})
                break;
            }
            /* if (type == 'type') {
              dispatch('fetchTrialByType', {criteria_type_id: payload.record.criteria_type_id});
            } else if (type == 'id') {
              dispatch('fetchTrialById', {nct_id: payload.record.nct_id});
            } */
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addTrialCriteriaType({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        fetchService.postDataFiles('post_create_trial_criteria', payload)
          .then((response) => {
            dispatch('fetchTrialById', {
              nct_id: payload.record.nct_id
            })
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTypesCount({commit}, payload) {
      return new Promise((resolve, reject) => {
        fetchService.getDataFiles('get_trial_criteria_count_by_id', payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteTypes({ commit }, payload) {
      return new Promise((resolve, reject) => {
        fetchService
          .deleteDataFiles('delete_criteria_type', payload)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    deleteTrialByType({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        fetchService
          .deleteDataFiles('delete_criteria_type', payload)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
  },
};
