<template>
	<div id="table-tab-wrapper">
		<div
			class="ml-md-4 mr-md-4"
			id="list-view-progress-wrapper"
		>
			<v-progress-linear
				v-show="loadingNCTId"
				indeterminate
				color="primary"
			></v-progress-linear>
		</div>
		<div class="data-table-wrapper ml-md-4 pr-md-4">
			<!-- scroll data table -->
			<v-data-table
				id="scroll-data-table"
				:headers="activeTrialsHeader"
				:items="filterTrials"
				:items-per-page="itemsPerPage"
				:page="page"
				:sort-by="sortBy"
				:sort-desc="sortDesc"
				:footer-props="{ 'items-per-page-options': [5, 10, 20, 50, 100, -1] }"
				:search="searchStr"
				:custom-sort="sortEmptyLast"
				@pagination="onPaginationChange"
				@update:sort-by="onSortBy"
				@update:sort-desc="onSortDesc"
				must-sort
				fixed-header
			>
				<template
					v-for="col in activeTrialsHeader"
					v-slot:[`item.${col.value}`]="{ item, value, header }"
				>
					<template v-if="header.value == 'hide'">
						<eye-hidden
							:key="col.value"
							:item="item"
						/>
					</template>
					<template v-else-if="header.value == 'starred'">
						<star
							:key="col.value"
							:item="item"
						/>
					</template>
					<template v-else-if="header.value == 'nct_id'">
						<a
							class="font-weight-bold e-link"
							:href="
								'https://www.cancer.gov/about-cancer/treatment/clinical-trials/search/v?id=' +
								value +
								'&amp;r=1'
							"
							target="_blank"
							:key="col.value"
						>
							{{ value }}
							<v-icon
								small
								color="primary"
								>mdi-open-in-new</v-icon
							>
						</a>
					</template>
					<span
						v-else-if="header.value.includes('matches')"
						:key="col.value"
					>
						<span
							v-if="value !== true && value !== false"
							class="no-icon"
							style="opacity: 0"
							aria-hidden="true"
							>-</span
						>
						<match-exc
							v-else-if="col.exclusion == true"
							:value="value"
						/>
						<match
							v-else
							:value="value"
						/>
					</span>

					<!-- prior therapy exclusions -->
					<template
						v-else-if="
							header.value == 'prior_therapy_exclusions' ||
							header.value == 'prior_therapy_inclusions'
						"
					>
						<content-with-tooltips
							:value="formatPriorTherapyExclusions(value)"
							:key="col.value"
						/>
					</template>

					<!-- biomarker exclusion/inclusion -->
					<template
						v-else-if="
							header.value == 'disease_names_lead' ||
							header.value == 'disease_names' ||
							header.value == 'biomarker_inc_refined_text' ||
							header.value == 'biomarker_exc_refined_text'
						"
					>
						<content-with-tooltips
							:value="formatBioMarker(value)"
							:key="col.value"
						/>
					</template>

					<template v-else>
						<content-with-tooltips
							v-if="header.tooltip == true"
							:value="value"
							:key="col.value"
						/>
						<span
							v-else
							:key="col.value"
							:class="{ 'small-font': header.small_font }"
							>{{ value }}</span
						>
					</template>
				</template>
			</v-data-table>

			<!-- fixed data table -->
			<v-data-table
				id="fixed-data-table"
				:headers="activeTrialsHeader"
				:items="filterTrials"
				:items-per-page="itemsPerPage"
				:page="page"
				:sort-by="sortBy"
				:sort-desc="sortDesc"
				:search="searchStr"
				:custom-sort="sortEmptyLast"
				@update:sort-by="onSortBy"
				@update:sort-desc="onSortDesc"
				hide-default-footer
				must-sort
				fixed-header
				:class="{ windows: os === 'windows' }"
			>
				<template
					v-for="col in activeTrialsHeader"
					v-slot:[`item.${col.value}`]="{ item, value, header }"
				>
					<template v-if="header.value == 'hide'">
						<eye-hidden
							:key="col.value"
							:item="item"
						/>
					</template>
					<template v-else-if="header.value == 'starred'">
						<star
							:key="col.value"
							:item="item"
						/>
					</template>
					<template v-else-if="header.value == 'nct_id'">
						<a
							class="font-weight-bold e-link"
							:href="
								'https://www.cancer.gov/about-cancer/treatment/clinical-trials/search/v?id=' +
								value +
								'&amp;r=1'
							"
							target="_blank"
							:key="col.value"
						>
							{{ value }}
							<v-icon
								small
								color="primary"
								>mdi-open-in-new</v-icon
							>
						</a>
					</template>

					<!-- biomarker exclusion/inclusion -->
					<template
						v-else-if="
							header.value == 'disease_names_lead' ||
							header.value == 'disease_names' ||
							header.value == 'biomarker_inc_refined_text' ||
							header.value == 'biomarker_exc_refined_text'
						"
					>
						<content-with-tooltips
							:value="formatBioMarker(value)"
							:key="col.value"
						/>
					</template>

					<template
						v-else-if="
							header.value == 'prior_therapy_exclusions' ||
							header.value == 'prior_therapy_inclusions'
						"
					>
						<content-with-tooltips
							:value="formatPriorTherapyExclusions(value)"
							:key="col.value"
						/>
					</template>
					<template v-else>
						<content-with-tooltips
							v-if="header.tooltip == true"
							:value="value"
							:key="col.value"
						/>
						<span
							v-else
							:key="col.value"
							:class="{ 'small-font': header.small_font }"
							>{{ value }}</span
						>
					</template>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import ContentWithTooltips from './ContentWithTooltips.vue';
	import EyeHidden from './EyeHidden.vue';
	import Match from './Match.vue';
	import MatchExc from './MatchExc.vue';
	import Star from './Star.vue';

	function getOS() {
		var userAgent = window.navigator.userAgent,
			platform = window.navigator.platform,
			macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
			windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
			iosPlatforms = ['iPhone', 'iPad', 'iPod'],
			os = null;

		if (macosPlatforms.indexOf(platform) !== -1) {
			os = 'Mac OS';
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			os = 'iOS';
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			os = 'Windows';
		} else if (/Android/.test(userAgent)) {
			os = 'Android';
		} else if (!os && /Linux/.test(platform)) {
			os = 'Linux';
		}

		return os;
	}

	export default {
		name: 'TableView',
		props: {
			searchStr: { type: String, default: '' },
		},
		components: { ContentWithTooltips, EyeHidden, Match, MatchExc, Star },
		data() {
			return {
				//data table shared params
				page: 1,
				itemsPerPage: 10,
				sortBy: '',
				sortDesc: true,
				os: '',
			};
		},
		created() {
			this.os = getOS().toLowerCase();
		},
		mounted() {
			let scrollTable = document.querySelector(
				'#scroll-data-table .v-data-table__wrapper'
			);
			let fixedTable = document.querySelector(
				'#fixed-data-table .v-data-table__wrapper'
			);
			scrollTable.addEventListener('scroll', function () {
				fixedTable.scrollTop = scrollTable.scrollTop;
			});
			fixedTable.addEventListener('scroll', function () {
				fixedTable.scrollLeft = 0;
			});
		},
		methods: {
			onPaginationChange(e) {
				this.page = e.page;
				this.itemsPerPage = e.itemsPerPage;
			},
			onSortBy(e) {
				this.sortBy = e;
			},
			onSortDesc(e) {
				this.sortDesc = e;
			},
			sortEmptyLast(items, index, isDesc) {
				//console.log('sortBy : index', this.sortBy, index)
				if (this.sortBy == 'star') {
					console.log('sortby star');
					let starRefArr = this.$store.state.trialsStore.starSelectedArr;
					items.sort((a, b) => {
						if (this.sortDesc != false) {
							return starRefArr[a['id'] - 1] < starRefArr[b['id'] - 1] ? -1 : 1;
						} else {
							return starRefArr[b['id'] - 1] < starRefArr[a['id'] - 1] ? -1 : 1;
						}
					});
				} else {
					items.sort((a, b) => {
						if (a[index] === '' || a[index] === null) return 1;
						if (b[index] === '' || b[index] === null) return -1;
						if (a[index] === b[index]) return 0;
						if (this.sortDesc != false) {
							return a[index] < b[index] ? -1 : 1;
						} else {
							return b[index] < a[index] ? -1 : 1;
						}
					});
				}

				return items;
			},
			formatPriorTherapyExclusions(value) {
				if (value === null) return '';

				// replace '(C' with ' (C'
				value = value.replace(/\(C/g, ' (C');

				// split by ';' and add newline
				let arr = value.split(';');
				return arr.join('<br /> ');
			},
			formatBioMarker(value) {
				if (value === null) return '';

				// split by ';' and add newline
				let arr = value.split(',');
				return arr.join('<br /> ');
			},
		},
		computed: {
			...mapGetters({
				loadingNCTId: 'trialsStore/getLoadingNCTId',
				trialsHeader: 'trialsStore/getTrialsHeader',
				trials: 'trialsStore/getTrials',
				filterTrials: 'trialsStore/getFilteredTrials',
			}),
			activeTrialsHeader() {
				return this.trialsHeader.filter((header) => {
					return header.show === true;
				});
			},
		},
	};
</script>

<style></style>
