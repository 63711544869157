<template>
  <div>
    <v-menu v-model="generateReportMenu" :close-on-content-click="false" 
      offset-y z-index="100"
      transition="slide-y-transition"
      :attach="`#report-options-container`">
      <template v-slot:activator="{ on, attrs }">
        <v-btn data-test="generate-report-btn" depressed color="primary" v-bind="attrs" v-on="on" class="mt-n2 pr-2 mt-sm-0 elevation-2 primary-bd">
          <v-icon left>mdi-clipboard-list-outline</v-icon> GENERATE REPORT <v-icon class="ml-2">mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-card class="pop-up">
        <div id="report-options" class="form-group">
          <div class="group-label">Report Options</div>
          <v-radio-group data-test="report-options" hide-details="" v-model="reportOption" class="pt-0 mt-2">
            <v-radio v-for="item in reportOptionArr" :label="item.label" :value="item.value" :key="item.label" v-rb-focus></v-radio>
          </v-radio-group>

          <div class="group-label mt-6">Report generating methods</div>
          <v-radio-group data-test="download-options" hide-details="" v-model="generatingMethods" class="pt-0 mt-2">
            <v-radio :label="'Generate a download file'" :value="0" v-rb-focus></v-radio>
            <v-radio :label="'Generate a shareable link'" :value="1" v-rb-focus></v-radio>
          </v-radio-group>

          <div v-if="generatingMethods == 0">
            <div class="group-label mt-6">File Format Options</div>
            <v-radio-group data-test="download-options" hide-details="" v-model="downloadOption" class="pt-0 mt-2">
              <v-radio v-for="(item, n) in downloadOptionArr" :label="item" :value="n" :key="item" v-rb-focus></v-radio>
            </v-radio-group>

            <div class="report-btn-actions">
              <v-btn data-test="download-report" depressed color="primary" class="mt-4 mt-lg-6 px-3" @click="downloadReport">
                <v-icon left>mdi-download</v-icon> DOWNLOAD
              </v-btn>

              <!-- <v-btn data-test="download-report" depressed color="primary" class="mt-4 mt-lg-6 px-3" @click="emailProvider">
                <v-icon left>mdi-mail</v-icon> EMAIL PROVIDER
              </v-btn> -->
            </div>
          </div>

          <div v-if="generatingMethods == 1">
            <v-btn data-test="generate-link" block depressed color="primary" class="mt-6" @click="generateLink">
              <v-icon left>mdi-link-variant</v-icon> GENERATE LINK
            </v-btn>

            <v-divider class="my-6"></v-divider>

            <v-text-field id="shareableLink" :value="shareableLink" label="Shareable Link" disabled outlined hide-details
              :loading="generatingLink"></v-text-field>

            <div class="report-btn-actions">
              <v-btn data-test="download-report" depressed color="primary" class="mt-4 mt-lg-6 px-3" 
                :disabled="shareableLinkDisabled" @click="copyLink">
                <v-icon left>mdi-content-copy</v-icon> {{btnCopyLinkLabel}}
              </v-btn>

              <!-- <v-btn data-test="download-report" depressed color="primary" class="mt-4 mt-lg-6 px-3" 
                :disabled="shareableLinkDisabled" @click="emailProvider">
                <v-icon left>mdi-mail</v-icon> EMAIL PROVIDER
              </v-btn> -->
            </div>
          </div>
        </div>
      </v-card>
    </v-menu>
    <div :id="`report-options-container`" class="popup-menu-container"></div>

    <v-snackbar :timeout="alertTimeout" v-model="exportSnackbar">
      {{exportAlertMsg}}.

      <template v-slot:action="{ attrs }">
        <v-btn @click="exportSnackbar = false" color="white" text v-bind="attrs" class="pr-0 mr-n4">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import exportCSV from '@/export/exportCSV';
//import exportExcel from '@/export/exportExcel';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { truthy } from '@/services/CommonService';
import { criteriaDict} from '@/store/initState';
import fetchService from '@/services/fetchService';

export default {
  name: 'ExportWidget',
  data() {
    return {
      generateReportMenu: false,
      reportOptionArr: [
        {label: 'Include all matching trials EXCEPT those marked as hidden', value: 1},
        {label: 'Include all matching trials', value: 0},
        {label: 'Include ONLY saved trials', value: 2},

      ],
      //downloadOptionArr: ['CSV', 'Excel', 'PDF'],
      downloadOptionArr: ['CSV', 'PDF'],
      downloadOption: 0,
      generatingMethods: 0,
      reportOption: 1,
      exportSnackbar: false,
      exportAlertMsg: 'Export Alert',
      alertTimeout: 5000,
      shareableLink: '',
      generatingLink: false,
      btnCopyLinkLabel: 'COPY LINK'
    }
  },
  methods: {
    downloadReport() {
      let fileTitle = `FACTrial_search_${this.$moment(new Date()).format('YYYY-MM-DD_hh_mm_A')}`;
      let exportData;
      //get export data for different report option
      if (this.reportOption == 0) {
        //all match, this.trials
        exportData = this.$store.getters['trialsStore/getMatchedTrials'];
      } else if (this.reportOption == 1) {
        //all match without hidden, this.filterTrials
        exportData = this.$store.getters['trialsStore/getMatchedWithoutHiddenTrials'];
      } else if (this.reportOption == 2) {
        //only saved, this.filterTrials
        exportData = this.$store.getters['trialsStore/getStarSelectedTrials'];
      }
      this.generateReportMenu = false;

      //export file for different format
      if (this.downloadOption == 2) {
        console.log('export excel file')
        //excel 'xlsx' exportExcelFile
        /* if (exportData.length == 0) {
          this.exportAlertMsg = this.reportOption == 2 ? 'No saved trials' : 'No matching trials'
          this.alertTimeout = 3000;
          this.exportSnackbar = true;
        } else {
          //change column label for final output

          exportExcel.exportExcelFile({
            exportData,
            criteriaSelection: this.criteriaSelectionForExcel,
            header: this.$store.getters['trialsStore/getTrialsHeader']
          }, `${fileTitle}.xlsx`);
        } */
      } else if (this.downloadOption == 0) {
        //csv
        if (exportData.length == 0) {
          this.exportAlertMsg = this.reportOption == 2 ? 'No saved trials' : 'No matching trials';
          this.alertTimeout = 3000;
          this.exportSnackbar = true;
        } else {
          exportCSV.exportCSVFile({
            exportData,
            criteriaSelection: this.criteriaSelection
          }, `${fileTitle}.csv`);
        }
      } else if (this.downloadOption == 1) {
        //pdf
        if (exportData.length == 0) {
          this.exportAlertMsg = this.reportOption == 2 ? 'No saved trials' : 'No matching trials';
          this.alertTimeout = -1;
          this.exportSnackbar = true;
        } else {
          let exportNct_id = exportData.map(trial => {
            return trial.nct_id
          })
          let pdfObj = {
            criteria: this.criteriaSelection,
            nct_ids: exportNct_id,
            coordinates: this.coordinatesForPdf
          }
          this.exportSnackbar = true;
          this.exportAlertMsg = 'Generating PDF report....'
          fetchService.postDataFiles('report', pdfObj)
            .then(response => {
              setTimeout(() => {
                this.exportSnackbar = false;
              }, 2000)
              let blob = new Blob([response], { type: 'application/pdf' });
              if (navigator.msSaveBlob) { // IE 10+
                  navigator.msSaveBlob(blob, `${fileTitle}.pdf`);
              } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", `${fileTitle}.pdf`);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              } 
            })
            .catch(error => {
              console.log('error!', error)
            })
        }
      }
    },
    generateLink() {
      this.generatingLink = true;
      this.shareableLink = '';
      //prepare data to send
      let distance = this.$store.state.trialsStore.distance;
      let summary = this.$store.getters['summaryStore/getShareSummary'];
      let trials = [];
      if (this.reportOption == 0) {
        //all match, this.trials
        trials = this.$store.getters['trialsStore/getMatchedTrials'];
      } else if (this.reportOption == 1) {
        //all match without hidden, this.filterTrials
        trials = this.$store.getters['trialsStore/getMatchedWithoutHiddenTrials'];
      } else if (this.reportOption == 2) {
        //only saved, this.filterTrials
        trials = this.$store.getters['trialsStore/getStarSelectedTrials'];
      }
      let coordinates = []
      let shareableLinkJSON = {
        summary,
        trialsHeader: this.$store.getters['trialsStore/getTrialsHeader'],
        trials,
        coordinates
      }
      let validGeo = summary.patientInfo.geoArr.filter(geo => {
        return geo.lat !== '' && geo.lon !== ''
      })
      if (validGeo.length > 0 && distance > 0) {
        validGeo.forEach(coord => {
          coordinates.push({
            latitude: coord.lat,
            longitude: coord.lon,
            distance
          })
        })
      }
      
      //console.log('shareableLinkJSON', shareableLinkJSON)
      if (trials.length > 0) {
        fetchService.postDataFiles('post_search_results', {
            data: shareableLinkJSON
          })
          .then(res => {
            this.shareableLink = `${window.location.origin}/share/${res.saved_search}`;
            this.generatingLink = false
          })
      } else {
        this.exportAlertMsg = this.reportOption == 2 ? 'No saved trials' : 'No matching trials'
        this.alertTimeout = 3000;
        this.exportSnackbar = true;
      }
      
      
    },
    copyLink() {
      navigator.clipboard.writeText(this.shareableLink)
        .then(() => {
          this.btnCopyLinkLabel = 'COPIED';
          setTimeout(() => {
            this.btnCopyLinkLabel = 'COPY LINK'
          }, 2000)
        });
    },
    emailProvider() {

    }
  },
  computed: {
    ...mapGetters('summaryStore', {
      patientInfo: 'getPatientInfo',
      disease: 'getDisease',
      biomarkers: 'getBiomarkers',
      biomarkersMultipleSelected: 'getBiomarkersMultipleSelected',
      testResults: 'getTestResults'
    }),
    biomarkersSelectedRender() {
      let combinedBiomarkers = [...this.biomarkersMultipleSelected, ...this.biomarkers.biomarkersSelected.map(item => item.biomarker)]
      return combinedBiomarkers.join(', ')
    },
    distance() {
      return this.$store.state.trialsStore.distance;
    },
    criteriaSelection() {
      let tempCriteriaSelection = {};
      let tempPatientInfo = cloneDeep(this.patientInfo);
      let tempDisease = cloneDeep(this.disease);
      let tempTestResults = cloneDeep(this.testResults);

      //for patientInfo
      delete tempPatientInfo['geo'];
      let t_PatientInfo = cleanCriteriaSelection(tempPatientInfo);
      let f_PatientInfo = formatCriteriaSelection(t_PatientInfo, 'patientInfo');
      delete f_PatientInfo['ageUnit'];
      delete f_PatientInfo['geoArr'];
      //for disease
      let t_Disease = cleanCriteriaSelection(tempDisease);
      let f_Disease = formatCriteriaSelection(t_Disease, 'disease');
      //for testResult
      let t_TestResults = cleanCriteriaSelection(tempTestResults);
      let f_TestResults = formatCriteriaSelection(t_TestResults, 'testResult');

      tempCriteriaSelection = {
        ...f_PatientInfo,
        ...f_Disease,
        Biomarkers: this.biomarkersSelectedRender,
        ...f_TestResults
      }

      return cleanCriteriaSelection(tempCriteriaSelection)
    },
    criteriaSelectionForExcel() {
      let tempCriteriaSelection = [];
      Object.keys(this.criteriaSelection).map(key => {
        tempCriteriaSelection.push({
          Criteria: key,
          Value: this.criteriaSelection[key]
        })
      })

      return tempCriteriaSelection
    },
    coordinatesForPdf() {
      let tempPatientInfo = cloneDeep(this.patientInfo);
      let coordinates;
      if (this.distance > 0) {
        coordinates = tempPatientInfo.geoArr.filter(geo => geo.lat !== '').map(geo => ({
          "latitude": geo.lat, 
          "longitude": geo.lon, 
          "distance": this.distance
        }))
      } else {
        coordinates = []
      }
      return coordinates
    },
    shareableLinkDisabled() {
      return this.generatingLink == true || this.shareableLink == ''
    }
  }
}

function mapFieldToLabel(fieldName) {
  return criteriaDict[fieldName]
}

function cleanCriteriaSelection(data) {
  let tempCriteriaSelection = {}
  //remove empty prop
  for (let key in data) {
    if ({}.hasOwnProperty.call(data, key) && !truthy(data[key])) {
      delete data[key] // delete empty values
    }
  }
  Object.keys(data).forEach((key) => {
    if (data[key] !== 2) {
      tempCriteriaSelection[key] = data[key];
    }
  });

  return tempCriteriaSelection;
}

function formatCriteriaSelection(data, type) {
  let tempCriteriaSelection = {};
  if (type == 'patientInfo') {
    Object.keys(data).forEach((key) => {
      if (key.includes('_multiple')) {
        if (key === 'sex_multiple' && data[key] !== 2) {
          tempCriteriaSelection[mapFieldToLabel(key)] = data[key] == 0 ? 'Male' : 'Female';
        } else {
          tempCriteriaSelection[mapFieldToLabel(key)] = data[key] == 0 ? 'Yes' : 'No';
        }
      } else {
        if (key === 'therapy') {
          tempCriteriaSelection[mapFieldToLabel(key)] = data[key].map((item) => {
            return item.pref_name;
          }).join(', ');
        } else if (key === 'age') {
          tempCriteriaSelection[mapFieldToLabel(key)] = data[key] + ' ' + data['ageUnit'];
        } else if (key === 'geoArr') {
          data[key] = data[key].filter(geo => geo.zipcode !== '');
          if (data[key].length > 0) {
            tempCriteriaSelection[mapFieldToLabel(key)] = data[key].map(geo => geo.zipcode).join(', ')
          }
        } else {
          tempCriteriaSelection[mapFieldToLabel(key)] = data[key];
        }
      }
    });
  } else if (type == 'disease') {
    let tempDiseaseArr = []
    Object.keys(data).forEach((key) => {
      tempDiseaseArr = [...tempDiseaseArr, ...data[key]]
    });
    tempCriteriaSelection['Diseases'] = tempDiseaseArr.join(', ');
  } else if (type == 'testResult') {
    Object.keys(data).forEach((key) => {
      if (key.includes('_multiple')) {
        tempCriteriaSelection[mapFieldToLabel(key)] = data[key] == 0 ? 'Yes' : 'No';
      } else if (key == 'performance') {
        tempCriteriaSelection[mapFieldToLabel(key)] = data[key].name
      } else {
        tempCriteriaSelection[mapFieldToLabel(key)] = data[key];
      }
    });
  }
  return tempCriteriaSelection;
}
</script>

<style>

</style>